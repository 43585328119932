import {
    get,
    post, put
} from '@/utils/http';

export function createTicket(ticket) {
    return post('/supports', ticket)
}

export function listTickets() {
    return get('/supports/users', )
}
