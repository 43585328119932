<template>
  <section>
    <div class="auth-div">
      <div class="header">
        <span class="title">Viagens</span>
      </div>
      <div v-for="(flight, index) in myFlights" :key="index">
        <div class="going-card" @click="seeDetails(flight.id, index)">
          <div class="top-card">
            <div class="media media-top">
              <div class="media-left">
                <p class="hour">
                  {{ flight.outbound_date | moment("D MMMM") }}
                </p>
                <p class="dark-label">
                  {{ flight.origin.split("-", 1).shift() }}
                </p>
              </div>

              <div class="media-content">
                <hr />
              </div>
              <div class="media-right">
                <p class="hour" v-if="flight.inbound_date === null">
                  Sem data
                </p>
                <p class="hour" v-else>
                  {{ flight.inbound_date | moment("D MMMM") }}
                </p>
                <p class="dark-label">
                  {{ flight.destination.split("-", 1).shift() }}
                </p>
              </div>
            </div>
          </div>
          <br />
          <div class="media">
            <div class="media-left">
              <p class="tracking" v-if="flight.frequency === 'daily'">
                Tracking: <span class="tracking-type">Diário</span>
              </p>
              <p class="tracking" v-else-if="flight.frequency === 'weekly'">
                Tracking: <span class="tracking-type">Semanal</span>
              </p>
              <p class="tracking" v-else>
                Tracking: <span class="tracking-type">Mensal</span>
              </p>
            </div>
            <div class="media-content"></div>
            <div class="media-right"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { listOwnFlights } from "@/api/apiFlights";
export default {
  data() {
    return {
      myFlights: null,
    };
  },
  methods: {
    seeDetails(id, index) {
      console.log(id);
      let inboundDate;
      if (this.myFlights[index].inbound_date) {
        inboundDate = this.myFlights[index].inbound_date;
      } else inboundDate = this.myFlights[index].outbound_date;

      this.$router.push({
        name: "Tracking",
        params: {
          id: id,
          outbound: this.myFlights[index].outbound_date,
          inbound: inboundDate,
          origin: this.myFlights[index].origin,
          destination: this.myFlights[index].destination
        },
      });
    },
  },
  created() {
    listOwnFlights()
      .then((response) => {
        console.log(response);
        this.myFlights = response.data;
        console.log(this.myFlights);
      })
      .catch((error) => {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: error,
          position: "is-top",
          type: "is-danger",
        });
      });
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/auth";
@import "../../assets/scss/myFlights";
</style>
