<template>
	<section>
		<div v-touch:swipe.left="swipeHandler" class="swipe-area"></div>
	
		<div class="burger-menu" :class="{ 'burger-menu-color': !isOpen }">
			<input v-model="isOpen" type="checkbox" id="burger" />
			<label class="burger-bars" for="burger">
				<div class="bar" :class="{ 'bar-expanded': isOpen }"></div>
				<div class="bar" :class="{ 'bar-expanded': isOpen }"></div>
				<div class="bar" :class="{ 'bar-expanded': isOpen }"></div>
			</label>
		</div>
		<!--    <div @click="isExpanded=true" v-if="isOpen&&!isExpanded" class="expand-arrow is-click">
          <i class="mdi mdi-arrow-left mdi-24px"></i>
        </div> -->
		<ExpandedMenu @close="closeMenu" v-if="isOpen"></ExpandedMenu>

		<CompactMenu v-touch:swipe="swipeHandler" v-if="isCompact"></CompactMenu>
	</section>
</template>

<script>
	//Mixins
	import { userInfo } from '@/mixins/shared/userInfo';
	import { goToPage } from '@/mixins/shared/goToPage';
	import { notifications } from '@/mixins/notifications/notifications';

	//Components
	import CompactMenu from '@/components/Menu/CompactMenu';
	import ExpandedMenu from '@/components/Menu/ExpandedMenu';

	export default {
		mixins: [userInfo, goToPage],
		components: { CompactMenu, ExpandedMenu },
		name: 'Menu',
		data() {
			return {
				isOpen: false,
				isCompact: false,
				itemName: '',
				swipped: false,
			};
		},
		methods: {
			closeMenu(value) {
				this.isOpen = value;
			},
			closeCompactMenu(value) {
				this.isCompact = value;
			},
			swipeHandler(direction) {
				console.log(direction);
				if (direction === 'left') {
					this.isCompact = true;
				}
				if (direction === 'right') {
					this.isCompact = false;
				}
			},
			close() {
				if (this.isCompact) {
					this.isCompact = false;
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/menu';

	.title {
		color: white;
		margin-top: 23px;
		margin-left: 30px;
	}

	.subtitle {
		color: white;
		font-size: 24px;
	}

	.mdi-arrow-left {
		color: #1d4696;
	}

	.swipe-area {
		position: fixed;
		width: 35px;
		height: 70vh;
		z-index: 36;
		right: 0;
		background-color: transparent;
		top: 50%;
		transform: translate(0, -50%);
	}


</style>
