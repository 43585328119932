<template>
  <section>
    <div :class="{'hitchhike-div':getHitchhikeStep!==3}">
      <p class="title has-text-primary" v-if="getHitchhikeStep!==3"><b>Boleias</b></p>
      <!--User choose between search or create-->
      <ChooseType :userData="userData" v-if="userData.info&&getHitchhikeStep===0"></ChooseType>
      <!--If user creating hitchhike does not have car-->
      <HitchhikeCar v-else-if="getHitchhikeStep===1" :user-car="userCar"></HitchhikeCar>
      <!--Select Cartrip Route-->
      <ChooseRoute v-else-if="getHitchhikeStep===2"></ChooseRoute>
      <!--After creating hitchhike show trip preview in map-->
      <Map v-else-if="getHitchhikeStep===3"></Map>

      <!--Show results after search-->
      <HitchhikeResults v-else-if="getHitchhikeStep===4"></HitchhikeResults>

    </div>
  </section>
</template>
<script>

//Components
import ChooseType from "@/components/Hitchhike/ChooseType";
import HitchhikeResults from "@/components/Hitchhike/AskForHitchhike/HitchhikeResults";
import HitchhikeCar from "@/components/Hitchhike/OfferHitchhike/HitchhikeCar";
import Map from "@/components/Hitchhike/OfferHitchhike/Map/Map";
import ChooseRoute from "@/components/Hitchhike/OfferHitchhike/ChooseRoute";
//Mixins
import {userInfo} from "@/mixins/shared/userInfo";
import {hitchhikeSteps} from "@/mixins/hitchhikes/hitchhikeSteps";
//API
import {listUserVehicles} from "@/api/apiCar";

export default {
  mixins: [userInfo, hitchhikeSteps],
  name: "Hitchhike",
  components: {HitchhikeResults, HitchhikeCar, ChooseType, Map, ChooseRoute},
  data() {
    return {
      userCar: null
    };
  },
  created() {
    console.log(this.userData)
    listUserVehicles()
        .then(response => {
          console.log(response)
          this.userCar = response.data

        })
        .catch(error => {

        })
  }

};
</script>
<style scoped lang="scss">
@import "../assets/scss/hitchhike";


</style>
