<template>
  <section>
    <div :class="{ 'local-markets-div': getLocalMarketStep !== 2 }" v-if="marketInfo !== null">
      <div  class="header">
        <i class="mdi mdi-chevron-left mdi-36px" @click="$router.back()"></i>
        <span class="title">Negócio Local</span>
      </div>
      <br />

      <!--Show Market Info-->
      <LocalMarketInfo :marketInfo="marketInfo" v-if="getLocalMarketStep === 0"></LocalMarketInfo>
      <!--Write Bought Items-->
      <LocalMarketDiscount :marketInfo="marketInfo" v-else-if="getLocalMarketStep === 1"></LocalMarketDiscount>
      <!--Generate Discount QrCode-->
      <LocalMarketQrCode :marketInfo="marketInfo" v-else-if="getLocalMarketStep === 2"></LocalMarketQrCode>
    </div>
  </section>
</template>

<script>
//API
import { getLocalMarketByID } from "@/api/apiLocalMarket";
//Mixins
import { loading } from "@/mixins/shared/loading";
import { localMarketSteps } from "@/mixins/localMarkets/localMarketSteps";
//Components
import LocalMarketInfo from "@/components/LocalMarkets/PageSteps/LocalMarketInfo";
import LocalMarketDiscount from "@/components/LocalMarkets/PageSteps/LocalMarketDiscount";
import LocalMarketQrCode from "@/components/LocalMarkets/PageSteps/LocalMarketQrCode";

export default {
  mixins: [loading, localMarketSteps],
  name: "LocalMarketPage",
  components: { LocalMarketInfo, LocalMarketDiscount, LocalMarketQrCode },
  data() {
    return {
      marketInfo: null
    };
  },
  created() {
    this.setPageLoading(true);
    getLocalMarketByID(this.$route.params.id)
      .then(response => {
        this.marketInfo = response.data;
        console.log(this.marketInfo);
        this.setPageLoading(false);
      })
      .catch(error => {
        this.setPageLoading(false);
      });
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/localMarket";

.title {
  font-size: 1.5rem;
}

.header {
  display: inline-flex;
  margin-top: 7px;
}

i::before {
  color: #1d4696;
  transform: translate(6px, -13px);
}
</style>
