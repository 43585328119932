<template>
  <section>
    <div class="feed-div">
      <p class="title has-text-primary"><b>Feed</b></p>
      <div class="field " v-if="getFeedStep===0" @click="setFeedInfo(1,null)">
        <div class="control has-icons-right">
          <input class="input create-publication is-fullwidth"
                 type="text" placeholder="Criar publicação">
        </div>
      </div>
      <!--List Feed Publications-->
      <FeedTiles v-if="getFeedStep===0"></FeedTiles>
      <!--View Publication-->
      <ViewPublicationModal v-else-if="getFeedStep===2"></ViewPublicationModal>
      <!--Create Publication-->
      <CreatePublicationModal v-else-if="getFeedStep===1"></CreatePublicationModal>
    </div>
  </section>
</template>
<script>

//Components
import CreatePublicationModal from "@/components/Feed/CreatePublication";
import ViewPublicationModal from "@/components/Feed/ViewPublication";
import FeedTiles from "@/components/Feed/FeedTiles";

//Mixins
import {userInfo} from "@/mixins/shared/userInfo";
import {feedSteps} from "@/mixins/feed/feedSteps";

export default {
  mixins: [userInfo, feedSteps],
  name: "Feed",
  components: {CreatePublicationModal, ViewPublicationModal, FeedTiles},
  data() {
    return {};
  },
  created() {

  },
  methods: {}
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/feed";

.title {
  font-size: 1.5rem;
  margin-top: 8px;
}

</style>
