<template>
	<section>
		<div class="auth-div">
			<div class="header">
				<i class="mdi mdi-tune-vertical-variant mdi-24px" style="margin-right:10px" @click="changeSortSearch"></i>
				<span class="title">{{ getFlightData[0].OutboundLegId.OriginStation.Code }}-{{ getFlightData[0].OutboundLegId.DestinationStation.Code }}</span>
			</div>
			<div v-for="(flight, index) in getFlightData" :key="index">
				<div class="going-card" @click="seeDetails(getFlightData[index])">
					<div class="top-card">
						<div class="media media-top">
							<div class="media-left">
								<p class="hour">
									{{ flight.OutboundLegId.Departure | moment('HH:mm') }}
								</p>
								<p class="dark-label">
									{{ flight.OutboundLegId.OriginStation.Code }}
								</p>
							</div>

							<div class="media-content">
								<p class="dark-label">
									{{ flight.OutboundLegId.Duration | moment('HH:mm') }}
								</p>

								<hr />
								<p class="initials">
									{{ flight.OutboundLegId.Carriers[0].DisplayCode }}
								</p>
							</div>
							<div class="media-right">
								<p class="hour">
									{{ flight.OutboundLegId.Arrival | moment('HH:mm') }}
								</p>
								<p class="dark-label">
									{{ flight.OutboundLegId.DestinationStation.Code }}
								</p>
							</div>
						</div>
					</div>
					<br />
					<div class="media">
						<div class="media-left"></div>
						<div class="media-content"></div>
						<div class="media-right">
							<div class="price">
								<p class="price-value">{{ flight.PricingOptions[0].Price }} €</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import { flightSteps } from '@/mixins/flight/flightSteps';
	import { getFlight } from '@/api/apiFlights';
	export default {
		mixins: [flightSteps],
		data() {
			return {
				newOrder: null,
				dataUsed: null,
			};
		},
		created() {
			console.log(this.getFlightData);
			console.log(this.getOrder);

			this.dataUsed = this.getPassengers;
			console.log(this.dataUsed);
		},
		methods: {
			seeDetails(index) {
				console.log(index);
				this.$router.push({ name: 'FlightsDetails', params: { details: index } });
			},

			changeSortSearch() {
				if (this.dataUsed.sort === 'duration') {
					this.dataUsed.sort = 'price';
				} else this.dataUsed.sort = 'duration';
				console.log(this.dataUsed.sort);

				getFlight(this.dataUsed)
					.then(response => {
						console.log(response.data);
						if (response.data.length > 0) {
							this.$store.commit('flightSteps/setActiveFlightStep', 2);
							this.$store.commit('flightSteps/setFlightData', response.data);
							this.$store.commit('flightSteps/setPassengers', this.dataUsed);
						} else {
							this.$buefy.toast.open({
								message: 'Os seus dados estão a ser processados. Por favor tente novamente.',
								position: 'is-top',
								type: 'is-danger',
							});
						}
					})
					.catch(error => {
						/* this.isLoading = false;
                this.$buefy.toast.open({
                  message: error,
                  position: "is-top",
                  type: "is-danger",
                }); */
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/auth';
	@import '../../assets/scss/flightGoing';


</style>
