export const notifications = {
    computed: {
        getAllNotifications() {
            return this.$store.getters["notifications/getNotifications"]
        },
        getChatNotifications() {
            return this.$store.getters["notifications/getChatNotifications"]
        }
    },
    methods: {
        setAllNotifications(notifications) {
            this.$store.commit('notifications/setNotifications', notifications)

        },
        setChatNotifications(unreadCount) {
            this.$store.commit('notifications/setChatNotifications', unreadCount)

        }
    }
}
