export const loading = {

    computed: {
        isPageLoading() {
            return this.$store.getters['loading/getLoadingState']
        },
    },
    methods: {
        setPageLoading(state) {
            this.$store.commit('loading/setLoadingState', state)

        }
    }
}
 