<template>
	<section>
		<div class="auth-div">
			<p class="title has-text-primary"><b>Política de Privacidade</b></p>
			<div id="policies-body" class="policies" @scroll="onScroll">
				<div class="policies-header">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
				</div>
				<div class="policies-body">
					labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
					in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
					deserunt mollit anim id est laborum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
					Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
					velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
					nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
					fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum Lorem ipsum dolor sit amet,
					consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
					laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
					Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum Lorem ipsum dolor sit amet, consectetur adipiscing
					elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
					ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
					non proident, sunt in culpa qui officia deserunt mollit anim id est laborum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
					incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
					aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
					qui officia deserunt mollit anim id est laborum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
					magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
					in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim
					id est laborum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
					veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
					dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
				</div>
			</div>
			<div class="next-div has-text-centered">
				<button v-if="getStepData === 'Google'" :disabled="disabledButton" @click="googleLogin" class="button register is-rounded is-primary is-fullwidth">
					<i class="mdi mdi-google mdi-24px"></i> Registar
				</button>
				<button v-else-if="getStepData === 'Facebook'" :disabled="disabledButton" @click="facebookLogin" class="button register is-rounded is-primary is-fullwidth">
					<i class="mdi mdi-facebook mdi-24px"></i> Registar
				</button>
				<button v-else-if="getStepData === 'Twitter'" :disabled="disabledButton" @click="twitterLogin" class="button register is-rounded is-primary is-fullwidth">
					<i class="mdi mdi-twitter mdi-24px"></i> Registar
				</button>
				<div v-else>
					<button class="button is-rounded is-secondary" @click="goBack">
						<i style="width:40px;color: white;" class="mdi mdi-arrow-left-thick mdi-48px"></i>
					</button>
					<button class="button is-rounded is-primary button-size " :disabled="disabledButton" :class="{ 'is-loading': isLoading }" @click="register">
						Registar
					</button>
					<div class="register-steps center-inside is-fullwidth">
						<div></div>
						<div></div>
						<div class="step-2"></div>
						<div></div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	//Mixins
	import { steps } from '@/mixins/shared/steps';
	import { goToPage } from '@/mixins/shared/goToPage';
	import { socialAuth } from '@/mixins/auth/socialAuth';
	//API
	import { createAccount } from '@/api/apiRegister';

    import firebase from 'firebase/app';
    import 'firebase/auth';

	export default {
		mixins: [socialAuth, steps, goToPage],
		name: 'PrivacyPolicies',
		components: {},
		data() {
			return {
				isLoading: false,
				disabledButton: true,
			};
		},
		created() {
            const auth = firebase.auth.getAuth();
            
			if (this.$store.getters['auth/getSocialType'] == 'google') this.googleLoginCallback(auth);
			if (this.$store.getters['auth/getSocialType'] == 'facebook') this.facebookLoginCallback(auth);
			if (this.$store.getters['auth/getSocialType'] == 'twitter') this.twitterLoginCallback(auth);
		},
		mounted() {
			const div = document.getElementById('policies-body');
			let hasVerticalScrollbar = div.scrollHeight > div.clientHeight;
			if (!hasVerticalScrollbar) {
				this.disabledButton = false;
			}
		},
		methods: {
			register() {
				this.isLoading = true;
				this.$validator.validateAll().then(result => {
					if (result) {
						createAccount(this.getStepData)
							.then(response => {
								console.log(response);
								this.isLoading = false;
								this.setActiveStep(4, null);
							})
							.catch(error => {
								this.isLoading = false;

								this.$buefy.toast.open({
									message: error.response.data.message.pt,
									position: 'is-top',
									type: 'is-danger',
								});
								let infoBack = {
									data: this.getStepData,
									type: 'error',
								};
								this.setActiveStep(2, infoBack);
							});
					} else {
						this.isLoading = false;
						this.$buefy.toast.open({
							message: 'Preencha os campos corretamente',
							position: 'is-top',
							type: 'is-primary',
						});
					}
				});
			},
			goBack() {
				let infoBack = {
					data: this.getStepData,
					type: 'error',
				};
				this.setActiveStep(2, infoBack);
			},
			onScroll: function({ target: { scrollTop, clientHeight, scrollHeight } }) {
				if (scrollTop + clientHeight + 1 >= scrollHeight) {
					this.disabledButton = false;
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../../assets/scss/auth';

	.auth-div {
		top: 8%;
	}

	.email-field {
		margin-top: 30px;
		margin-bottom: 20px;
	}

	.button {
		margin-top: 25px;
		margin-bottom: 5px;
	}

	.next-div {
		margin-top: 25px;
	}

	.register-steps > .step-2 {
		background: #1d4696;
	}

	.mdi {
		color: white;
		margin-right: 10px;
	}
	i::before {
		transform: translate(0, 0);
	}

	.mdi-google {
		transform: translate(0, 0);
	}

	.button-size {
		width: 170px;
		margin-left: 10px;
	}

	@media screen and (max-width: 360px) {
		.button-size {
			width: 140px;
			margin-left: 10px;
		}
	}
</style>
