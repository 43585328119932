import { del, formData, formDataPut, get, post, put } from '@/utils/http';

export function createAccount(data) {
	return post('/emigrants', data);
}

export function activateAccount(user_id) {
	return put('/users/' + user_id + '/activate');
}

export function requestRecoverPassword(data) {
	return post('/users/password/recover', data);
}

export function recoverPassword(token, data) {
	return put('/users/password/recover?token=' + token, data);
}

/*export function changePassword(user_id) {
    return put('/users/' + user_id + '/activate')
}*/

export function completeRegister(data) {
	let form = new FormData();
	form.append('origin_zip_code', data.origin_zip_code);
	form.append('origin_county', data.origin_county);
	form.append('origin_parish', data.origin_parish);
	form.append('origin_street', data.origin_street);
	form.append('destination_country', data.destination_country);
	form.append('destination_zip_code', data.destination_zip_code);
	form.append('destination_county', data.destination_county);
	form.append('destination_city', data.destination_city);
	form.append('destination_street', data.destination_street);
	form.append('name', data.name);

	form.append('birth_date', data.birth_date);
	if (data.phone) {
		form.append('phone', data.phone);
	}
	if (data.photo) {
		form.append('photo', data.photo);
	}
	if (data.gender) {
		form.append('gender', data.gender);
	}
	form.append('email', data.email);
	return formData('/emigrants/register', form);
}

export function resendEmail(data) {
	return get('/users/resend-activation-email?email=' + data.email);
}
