<template>
  <section>
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <section class="modal-card-body">
          <button
            @click="closeModal()"
            class="delete"
            aria-label="close"
          ></button>
          <p class=" modal-card-title title has-text-primary">
            <b>Criação de Negócio Local</b>
          </p>
          <br />
          <div class="field email-field">
            <label class="label">Nome do negócio</label>
            <div class="control has-icons-right">
              <input
                class="input is-success"
                :class="{ 'error-text': errors.has('name') }"
                type="text"
                v-validate="'required'"
                name="name"
                v-model="marketName"
              />
              <span class="icon is-small is-right">
                <i
                  v-show="!errors.has('name')"
                  class="required-icon mdi mdi-check mdi-24px"
                ></i>
              </span>
            </div>
            <p v-show="errors.has('name')" class="help is-danger">
              Insira o nome do negócio!
            </p>
          </div>

          <div class="field email-field">
            <label class="label">Email</label>
            <div class="control has-icons-right">
              <input
                class="input is-success"
                :class="{ 'error-text': errors.has('email') }"
                type="email"
                v-validate="'required|email'"
                name="email"
                v-model="email"
              />
              <span class="icon is-small is-right">
                <i
                  v-show="!errors.has('email') && email != ''"
                  class="required-icon mdi mdi-check mdi-24px"
                ></i>
              </span>
            </div>
            <p v-show="errors.has('email')" class="help is-danger">
              Insira um endereço de email!
            </p>
          </div>

          <div class="field email-field">
            <label class="label">Número de telefone</label>
            <div class="control has-icons-right">
              <input
                class="input is-success"
                :class="{ 'error-text': errors.has('phone') }"
                type="text"
                v-validate="'min:9|max:9'"
                name="phone"
                v-model="phone"
              />
              <span class="icon is-small is-right">
                <i
                  v-show="!errors.has('phone')"
                  class="required-icon mdi mdi-check mdi-24px"
                ></i>
              </span>
            </div>
            <p v-show="errors.has('phone')" class="help is-danger">
              Insira um número de telefone válido!
            </p>
          </div>

          <div class="field email-field">
            <label class="label">Descrição</label>
            <div class="control has-icons-right">
              <textarea
                class="input is-success"
                type="text"
                v-model="description"
              />
            </div>
          </div>

          <div class="field email-field">
            <label class="label">País</label>
            <div class="select control">
              <select v-validate="'required'" v-model="country" name="country">
                <option
                  v-for="(item, index) in postalCodeData"
                  :key="index"
                  :value="item.ISO"
                >
                  {{ item.ISO }} -
                  {{ item.Country }}
                </option>
              </select>
              <p v-show="errors.has('country')" class="help is-danger">
                Insira um país!
              </p>
            </div>
          </div>

          <div class="field email-field">
            <label class="label">Código Postal</label>
            <div style="width: 100%" class="control has-icons-right">
              <input
                ref="host"
                class="input is-success"
                type="text"
                v-validate="{ required: true }"
                name="zipCode"
                :class="{ 'error-text': errors.has('zipCode') }"
                v-model="zipCode"
              />
              <span class="icon is-small is-right">
                <i
                  v-show="!errors.has('zipCode') && zipCode != null"
                  class="required-icon mdi mdi-check mdi-24px"
                ></i>
              </span>
            </div>
            <p v-show="errors.has('zipCode')" class="help is-danger">
              Formato de código postal inválido
            </p>
          </div>

          <div class="field email-field">
            <label class="label">Site</label>
            <div class="control has-icons-right">
              <input
                class="input is-success"
                :class="{ 'error-text': errors.has('site') }"
                type="text"
                v-validate="'url'"
                name="site"
                v-model="site"
              />
              <span class="icon is-small is-right">
                <i
                  v-show="!errors.has('site')"
                  class="required-icon mdi mdi-check mdi-24px"
                ></i>
              </span>
            </div>
            <p v-show="errors.has('site')" class="help is-danger">
              Insira um endereço válido!
            </p>
          </div>

          <div class="field email-field">
            <label class="label">Setor</label>
            <div class="select control">
              <select v-validate="'required'" v-model="area" name="status">
                <option
                  v-for="(item, index) in localMarketAreas"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name_pt }}
                </option>
              </select>
              <p v-show="errors.has('status')" class="help is-danger">
                Selecione uma área de negócio!
              </p>
            </div>
          </div>

          <div class="field email-field">
            <label class="label">Benefícios</label>
            <button class="add-benefit" @click="addBenefit">
              Adicionar Benefício
            </button>

            <div
              style="margin-bottom: 100px"
              v-for="(discount, index) in newBenefits"
              :key="index"
            >
              <hr />
              <label class="label">Tipo de benefício</label>
              <div class="select control">
                <select v-model="benefitType[index]">
                  <option value="fixed_price_purchase"
                    >Desconto fixo na compra</option
                  >
                  <option value="fixed_price_product"
                    >Desconto fixo em produto</option
                  >
                  <option value="percentage_purchase"
                    >Desconto em percentagem na compra</option
                  >
                  <option value="percentage_product"
                    >Desconto em percentagem em produto</option
                  >
                </select>
              </div>

              <label class="label">Valor do desconto</label>
              <input
                class="input is-success center-value"
                style="width:100px"
                type="text"
                v-model="discountsValue[index]"
              />
              <label class="label description-label"
                >Descrição do benefício</label
              >
              <textarea
                class="input is-success"
                type="text"
                v-model="discountsDescripiton[index]"
              />

              <label class="label description-label"
                >Escolha uma cor para o benefício</label
              >
              <v-swatches
                :swatches="swatches"
                v-model="color[index]"
              ></v-swatches>

              <div class="field email-field">
                <vc-date-picker
                  :min-date="new Date()"
                  :model-config="modelConfig"
                  v-model="benefitDate[index]"
                />
              </div>

              <label class="label">Marcar Favorito</label>
              <label class="radio">
                <input
                  v-validate="'required'"
                  name="favourite"
                  type="radio"
                  :value="index"
                  v-model="featured"
                />
              </label>
              <p v-show="errors.has('favourite')" class="help is-danger">
                Selecione um benefício favorito!
              </p>

              <label class="label multiple"
                >Permitir acumular com outros benefícios</label
              >
              <input
                type="checkbox"
                id="checkbox"
                v-model="multiple[index]"
                :value="true"
              />

              <button class="remove-benefit" @click="removeBenefit(index)">
                Remover Benefício
              </button>
            </div>
          </div>

          <div>
            <label class="label">Horário do Negócio</label>
            <business-hours
              @updated-hours="updatedHours"
              :days="days"
              :timeIncrement="15"
              :hourFormat24="true"
              :localization="local"
            ></business-hours>
          </div>

          <b-field class="file is-primary center-inside">
            <b-upload v-model="marketPhoto" class="file-label" accept="image/*">
              <div class="file-cta center-inside">
                <img
                  class="profile-photo"
                  v-if="marketPhoto"
                  :src="getFileURL"
                />
                <i v-else class="file-icon mdi mdi-camera-outline mdi-48px"></i>
              </div>
            </b-upload>
          </b-field>

          <button
            class="button is-rounded is-primary is-fullwidth"
            :class="{ 'is-loading': isButtonLoading }"
            @click="createLocalMarket"
          >
            Criar
          </button>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import { getLocalMarketsAreas, createLocalMarket } from "@/api/apiBackOffice";
import VSwatches from "vue-swatches";
const postalCodeData = require("@/components/Auth/RegisterSteps/postalCodes/postal-codes.json");
export default {
  components: {
    VSwatches,
  },
  data() {
    return {
      isButtonLoading: false,
      inputError: false,
      marketName: null,
      email: "",
      zipCode: null,
      description: null,
      area: null,
      phone: null,
      country: null,
      site: null,
      marketPhoto: null,
      host_country: null,
      postalCodeData,
      localMarketAreas: [],
      discounts: [],
      discountsValue: [],
      discountsDescripiton: [],
      benefits: {},
      color: [],
      benefitType: [],
      newBenefits: [],
      featured: 0,
      multiple: [],
      benefitDate: [],
      modelConfig: {
        type: "string",
      },
      swatches: [
        "#1d4696",
        "#f6a328",
        "#bac3dc",
        "#e64310",
        "#955AE6",
        "#6EFE63",
      ],
      days: {
        sunday: [
          {
            open: "",
            close: "",
            id: "1",
            isOpen: false,
          },
        ],
        monday: [
          {
            open: "",
            close: "",
            id: "2",
            isOpen: false,
          },
        ],
        tuesday: [
          {
            open: "",
            close: "",
            id: "3",
            isOpen: false,
          },
        ],
        wednesday: [
          {
            open: "",
            close: "",
            id: "4",
            isOpen: false,
          },
        ],
        thursday: [
          {
            open: "",
            close: "",
            id: "5",
            isOpen: false,
          },
        ],
        friday: [
          {
            open: "",
            close: "",
            id: "6",
            isOpen: false,
          },
        ],
        saturday: [
          {
            open: "",
            close: "",
            id: "8",
            isOpen: false,
          },
        ],
      },
      local: {
        switchOpen: "Aberto",
        switchClosed: "Fechado",
        placeholderOpens: "Abre às",
        placeholderCloses: "Fechas às",
        addHours: "Adicionar Intervalo",
        open: {
          invalidInput:
            "Por favor introduza um horário de abertura em formato de 24h (exemplo 08:00).",
          greaterThanNext:
            "Por favor introduza um horário de abertura anterior ao horário de fecho.",
          lessThanPrevious:
            "Por favor introduza um horário de abertura posterior ao horário de fecho anterior.",
          midnightNotLast:
            "A meia-noite só pode ser selecionada para a última hora de fecho do dia.",
        },
        close: {
          invalidInput:
            "Por favor introduza um horário de abertura em formato de 24h (exemplo 08:00).",
          greaterThanNext:
            "Por favor introduza um horário de fecho posterior ao horário de abertura.",
          lessThanPrevious:
            "Por favor introduza um horário de fecho posterior ao horário de abertura.",
          midnightNotLast:
            "A meia-noite só pode ser selecionada para a última hora de fecho do dia.",
        },
        t24hours: "24 horas",
        midnight: "Midnight",
        days: {
          monday: "Segunda",
          tuesday: "Terça",
          wednesday: "Quarta",
          thursday: "Quinta",
          friday: "Sexta",
          saturday: "Sábado",
          sunday: "Domingo",
          newYearsEve: 'New Year\'s Eve', // prettier-ignore
          newYearsDay: 'New Year\'s Day', // prettier-ignore
          martinLutherKingJrDay: "Martin Luther King, Jr. Day",
          presidentsDay: 'Presidents\' Day', // prettier-ignore
          easter: "Easter",
          memorialDay: "Memorial Day",
          independenceDay: "Independence Day",
          fourthOfJuly: "4th of July",
          laborDay: "Labor Day",
          columbusDay: "Columbus Day",
          veteransDay: "Veterans Day",
          thanksgivingDay: "Thanksgiving Day",
          christmasEve: "Christmas Eve",
          christmas: "Christmas",
        },
      },
    };
  },

  methods: {
    addBenefit: function() {
      this.newBenefits.push(this.newBenefits.length + 1);
      console.log(this.benefitType);
      console.log(this.discountsValue);
      console.log(this.discountsValue);
      console.log(this.color);
      console.log(this.featured);
    },
    removeBenefit: function(index) {
      this.newBenefits.splice(index, 1);
    },

    closeModal(event) {
      this.$emit("clicked", false);
    },

    updatedHours: function(val) {
      // do whatever you want here
      console.log(this.days);
    },

    createLocalMarket() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let allBenefits = [];
          for (let i = 0; i < this.newBenefits.length; i++) {
            if (i == this.featured) {
              allBenefits.push({
                type: this.benefitType[i],
                value: this.discountsValue[i],
                description: this.discountsDescripiton[i],
                color: this.color[i],
                featured: true,
                multiple: false,
              });
              if (this.multiple[i] || this.multiple === "true") {
                allBenefits[i].multiple = true;
              } else {
                allBenefits[i].multiple = false;
              }

              if (this.benefitDate[i]) {
                allBenefits[i].validity = this.benefitDate[i];
              }
            } else {
              allBenefits.push({
                type: this.benefitType[i],
                value: this.discountsValue[i],
                description: this.discountsDescripiton[i],
                color: this.color[i],
                featured: false,
                multiple: true,
              });
              if (this.multiple[i] || this.multiple === "true") {
                allBenefits[i].multiple = true;
              } else {
                allBenefits[i].multiple = false;
              }

              if (this.benefitDate[i]) {
                allBenefits[i].validity = this.benefitDate[i];
              }
            }
          }

          this.benefits = allBenefits;
          console.log(this.benefits);
          let data = {
            name: this.marketName,
            email: this.email,
            zip_code: this.zipCode,
            country: this.country,
            local_market_area_id: this.area,
            days: this.days,
            benefits: this.benefits,
            ...(this.description != null && { description: this.description }),
            ...(this.phone != null && { phone: this.phone }),
            ...(this.marketPhoto != null && { photo: this.marketPhoto }),
            ...(this.site != null && { url: this.site }),
          };

          createLocalMarket(data)
            .then((response) => {
              console.log(response);
              this.$buefy.toast.open({
                message: "Negócio criado com sucesso!",
                position: "is-top",
                type: "is-danger",
              });
              this.closeModal();
            })
            .catch((error) => {
              console.log(error.response.data.message.pt);
              this.isLoading = false;
              this.$buefy.toast.open({
                message: error.response.data.message.pt,
                position: "is-top",
                type: "is-danger",
              });
            });
        }
      });
    },
  },
  computed: {
    getFileURL() {
      if (this.marketPhoto) {
        return window.URL.createObjectURL(this.marketPhoto);
      }
    },
  },
  mounted() {
    getLocalMarketsAreas()
      .then((response) => {
        console.log(response.data);
        this.localMarketAreas = response.data;
      })
      .catch((error) => {});
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/auth";
@import "../../assets/scss/backofficeLocalMarketModal";
</style>
