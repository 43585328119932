<template>
  <section>
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <section class="modal-card-body">
          <button
            @click="closeModal()"
            class="delete"
            aria-label="close"
          ></button>
          <p class=" modal-card-title title has-text-primary"><b>Ticket</b></p>
          <br />
          <div class="field email-field">
            <label class="label">Tipo de problema</label>
            <span>{{ problem }}</span>
          </div>
          <div class="field email-field">
            <label class="label">Descrição do problema</label>
            <span>{{ description }}</span>
          </div>
          <div class="field email-field">
            <label class="label">Enviado por</label>
            <span>{{ email }}</span>
          </div>
          <div class="field email-field">
            <label class="label">Criado em</label>
            <span>{{ created | moment("dddd, MMMM Do YYYY") }}</span>
          </div>
          <div class="field email-field">
            <label class="label">Estado do ticket</label>
            <div class="select control">
              <select v-validate="'required'" v-model="request" name="status">
                <option value="open">Em aberto</option>
                <option value="in-progress">Em progresso</option>
                <option value="solved">Resolvido</option>
                <option value="closed">Fechado</option>
              </select>
            </div>
            <p v-show="errors.has('status')" class="help is-danger">
              Selecione um tipo de estado
            </p>
          </div>

          <button
            class="button is-rounded is-primary is-fullwidth"
            :class="{ 'is-loading': isButtonLoading }"
            @click="updateTicket"
          >
            Guardar
          </button>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import { updateTicketStatus } from "@/api/apiBackOffice";
export default {
  props: ["id","problem", "description", "created", "email", "status"],
  data() {
    return {
      isButtonLoading: false,
      request: this.status,
      requestDescription: "",
      inputError: false,
    };
  },

  methods: {
    closeModal(event) {
      this.$emit("clicked", false);
      console.log(this.request);
    },

    updateTicket() {
      console.log(this.id);
       updateTicketStatus(this.id, this.request)
        .then((response) => {
         console.log(response);
        })
        .catch((error) => {console.log(error);}); 
        this.closeModal()
    },
  },

};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/auth";
@import "../../assets/scss/backofficeTicketsModal";


</style>
