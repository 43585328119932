<template>
  <section>
    <div class="hitchhike-fields">
      <div class="columns is-gapless is-multiline">
        <div class="column is-4-desktop is-6-tablet" v-for="(hitchhike,index) in requestedHitchhikes "
             v-if="!hitchhike.trash">
          <div class="card">
            <div class="card-content">
              <div class="media">
                <div @click="goToPage('/emigrant/'+hitchhike.car_trip.vehicle.emigrant_id)" class="media-left">
                  <div class="user center-inside">
                    <img class="profile-photo" v-if="hitchhike.car_trip.vehicle.emigrant.photo"
                         :src="hitchhike.car_trip.vehicle.emigrant.photo">
                    <i v-else class="file-icon mdi mdi-camera-outline mdi-36px"></i>
                  </div>
                </div>
                <div class="media-content">
                  <p class="has-text-primary"><b>{{ hitchhike.car_trip.vehicle.emigrant.name }}</b></p>
                  <p class="has-text-dark">{{ hitchhike.car_trip.vehicle.car_model.car_brand.name }} | {{
                      hitchhike.car_trip.vehicle.year
                    }}</p>
                </div>
                <div class="media-right">
                  <div class="media-content">
                    <p class="has-text-secondary"><b>{{ hitchhike.car_trip.distance_text }}</b></p>
                    <p class="has-text-primary"><b>{{ hitchhike.hitchhike_places }} Lug.</b></p>
                  </div>
                </div>
              </div>

              <div class="media">
                <div class="media-left">
                  <span v-if="!hitchhike.trash&&hitchhike.is_accepted" class="tag is-primary is-rounded">Aprovada</span>
                  <span v-else-if="!hitchhike.trash&&!hitchhike.is_accepted" style="color: white"
                        class="tag is-dark is-rounded">Aguarda aprovação</span>
                </div>
              </div>

              <div class="media">
                <div class="media-left">
                  <div class="media-content">
                    <p class="has-text-primary">
                      <b>{{ hitchhike.car_trip.departure_date }}</b></p>
                  </div>
                </div>
                <div class="media-content has-text-centered">
                  <p class="has-text-primary"><b>{{ hitchhike.car_trip.departure_hour }}</b></p>
                </div>
                <div class="media-right">
                  <div class="media-content">

                    <button class="button is-rounded is-danger is-fullwidth"
                            @click="cancelHitchhike(hitchhike.id, index)"><b>Cancelar</b>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

//Mixins
import {userInfo} from "@/mixins/shared/userInfo";
import {goToPage} from "@/mixins/shared/goToPage";
import {loading} from "@/mixins/shared/loading";
//API
import {deleteHitchhike} from "@/api/apiRides";

export default {
  mixins: [loading, userInfo, goToPage],
  props: {
    requestedHitchhikes: {
      type: Array,
      required: true
    }
  },
  name: "ListRequestedHitchhikes",
  data() {
    return {
      isButtonLoading: false
    }
  },
  created() {
  },
  methods: {
    cancelHitchhike(id, index) {
      this.hitchhike_id = id
      this.hitchhike_index = index
      let self = this
      this.$buefy.dialog.confirm({
        title: 'Apagar Viagem',
        canCancel: ['button'],
        message: '<p style="color: #1d4696">Tem a certeza que pretende cancelar esta boleia?</p>',
        cancelText: 'Confirmar',
        confirmText: 'Não',
        type: 'is-primary',
        onCancel() {
          deleteHitchhike(self.hitchhike_id)
              .then(response => {
                self.requestedHitchhikes.splice(self.hitchhike_index, 1)
              })
              .catch(error => {
                self.$buefy.toast.open({
                  message: error.response.data.message.pt,
                  position: "is-top",
                  type: "is-danger"
                });
              })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/hitchhike";

.hitchhike-fields {
  width: 100%;
  height: calc(100vh - 165px);
  position: absolute;
  padding-left: 0;
  padding-right: 0;
  overflow-y: scroll;
}

.card {
  width: 93%;
  border-radius: 25px;
  margin: 10px auto;
  -webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  -moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;

}

.media {
  border: none;
  margin-top: 0;
}

.card .media:not(:last-child) {
  margin-bottom: 0;
}

p {
  font-size: 0.9rem;
}
</style>