import {
    get,
    post, put
} from '@/utils/http';
const queryString = require('query-string');

export function getNearbyEmigrants(query = {}) {
    let _query = '?' + queryString.stringify(query);
    return get('/emigrants/nearby_distance' + _query);
}


export function getBySearch(query = {}) {
    let _query = '?' + queryString.stringify(query);
    console.log(_query);
    return get('/emigrants/search' + _query);
}
