<template>
	<section>
		<div class="local-markets-fields">
			<div class="detailed-local-image center-inside">
				<img class="profile-photo" v-if="marketInfo.photo" :src="marketInfo.photo" />
				<i v-else class="file-icon mdi mdi-camera-outline mdi-36px"></i>
			</div>
			<br />
			<p class="subtitle has-text-primary">
				<b>{{ marketInfo.name }}</b>
			</p>
			<p class="has-text-dark">{{ marketInfo.local_market_area.name_pt }}</p>
			<br />
			<div class="has-text-dark" v-for="(text, index) in description" :key="index">
				<p v-for="desc in text" :key="desc">{{ desc }}</p>
			</div>
			<hr />
			<div v-for="(benefit, index) in marketInfo.benefits" v-if="!benefit.trash">
				<input style="float: right" type="checkbox" v-model="selectedDiscounts" :value="benefit" />
				<p style="font-size: 40px" :style="{ color: benefit.color }">
					{{ benefit.value }}<span v-if="benefit.type == 'percentage_purchase' || benefit.type == 'percentage_product'">%</span><span v-else>€</span>
				</p>
				<p class="has-text-dark">
					{{ benefit.description }}
				</p>
				<p class="has-text-danger info" v-if="benefit.multiple === false">Este desconto não é acumulável com os outros descontos</p>
				<hr />
			</div>

			<p class="subtitle has-text-primary">
				<b>Horário</b>
			</p>
			<ul v-for="days in marketInfo.days">
				<li class="has-text-dark">{{ days }}</li>
			</ul>

			<hr />
			<p class="subtitle has-text-primary">
				<b>Contactos</b>
			</p>
			<a class="has-text-dark" :href="'tel:' + marketInfo.phone">{{ marketInfo.phone }}</a
			><br /><br />
			<a class="has-text-dark" :href="marketInfo.url" target="_blank">{{ marketInfo.url }}</a>

			<hr />
			<gmap-map
				ref="map"
				class="map-size"
				:zoom="12"
				:center="{
					lat: +marketInfo.latitude,
					lng: +marketInfo.longitude,
				}"
				:options="options"
			>
				<gmap-marker
					:icon="mapMarker"
					:position="{
						lat: +marketInfo.latitude,
						lng: +marketInfo.longitude,
					}"
				>
				</gmap-marker>
			</gmap-map>
			<!--        <hr />
              <div>
                <span class="has-text-dark">Valor</span>
                <p class="has-text-primary"><b>18.00€</b></p>
              </div>

              <hr />

              <div>
                <span class="has-text-dark">Serviço</span>
                <p class="has-text-primary"><b>Corte de cabelo + Barba</b></p>
              </div>-->

			<div class="next-div has-text-centered">
				<button @click="openPurchasePage" class="button is-rounded is-primary is-fullwidth">
					Utilizar Desconto
				</button>
			</div>
			<div class="register-steps center-inside is-fullwidth">
				<div class="step"></div>
				<div></div>
				<div></div>
			</div>
			<br />
		</div>
	</section>
</template>

<script>
	//MAP Style
	import { localMarketSteps } from '@/mixins/localMarkets/localMarketSteps';

	const mapStyle = require('@/components/Community/Map/mapStyle.json');
	export default {
		name: 'LocalMarketInfo',
		mixins: [localMarketSteps],
		props: {
			marketInfo: {
				type: Object,
				required: true,
			},
		},
		data() {
			return {
				mapMarker: require('@/assets/images/mapMarkers/mapMarker.png'),
				options: {
					zoomControl: false,
					mapTypeControl: false,
					scaleControl: false,
					streetViewControl: false,
					rotateControl: false,
					fullscreenControl: false,
					disableDefaultUi: false,
					styles: mapStyle,
					error: false,
				},
				selectedDiscounts: [],
				description: [],
			};
		},
		created() {
			this.checkNewLines(this.marketInfo.description);
			console.log(this.description);
		},
		methods: {
			openPurchasePage() {
				if (this.selectedDiscounts.length > 0) {
					if (this.selectedDiscounts.find(discount => discount.multiple === false) && this.selectedDiscounts.length >= 2) {
						this.$buefy.toast.open({
							message: 'Tem descontos não acumuláveis selecionados!',
							position: 'is-top',
							type: 'is-danger',
						});
					} else {
						this.setMarketInfo(1, this.selectedDiscounts);
					}
				} else {
					this.$buefy.toast.open({
						message: 'Por favor selecione pelo menos um desconto',
						position: 'is-top',
						type: 'is-danger',
					});
				}
			},

			checkNewLines(t) {
				this.description.push(t.split(/\r\n|\r|\n/));
				return t.split(/\r\n|\r|\n/);
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../../assets/scss/auth';
	@import '../../../assets/scss/localMarket';

	.select,
	select {
		width: 100% !important;
	}

	.email-field {
		margin-top: 30px;
		margin-bottom: 20px;
	}

	.file-icon {
		margin-right: 0;
		color: white;
	}

	.file-cta {
		width: 400px;
		height: 200px;
		padding: 0;
		background-color: #bac3dc !important;
	}

	.button {
		margin-top: 30px;
	}

	hr {
		background-color: whitesmoke !important;
	}

	.has-text-dark {
		word-wrap: break-word;
		text-align: justify;
	}

	.profile-photo {
		border-radius: 0;
		width: 100%;
		height: 100%;
	}

	.info {
		font-size: 0.75rem;
	}

	ul {
		list-style-type: disc;
	}
</style>
