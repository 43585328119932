<template>
  <section>
    <div class="goods-services-div">
      <div class="goods-header">
        <i @click="checkBack" class="mdi mdi-chevron-left mdi-36px"></i>
        <p class="title has-text-primary"><b>Bens</b></p>
      </div>
      <!--User choose between search or create service-->
      <ChooseType :goodsList="goodsList" :userData="userData" v-if="userData.info && getGoodsStep === 0 && goodsList !== null"></ChooseType>
      <!--Show results after search-->
      <GoodsResults v-else-if="getGoodsStep === 1"></GoodsResults>
    </div>
  </section>
</template>
<script>
//API
import { getGoodsType } from "@/api/apiGoodsAndServices";

//Components
import ChooseType from "@/components/Goods/ChooseType";
import GoodsResults from "@/components/Goods/GoodsResults";
//Mixins
import { userInfo } from "@/mixins/shared/userInfo";
import { goodsSteps } from "@/mixins/goods/goodsSteps";

export default {
  mixins: [userInfo, goodsSteps],
  name: "Goods",
  components: { ChooseType, GoodsResults },
  data() {
    return {
      goodsList: null
    };
  },
  created() {
    getGoodsType()
      .then(response => {
        console.log(response);
        response.data.sort((a, b) => (a.order_menu > b.order_menu ? 1 : -1));
        this.goodsList = response.data;
      })
      .catch(error => {});
  },
  methods: {
    checkBack() {
      if (this.getGoodsStep === 1) {
        this.setGoods(0, null);
      } else this.$router.go(-1);
    }
  }
};
</script>
<style scoped lang="scss">
@import "../assets/scss/goodsAndServices";

.goods-header {
  display: flex;
  align-items: center;
  width: 70vw;
}

.mdi-chevron-left {
  color: #1d4696;
  transform: translate(10px, 3px);
}

</style>
