<template>
	<section>
		<div class="auth-div">
			<p class="title has-text-primary"><b>Editar Publicação</b></p>
		</div>
		<div class="section-card">
			<div class="card">
				<div class="content">
					<div class="header">
						<img v-if="this.details.user.emigrant.photo" class="profile-photo inline" :src="this.details.user.emigrant.photo" />
						<div v-else class="diff-file-cta center-inside inline">
							<i class="diff-file-icon mdi mdi-camera-outline mdi-26px"></i>
						</div>
						<div class="inline">
							<span
								class="new-post-text"
								:style="details.user.emigrant.photo ? 'transform: translateY(-20px)' : 'transform: translateY(0px)'"
								v-if="this.details.user.emigrant.name.length < 17"
								>Publicação de {{ this.details.user.emigrant.name }}</span
							>
							<span v-else class="new-post-text" :style="details.user.emigrant.photo ? 'transform: translateY(-20px)' : 'transform: translateY(0px)'">
								Publicação de
								{{ this.details.user.emigrant.name.substring(0, 14) }}...
							</span>
						</div>
					</div>

					<img v-if="this.details.media_url && this.details.media_type === 'image' && feedImage === null" class="feed-image" :src="details.media_url" />
					<Media
						v-else-if="this.details.media_url && this.details.media_type === 'video' && feedImage === null"
						class="feed-video"
						:kind="'video'"
						:controls="true"
						:loop="true"
						:src="details.media_url"
					>
					</Media>
					<img v-else-if="feedImage && feedImage.type.includes('image')" class="feed-image" :src="getFileURL" />
					<Media v-else-if="feedImage && feedImage.type.includes('video')" class="feed-video" :kind="'video'" :controls="true" :src="getFileURL"> </Media>

					<div v-else class="file-post center-inside inline ">
						<i class="file-icon-post  mdi mdi-camera-outline mdi-48px"></i>
					</div>

					<textarea class="input is-success" :class="{ 'error-text': errors.has('requestDescription') }" type="text" v-model="details.description" name="description" />
				</div>

				<div class=" media">
					<div class="file-cta center-inside inline ">
						<b-upload v-model="feedImage" class="file-label" capture="camera">
							<i class="file-icon mdi mdi-camera-outline mdi-26px"></i>
						</b-upload>
					</div>
					<div class="file-cta center-inside inline ">
						<b-upload v-model="feedImage" class="file-label" accept="image/*">
							<i class="file-icon mdi mdi-image-outline mdi-26px"></i>
						</b-upload>
					</div>
					<div class="file-cta center-inside inline ">
						<b-upload v-model="feedImage" class="file-label" accept="video/*">
							<i class="file-icon mdi mdi-video-outline mdi-26px"></i>
						</b-upload>
					</div>
					<div class="media-content"></div>

					<div class="media-right">
						<div class="file-cta center-inside inline " @click="remove">
							<i class="file-icon mdi mdi-delete mdi-26px"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="next-div has-text-centered">
				<button class="button is-rounded is-primary publish" @click="edit">
					Editar
				</button>
				<button class="button is-rounded is-danger cancel" @click="goBack()">
					Cancelar
				</button>
			</div>
		</div>
	</section>
</template>

<script>
	import { getPostDetails, updatePost, deletePost } from '@/api/apiFeed';
	export default {
		data() {
			return {
				postId: null,
				details: null,
				feedImage: null,
			};
		},
		computed: {
			getFileURL() {
				if (this.feedImage) {
					return window.URL.createObjectURL(this.feedImage);
				}
			},
		},
		methods: {
			postDetails() {
				getPostDetails(this.postId)
					.then(response => {
						this.details = response.data;
						console.log(this.details);
					})
					.catch(error => {});
			},
			goBack() {
				this.$router.push({ name: 'Profile' });
			},

			edit() {
				let image = null;
				if (this.feedImage) {
					image = this.feedImage;
				} else image = this.details.media_url;

				let data = {
					photo: image,
					description: this.details.description,
				};
				updatePost(this.postId, data)
					.then(response => {
						console.log(response);
						this.$buefy.toast.open({
							message: 'Publicação editada com sucesso.',
							position: 'is-top',
							type: 'is-primary',
						});
						this.$router.push({ name: 'Profile' });
					})
					.catch(error => {
						console.log(error);
						this.$buefy.toast.open({
							message: error.response.data.message.pt,
							position: 'is-top',
							type: 'is-danger',
						});
					});
			},

			remove() {
				deletePost(this.postId)
					.then(response => {
						console.log(response);
						this.$buefy.toast.open({
							message: 'Publicação removida com sucesso.',
							position: 'is-top',
							type: 'is-primary',
						});
						this.$router.push({ name: 'Profile' });
					})
					.catch(error => {
						console.log(error);
						this.$buefy.toast.open({
							message: error.response.data.message.pt,
							position: 'is-top',
							type: 'is-danger',
						});
					});
			},
		},
		created() {
			this.postId = this.$route.params.id;
			this.postDetails();
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/createPublication';

	.auth-div {
		padding-left: 12%;
		padding-right: 12%;
		padding-top: 20px;
	}
	.title {
		font-size: 1.5rem;
		margin-top: 4px;
	}

	.section-card {
		margin-top: 20px;
	}
</style>
