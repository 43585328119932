<template>
  <section>
    <div :class="{ 'local-markets-div': getLocalMarketStep !== 1 }">
      <p v-if="getLocalMarketStep !== 1" class="title has-text-primary"><b>Negócio Local</b></p>
      <!--Show Charts With Stats-->
      <LocalMarketStats v-if="getLocalMarketStep === 0"></LocalMarketStats>
      <!--Open Camera to validate QrCode-->
      <ValidateDiscount v-else-if="getLocalMarketStep === 1"></ValidateDiscount>
    </div>
  </section>
</template>

<script>
//Mixins
import { localMarketSteps } from "@/mixins/localMarkets/localMarketSteps";

//Components
import LocalMarketStats from "@/components/LocalMarkets/PageOwnerSteps/LocalMarketStats";
import ValidateDiscount from "@/components/LocalMarkets/PageOwnerSteps/ValidateDiscount";

export default {
  components: { ValidateDiscount, LocalMarketStats },
  mixins: [localMarketSteps],
  name: "LocalMarketsOwner",
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/auth";
@import "../assets/scss/localMarket";

.local-markets-list {
  height: calc(100vh - 90px);
}

.country-list {
  border-radius: 20px;
  background-color: #f6a328;
  color: white;
}

.mdi-24px {
  color: #f6a328;
}

.title {
  font-size: 1.5rem;
  margin-top: 8px;
}
</style>
