<template>
  <Emigrant v-if="userType==='emigrant'"></Emigrant>
  <Backoffice v-else-if="userType==='admin'"></Backoffice>
  <LocalMarketOwner v-else-if="userType==='local_market'"></LocalMarketOwner>
<!--  <LocalMarketOwner ></LocalMarketOwner>-->
</template>

<script>
//Mixins
import {userInfo} from "@/mixins/shared/userInfo";
import {goToPage} from "@/mixins/shared/goToPage";

//Components
import Emigrant from "@/views/Emigrant";
import Backoffice from "@/views/Backoffice";
import LocalMarketOwner from "@/views/LocalMarketOwner";


export default {
  mixins: [userInfo, goToPage],
  name: "HomePage",
  components: {
    Emigrant,
    Backoffice,
    LocalMarketOwner
  },
  data() {
    return {}
  },
  created() {

  }
}
</script>

<style lang="scss" scoped>

</style>