<template>
  <section>
    <div class="goods-services-fields" v-if="services!==null">
      <div class="columns is-gapless is-multiline">
        <div class="column is-4-desktop is-6-tablet" v-for="(service,index) in services">
          <div class="card">
            <div class="card-content">
              <div class="media">
                <div class="media-left">
                  <div class="user center-inside">
                    <img class="profile-photo" v-if="userData.info.photo"
                         :src="userData.info.photo">
                    <i v-else class="file-icon mdi mdi-camera-outline mdi-36px"></i>
                  </div>
                </div>
                <div class="media-content" @click="goToPage('services/'+service.id)">
                  <p class="has-text-primary"><b>{{ service.title }}</b></p>
                  <p class="has-text-dark">{{ service.service_type.name_pt }}</p>
                </div>
                <div class="media-right">
                  <div class="media-content">
                    <p class="has-text-primary">
                      <b>{{ service.hourly_price }} €/hora</b></p>
                  </div>
                </div>
                <!--            <div class="media-right">
                              <div class="media-content">
                                <i class="mdi mdi-heart-outline mdi-24px not-favourite"></i>
                                &lt;!&ndash;                <i v-else class="mdi mdi-heart mdi-24px not-favourite"></i>&ndash;&gt;

                              </div>

                            </div>-->
              </div>
              <div class="media">
                <div class="media-left">
                  <div class="media-content">
                    <button class="button is-rounded is-primary is-fullwidth"
                            @click="openModal(service)"><b>Editar</b>
                    </button>
                  </div>
                </div>
                <div class="media-content has-text-centered">

                </div>
                <div class="media-right">
                  <div class="media-content">
                    <button class="button is-rounded is-danger is-fullwidth"
                            @click="deleteService(service.id,index)"><b>Apagar</b>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EditModal v-if="isModalOpen" :isModalOpen="isModalOpen" :service="selectedService"
               @clicked="closeModal"></EditModal>
  </section>
</template>

<script>
//API
import {listOwnServices, deleteService} from "@/api/apiGoodsAndServices";
//Mixins
import {userInfo} from "@/mixins/shared/userInfo";
import {goToPage} from "@/mixins/shared/goToPage";
import {loading} from "@/mixins/shared/loading";
//Components
import EditModal from "@/components/Services/MyServices/EditModal";


export default {
  mixins: [loading, userInfo, goToPage],
  components: {
    EditModal
  },
  name: "ListServices",
  data() {
    return {
      selectedService: null,
      services: null,
      service_id: "",
      service_index: null,
      isModalOpen: false
    }
  },
  created() {
    this.setPageLoading(true)
    this.getOwnServices()

  },
  methods: {
    getOwnServices() {
      listOwnServices()
          .then(response => {
            console.log(response)
            this.services = response.data
            this.setPageLoading(false)

          })
          .catch(error => {
            this.setPageLoading(false)
          })
    },
    openModal(service) {
      this.selectedService = service
      this.isModalOpen = true

    },
    closeModal(value) {
      this.isModalOpen = value
      this.getOwnServices()
    },
    deleteService(id, index) {
      this.service_id = id
      this.service_index = index
      let self = this
      this.$buefy.dialog.confirm({
        title: 'Apagar Serviço',
        canCancel: ['button'],
        message: '<p style="color: #1d4696">Tem a certeza que pretende eliminar este serviço?</p>',
        cancelText: 'Não',
        confirmText: 'Confirmar',
        type: 'is-primary',
        onConfirm() {
          deleteService(self.service_id)
              .then(response => {
                self.services.splice(self.service_index, 1)
              })
              .catch(error => {
                this.$buefy.toast.open({
                  message: error.response.data.message.pt,
                  position: "is-top",
                  type: "is-danger"
                });
              })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/goodsAndServices";

.goods-services-fields {
  width: 100%;
  height: calc(100vh - 77px);
  position: absolute;
  padding-left: 0;
  padding-right: 0;
  overflow-y: scroll;
}

.card {
  width: 93%;
  border-radius: 25px;
  margin: 10px auto;
  -webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  -moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;

}

.media {
  border: none;
  margin-top: 0;
}
</style>