 // initial state
const state = {
    activeStep: 0,
    activeStepData: null,

}

// getters
const getters = {

    getActiveStep(state, getters) {
        return state.activeStep
    },
    getActiveStepData(state, getters) {
        return state.activeStepData
    }
}

// mutations
const mutations = {
    setActiveStep(state, activeStep) {
        state.activeStep = activeStep
    },
    setActiveStepData(state, activeStepData) {
        state.activeStepData = activeStepData
    }
}


export default {
    namespaced: true,
    state,
    getters,
    mutations
}
