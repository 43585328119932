import {
    getAirport,
    post,
    get,
    del

} from '@/utils/http';

export function getAirports(country) {
    console.log(country);
    return get(`/flights/airports?place=${country}`)
}

export function getFlight(data) {


    console.log(data);

    const params = new URLSearchParams()
    params.append('currency', data.currency)
    params.append('originplace', data.originPlace)
    params.append('destinationplace', data.destinationPlace)
    params.append('outbounddate', data.outboundDate)
    if (data.inboundDate) {
        params.append('inbounddate', data.inboundDate)
    }
    params.append('adults', data.adults.toString())
    params.append('children', data.children.toString())
    params.append('infants', data.infants.toString())
    params.append('stops', data.stops)
    params.append('sort', data.sort)



    return post('/flights/search', params)
}

export function trackFlight(data) {
    const params = new URLSearchParams()

    params.append('originplace', data.originPlace)
    params.append('destinationplace', data.destinationPlace)
    params.append('outbounddate', data.outboundDate)
    if (data.inboundDate) {
        params.append('inbounddate', data.inboundDate)
    }
    params.append('frequency', data.frequency)

    return post('/flights', params)

}

export function listOwnFlights() {
    return get(`/flights`)
}

export function unfollowFlight(id) {

    return del('/flights/' + id)
}

export function getGraphInfo(id, outbound, inbound) {

    return get(`/flights/` + id + `/graphs?outbound=${outbound}&inbound=${inbound}`)
}