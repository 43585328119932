// initial state
const state = {
    notifications: [],
    chatNotifications: null

}

// getters
const getters = {

    getNotifications(state, getters) {
        return state.notifications
    },

    getChatNotifications(state, getters) {
        return state.chatNotifications
    }
}

// mutations
const mutations = {
    setNotifications(state, notifications) {
        state.notifications = notifications
    },
    setChatNotifications(state, chatNotifications) {
        state.chatNotifications = chatNotifications
    }

}


export default {
    namespaced: true,
    state,
    getters,
    mutations
}
