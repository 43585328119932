import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home';
import Login from '@/views/Login';
import Register from '@/views/Register';
import Recover from '@/views/Recover';
import Profile from '@/views/Profile';
import Community from '@/views/Community';
import PublicProfile from '@/views/PublicProfile';
import ConfirmLocation from '@/components/HomePage/ConfirmLocation';
import Notifications from '@/views/Notifications';
import Chat from '@/views/Chat.vue';
import ChatRoom from '@/components/Chat/ChatRoom';
import Flights from '@/views/Flights';
import Hitchhike from '@/views/Hitchhike';
import MyHitchhikes from '@/components/Hitchhike/MyHitchhikes';
import Goods from '@/views/Goods';
import MyGoods from '@/components/Goods/MyGoods';
import Services from '@/views/Services';
import MyServices from '@/components/Services/MyServices';
import ServicePage from '@/components/Services/ServicePage';
import GoodPage from '@/components/Goods/GoodPage';
import LocalMarkets from '@/views/LocalMarkets';
import LocalMarketPage from '@/components/LocalMarkets/LocalMarketPage';
import LocalMarketActivation from '@/views/LocalMarketActivation';
import FlightDetails from '@/components/Flights/FlightDetails';
import Help from '@/views/Help';
import Terms from '@/views/TermsAndConditions';
import PrivacyPolicies from '@/views/PrivacyPolicies';
import CookiesPolicies from '@/views/CookiesPolicies';
import MyFlights from '@/components/Flights/MyFlights';
import TrackingDetails from '@/components/Flights/FlightCharts';
import CreateLocalMarket from '@/components/Backoffice/CreateLocalMarket';
import EditLocalMarket from '@/components/Backoffice/EditLocalMarket';
import EditPublication from '@/components/Profile/EditPublication';
import ResendEmail from '@/views/ResendEmail';
import About from '@/views/About';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: {
			onlyNoAuth: true,
		},
	},
	{
		path: '/register',
		name: 'register',
		component: Register,
		meta: {
			onlyNoAuth: true,
		},
	},
	{
		path: '/recover_password',
		name: 'RecoverPassword',
		component: Recover,
		meta: {
			onlyNoAuth: true,
		},
	},
	//https://dev.app.emigra.iotech.pt/localMarket/activate/40c533c7-48d8-42b5-89db-08d626987efc?activation_code=Z6wrWL1M
	{
		path: '/localMarket/activate/:id',
		name: 'LocalMarketActivation',
		component: LocalMarketActivation,
		meta: {
			onlyNoAuth: true,
		},
	},
	{
		path: '/profile',
		name: 'Profile',
		component: Profile,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/emigrant/:id',
		name: 'PublicProfile',
		component: PublicProfile,
		meta: {
			requiresAuth: true,
		},
	},

	{
		path: '/community/map',
		name: 'Map',
		component: Community,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/community/list',
		name: 'List',
		component: Community,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/chat',
		name: 'Chat',
		component: Chat,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/chat/:room_id/:user_id',
		name: 'ChatRoom',
		component: ChatRoom,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/confirm',
		name: 'ConfirmLocation',
		component: ConfirmLocation,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/notifications',
		name: 'Notifications',
		component: Notifications,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/flights',
		name: 'Flights',
		component: Flights,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/flights/details',
		name: 'FlightsDetails',
		component: FlightDetails,
		meta: {
			requiresAuth: true,
		},
		props: true,
	},
	{
		path: '/myflights',
		name: 'MyFlights',
		component: MyFlights,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/tracking',
		name: 'Tracking',
		component: TrackingDetails,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/hitchhike',
		name: 'Hitchhike',
		component: Hitchhike,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/myhitchhikes',
		name: 'MyHitchhikes',
		component: MyHitchhikes,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/goods',
		name: 'Goods',
		component: Goods,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/mygoods',
		name: 'MyGoods',
		component: MyGoods,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/goods/:good_id',
		name: 'GoodsPage',
		component: GoodPage,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/services',
		name: 'Services',
		component: Services,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/myservices',
		name: 'MyServices',
		component: MyServices,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/services/:service_id',
		name: 'ServicePage',
		component: ServicePage,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/localmarket/:id',
		name: 'LocalMarketPage',
		component: LocalMarketPage,
		meta: {
			requiresAuth: true,
		},
	},

	{
		path: '/localmarket',
		name: 'LocalMarket',
		component: LocalMarkets,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/help',
		name: 'Help',
		component: Help,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/createlocalmarket',
		name: 'CreateLocalMarket',
		component: CreateLocalMarket,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/editlocalmarket',
		name: 'EditLocalMarket',
		component: EditLocalMarket,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/terms',
		name: 'Terms',
		component: Terms,
		/*  meta: {
             requiresAuth: true
         } */
	},
	{
		path: '/privacy',
		name: 'Privacy',
		component: PrivacyPolicies,
		/*  meta: {
             requiresAuth: true
         } */
	},
	{
		path: '/cookies',
		name: 'Cookies',
		component: CookiesPolicies,
		/*  meta: {
             requiresAuth: true
         } */
	},
	{
		path: '/editpost',
		name: 'EditPost',
		component: EditPublication,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/resend',
		name: 'ResendEmail',
		component: ResendEmail,
		meta: {
			onlyNoAuth: true,
		},
	},
	{
		path: '/about',
		name: 'About',
		component: About,
		meta: {
			requiresAuth: true,
		},
	},
];

const router = new VueRouter({
	mode: process.env.VUE_APP_ROUTER_MODE,
	base: process.env.BASE_URL,
	routes,
});

export default router;
