import {answerFollowRequest, follow, deleteFollow} from "@/api/apiFollow";
import {getUserInfo} from "@/api/apiProfile";

export const followUser = {
    methods: {
        followUser(user_id, index) {
            follow(user_id)
                .then(response => {
                    if (this.$route.name === "PublicProfile") {
                        getUserInfo(user_id)
                            .then(response => {
                                this.user = response.data
                                this.user.follow = 'pending'
                            })
                            .catch(error => {

                            })
                    }
                    if (this.$route.name === "Map") {
                        getUserInfo(user_id)
                            .then(response => {
                                this.selectedUser.follow_id = response.data.follow_id
                                this.selectedUser.follows = 'pending'
                            })
                            .catch(error => {


                            })
                    }
                    if (this.$route.name === "List") {
                        getUserInfo(user_id)
                            .then(response => {
                                this.filteredDataArray[index].follow_id = response.data.follow_id
                                this.filteredDataArray[index].follow = 'pending'
                            })
                            .catch(error => {


                            })
                    }


                })
                .catch(error => {

                });
        },
        unfollowUser(follow_id, index) {
            deleteFollow(follow_id)
                .then(response => {
                    if (this.$route.name === "PublicProfile") {
                        this.user.follow = 'none'
                    }
                    if (this.$route.name === "Map") {
                        this.selectedUser.follows = 'none'
                    }
                    if (this.$route.name === "List") {
                        this.filteredDataArray[index].follow = 'none'
                    }

                })
                .catch(error => {

                })
        },
      /*   acceptFollow(follow_id, answer, index) {
            answerFollowRequest(follow_id, answer)
                .then(response => {
                    this.getAllNotifications.splice(index, 1);
                })
                .catch(error => {

                })
        } */
    }
}
