<template>
  <section>
    <!--    <flight-charts></flight-charts> -->
    <flight-form v-if="getFlightStep === 0"></flight-form>
    <flight-going-back v-if="getFlightStep === 1"></flight-going-back>
    <flight-going v-if="getFlightStep === 2"></flight-going>
  </section>
</template>

<script>
import FlightCharts from "../components/Flights/FlightCharts";
import FlightForm from "../components/Flights/FlightForm";
import FlightGoing from "../components/Flights/FlightGoing";
import FlightGoingBack from "../components/Flights/FlightGoingBack";

import { flightSteps } from "@/mixins/flight/flightSteps";

export default {
  mixins: [flightSteps],
  name: "Flights",
  components: {
    FlightCharts,
    FlightForm,
    FlightGoing,
    FlightGoingBack,
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
