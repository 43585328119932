<template>
	<div id="app">
		<template>
			<section>
				<loading :isPageLoading="isPageLoading"></loading>
				<Menu v-if="isUserLogged && !isFirstAuth && (userType === 'emigrant' || userType === 'local_market')"></Menu>
				<router-view :key="$route.fullPath" />
			</section>
		</template>
	</div>
</template>

<script>
	//Mixins
	import { auth } from './mixins/auth/auth';
	import { logout } from '@/mixins/auth/logout';
	import { userInfo } from '@/mixins/shared/userInfo';
	import { loading } from '@/mixins/shared/loading';
	import { notifications } from '@/mixins/notifications/notifications';
	import firebase from 'firebase/app';
	//API
	import { activateAccount } from '@/api/apiRegister';
	//Components
	import Menu from '@/components/Menu/Menu';
	import Loading from '@/components/Loading/Loading';
	import icon from '../public/img/icons/mstile-150x150.png';

	export default {
		mixins: [auth, userInfo, loading, notifications, logout],
		components: {
			Menu,
			Loading,
		},
		data() {
			return {
				firebase: {
					apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
					authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
					databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
					projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
					storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
					messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
					appId: process.env.VUE_APP_FIREBASE_APP_ID,
				},
				img: icon,
			};
		},
		created() {
			// this.logout()
			firebase.initializeApp(this.firebase);

			//Activate User Acount
			if (this.$route.path.indexOf('/activate/') !== -1 && this.$route.name !== 'LocalMarketActivation') {
				let activation_code = this.$route.path.split('/')[2];
				activateAccount(activation_code)
					.then(response => {
						this.isLoading = false;
						this.$buefy.toast.open({
							message: 'Conta ativada com sucesso!',
							position: 'is-top',
							type: 'is-primary',
						});
						this.$router.push('/');
					})
					.catch(error => {
						this.isLoading = false;
						this.$buefy.toast.open({
							message: error.response.data.message.pt,
							position: 'is-top',
							type: 'is-danger',
						});
						this.$router.push('/');
					});
			}

			this.setHasVerifiedToken(false);

			if (this.$store.getters['auth/getToken']) {
				this.authWithToken();
			} else {
				this.setHasVerifiedToken(true);
			}
		},
		sockets: {
			//Coloca as notificações num array na store assim que o user entra na raiz da app
			notification: function(data) {
				let notification = {
					events: {
						onclick: () => {
							this.$router.push('/notifications');
						},
					},
				};

				const isArray = Object.prototype.toString.call(data) == '[object Array]';
				if (isArray) {
					this.setAllNotifications(data);
					console.log(data);
				} else {
					this.$buefy.notification.open({
						message: data.text_pt,
						type: 'is-secondary',
					});
					console.log(data);
					this.$notification.show(
						'e.migra',
						{
							body: data.text_pt,
							icon: this.img,
						},
						notification.events,
						{}
					);
					let dumArray = this.getAllNotifications;
					dumArray.push(data);
					this.$router.push('/notifications');
					this.setAllNotifications(dumArray);
				}
			},
			//Coloca o número de notificações do chat na store assim que o user entra na raiz da app
			chat_get_unread(rooms) {
				//devolve todas as salas em que o utilizador está, numero de mensagens nao lidas e informações para fazer uma lista
				console.log(rooms);
				let countUnread = 0;
				for (let i = 0; i < rooms.length; i++) {
					countUnread += rooms[i].unread;
				}
				this.setChatNotifications(countUnread);
			},
			//Emite uma notificação de mensagem quando o user recebe e acresecenta ao número de guardado na store
			chat_receive_message(data) {
				let notification = {
					events: {
						onclick: () => {
							this.$router.push('/chat');
						},
					},
				};

				//quando recebe uma mensagem nova, se a sala aberta for a que recebeu a mensagem adiciona ao array, senao aumenta o numero de mensagens nao lidas da sala correspondente, se a sala ainda nao existir, vai buscar todas as informações das salas (falta ver se isto funciona direito)
				console.log(data);
				if (this.$route.name !== 'ChatRoom') {
					this.$buefy.notification.open({
						message: `Nova Mensagem de ${data.sender_info.name}<br><br> <b>${data.message}</b>`,
						type: 'is-primary',
					});
					this.setChatNotifications(this.getChatNotifications + 1);
					this.$notification.show(
						'e.migra',
						{
							body: `Nova Mensagem de ${data.sender_info.name} \nMensagem: ${data.message}`,
							icon: this.img,
						},
						notification.events,
						{}
					);
				}
			},
		},

		methods: {},
	};
</script>

<style lang="scss">
		@import './assets/scss/main';
</style>
