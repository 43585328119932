<template>
  <section>
    <div class="local-markets-fields">
      <div class="detailed-local-image center-inside">
        <img class="profile-photo" v-if="marketInfo.photo" :src="marketInfo.photo" />
        <i v-else class="file-icon mdi mdi-camera-outline mdi-36px"></i>
      </div>
      <br />
      <p class="subtitle has-text-primary">
        <b>{{ marketInfo.name }}</b>
      </p>
      <p class="has-text-dark">{{ marketInfo.local_market_area.name_pt }}</p>

      <hr />
      <!-- Outra Solução-->
      <div class="field email-field">
        <label class="label">Valor Total da Compra</label>
        <div class="control has-icons-right">
          <input class="input is-success" :class="{ 'error-text': errors.has('totalPrice') }" type="number" v-validate="'required'" name="totalPrice" v-model="totalPrice" />
          <span class="icon is-small is-right">
            <i v-show="!errors.has('totalPrice') && totalPrice != null" class="required-icon mdi mdi-check mdi-24px"></i>
          </span>
        </div>
        <p v-show="errors.has('totalPrice')" class="help is-danger">
          Introduza o valor
        </p>
        <br />
      </div>
      <span class="has-text-dark">Descontos aplicados</span>
      <div v-for="(discount, index) in getLocalMarketData" :key="index">
        <hr v-if="index === 0" />
        <!--When quantity is needed-->
        <div v-if="discount.type == 'fixed_price_product' || discount.type == 'percentage_product'">
          <p class="has-text-primary">
            <b>{{ discount.value }}<span v-if="discount.type == 'percentage_purchase' || discount.type == 'percentage_product'">%</span><span v-else>€</span></b>
            | {{ discount.description }}
          </p>

          <div class="field email-field">
            <label class="label">Unidades do Artigo</label>
            <div class="control has-icons-right">
              <input class="input is-success" :class="{ 'error-text': errors.has('price') }" type="number" v-validate="'required'" name="price" v-model="price[index]" />
              <span class="icon is-small is-right">
                <i v-show="!errors.has('price') && price != null" class="required-icon mdi mdi-check mdi-24px"></i>
              </span>
            </div>
            <p v-show="errors.has('price')" class="help is-danger">
              Introduza o valor
            </p>
            <br />
          </div>
          <hr />
        </div>
        <div v-else>
          <p class="has-text-primary">
            <b>{{ discount.value }}<span v-if="discount.type == 'percentage_purchase' || discount.type == 'percentage_product'">%</span><span v-else>€</span></b>
            | {{ discount.description }}
          </p>
          <hr />
        </div>
      </div>

      <div class="next-div has-text-centered">
        <button @click="createQRCode" :class="{ 'is-loading': isLoading }" class="button is-rounded is-primary is-fullwidth">
          Gerar QR Code
        </button>
        <button @click="setMarketInfo(0, null, null)" :class="{ 'is-loading': isLoading }" class="button is-rounded is-danger is-fullwidth">
          Cancelar
        </button>
      </div>
      <div class="register-steps center-inside is-fullwidth">
        <div></div>
        <div class="step"></div>
        <div></div>
      </div>
      <br />
    </div>
  </section>
</template>

<script>
//Mixins
import { localMarketSteps } from "@/mixins/localMarkets/localMarketSteps";
//API
import { purchase } from "@/api/apiLocalMarket";

export default {
  name: "LocalMarketDiscount",
  mixins: [localMarketSteps],
  props: {
    marketInfo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      totalPrice: null,
      benefits: [],
      price: [],
      isLoading: false,
      selectedBenefits: []
    };
  },
  created() {
    this.selectedBenefits = this.$store.getters["localMarketSteps/getLocalMarketData"];
    console.log(this.selectedBenefits);
  },
  methods: {
    createQRCode() {
      this.isLoading = true;
      for (let index = 0; index < this.selectedBenefits.length; index++) {
        if (this.selectedBenefits[index].type === "percentage_purchase") {
          this.benefits.push({
            id: this.selectedBenefits[index].id,
            quantity: 0
          });
        } else {
          this.benefits.push({
            id: this.selectedBenefits[index].id,
            quantity: this.price[index]
          });
        }
      }

      this.$validator.validateAll().then(result => {
        if (result) {
          //Colocar quantidades dinamicamente
          let data = {
            amount: this.totalPrice,
            local_market_id: this.marketInfo.id,
            benefits: this.benefits
          };
          purchase(data)
            .then(response => {
              console.log(response.data.body);
              this.setMarketInfo(2, response.data.body, this.selectedBenefits);
              this.isLoading = false;
            })
            .catch(error => {
              this.isLoading = false;
              this.$buefy.toast.open({
                message: error.response.data.message.pt,
                position: "is-top",
                type: "is-danger"
              });
            });
        } else {
          this.isLoading = false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/auth";
@import "../../../assets/scss/localMarket";

.select,
select {
  width: 100% !important;
}

.email-field {
  margin-top: 30px;
  margin-bottom: 20px;
}

.file-icon {
  margin-right: 0;
  color: white;
}

.file-cta {
  width: 400px;
  height: 200px;
  padding: 0;
  background-color: #bac3dc !important;
}

.button {
  margin-top: 30px;
}

hr {
  background-color: whitesmoke !important;
}

.has-text-dark {
  word-wrap: break-word;
  text-align: justify;
}

.profile-photo {
  border-radius: 0;
  width: 100%;
  height: 100%;
}
</style>
