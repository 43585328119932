import {
    get,
    post, put, formData, formDataPut, del
} from '@/utils/http';


export function getServicesList() {
    return get('/servicetypes')
}


export function createService(data) {
    return post('/services', data)
}

export function updateService(data, id) {
    return put('/services/' + id, data)
}

export function deleteService(id) {
    return del('/services/' + id)
}

export function getServiceByID(id) {
    return get('/services/' + id)
}

export function searchService(data) {
    return get('/services/search?' + data)
}

export function listOwnServices() {
    return get('/services')
}

export function getGoodsType() {
    return get('/articletypes')
}

export function createGood(data) {
    let form = new FormData()
    form.append('article_type_id', data.id)
    form.append('title', data.title)
    form.append('description', data.description)
    form.append('price', data.price)
    form.append('share_contact', data.share_contact)
    form.append('share_location', data.share_location)
    form.append('transaction', data.transaction_type)
    form.append('photo', data.photo)
    return formData('/articles', form)
}

export function updateGood(data, id) {
    let form = new FormData()
    form.append('article_type_id', data.id)
    form.append('title', data.title)
    form.append('description', data.description)
    form.append('price', data.price)
    form.append('share_contact', data.share_contact)
    form.append('share_location', data.share_location)
    form.append('transaction', data.transaction_type)
    return formDataPut('/articles/' + id, form)
}

export function deleteGood(id) {
    return del('/articles/' + id)
}

export function soldGood(id) {
    return put('/articles/' + id + '/close')
}

export function getGoodById(id) {
    return get('/articles/' + id)
}

export function searchGoods(data) {
    return get('/articles/search?' + data)
}

export function createWishGood(id) {
    return post('/wisharticles', {
        'article_id': id
    })
}

export function deleteWishGood(id) {
    return del('/wisharticles/' + id)
}

export function listOwnGoods() {
    return get('/articles?status=open')
}

export function listOwnWishGoods() {
    return get('/wisharticles')
}

export function createWishService(id) {
    return post('/wishservices', {
        'service_id': id
    })
}

export function deleteWishService(id) {
    return del('/wishservices/' + id)
}

export function listOwnWishServices() {
    return get('/wishservices')
}