<template>
  <section>
    <div class="goods-services-div">
      <div class="services-header">
        <i @click="checkBack" class="mdi mdi-chevron-left mdi-36px"></i>
        <p class="title has-text-primary"><b>Serviços</b></p>
      </div>

      <!--User choose between search or create service-->
      <ChooseType :servicesList="servicesList" v-if="userData.info && getServicesStep === 0 && servicesList !== null"></ChooseType>
      <!--Show results after search-->
      <ServicesResults v-else-if="getServicesStep === 1"></ServicesResults>
    </div>
  </section>
</template>
<script>
//Components
import ChooseType from "@/components/Services/ChooseType";
import ServicesResults from "@/components/Services/ServicesResults";

//Mixins
import { userInfo } from "@/mixins/shared/userInfo";
import { servicesSteps } from "@/mixins/services/servicesSteps";

//API
import { getServicesList } from "@/api/apiGoodsAndServices";
import { methods } from "qrcode.vue";

export default {
  mixins: [userInfo, servicesSteps],
  name: "Services",
  components: { ChooseType, ServicesResults },
  data() {
    return {
      servicesList: null
    };
  },
  created() {
    getServicesList()
      .then(response => {
        console.log(response);
        response.data.sort((a, b) => (a.order_menu > b.order_menu ? 1 : -1));
        this.servicesList = response.data;
      })
      .catch(error => {});
  },

  methods: {
    checkBack() {
      if (this.getServicesStep === 1) {
        this.setServices(0, null);
      }
      else this.$router.go(-1)
    }
  }
};
</script>
<style scoped lang="scss">
@import "../assets/scss/goodsAndServices";

.services-header{
  display: flex;
  align-items: center;
  width: 70vw;
}

.mdi-chevron-left {
  color: #1d4696;
  transform: translate(10px, 5px);
}
</style>
