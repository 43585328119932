<template>
  <div class="ioNavBar is-fixed-top">
    <div
      @click="toggleBurger()"
      class="ioNavBar_burger_box is-hidden-desktop is-hidden-tablet"
    >
      <div class="ioNavBar_burger"></div>
      <div class="ioNavBar_burger"></div>
      <div class="ioNavBar_burger"></div>
    </div>
    
    <div v-if="personal_area" class="ioNavBar_persoanl_area">
      <p class="title is-6 has-text-grey-dark">username</p>
      <p class="subtitle is-6 has-text-grey">{{ userData.email }}</p>
      <div class="columns">
        <div class="column">
          <button class="button full-width is-primary" @click="editProfile()">
            Ver Perfil
          </button>
        </div>
        <div class="column">
          <button class="button full-width is-dark">Logout</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      personal_area: false,
      clicked: false,
    };
  },
  created() {
    window.addEventListener("click", (ev) => {
      let not_handled_class = [
        "ioNavBar_personal",
        "ioNavBar_persoanl_area",
        "user_image",
        "icon dropdown_icon",
        "dropdown navbar-item",
        "title is-6 has-text-grey-dark",
        "dropdown_span",
        "dropdown_fa fas fa-chevron-down",
        "subtitle is-6 has-text-grey",
      ];
      if (!not_handled_class.includes(ev.target.className)) {
        this.personal_area = false;
      }
      if (
        ev.target.className == "ioNavBar" ||
        ev.target.className == "ioNavBar_personal"
      ) {
        if (this.clicked) {
          this.clicked = false;
          this.$store.commit("backofficeSidebar/setState", this.clicked);
        }
      }
    });
  },
  methods: {
    backHome: function() {
      this.$router.push("/");
    },
    togglePersonalArea() {
      this.personal_area = !this.personal_area;
    },
    toggleBurger() {
      this.clicked = !this.clicked;
      this.$store.commit("backofficeSidebar/setState", this.clicked);
    },
    editProfile() {
      this.$router.push("/profile");
    },
    deleteUser() {
      deleteMyUser()
        .then((response) => {
          console.log("User Apagado");
        })
        .catch((error) => {
          console.log("ERRRO");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/backofficeNavbar';


</style>
