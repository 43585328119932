// initial state
const state = {
    activeFlightStep: 0,
    flightData: null,
    passengers:null,
 

}

// getters
const getters = {

    getActiveFlightStep(state, getters) {
        return state.activeFlightStep
    },
    getFlightData(state, getters) {
        return state.flightData
    },
    getPassengersData(state, getters) {
        return state.passengers
    },
  

}

// mutations
const mutations = {
    setActiveFlightStep(state, activeFlightStep) {
        state.activeFlightStep = activeFlightStep
    },
    setFlightData(state, flightData) {
        state.flightData = flightData
    },
    setPassengers(state, passengers) {
        state.passengers = passengers
    },
  
}


export default {
    namespaced: true,
    state,
    getters,
    mutations
}
