import CapFirebase from '@/mixins/auth/CapacitorFirebaseAuth';

export const logout = {
	methods: {
		async logout() {
			//   const auth = firebase.auth().getAuth();
			await CapFirebase.cfaSignOut();
			this.$store.commit('auth/removeToken');
			this.$router.push('/');
			location.reload();
		},
	},
};
