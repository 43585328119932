<template>
  <section>
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card" style="width:95%">
        <section class="modal-card-body">
          <button @click="closeModal()" class="delete" aria-label="close"></button>
          <p class=" modal-card-title title has-text-primary"><b>Filtros</b></p>
          <br />
          <div class="field email-field">
            <label class="label">País de Acolhimento</label>
            <div class="select control">
              <select name="destination_country" v-model="countryData">
                <option :value="country" v-for="country in postalCodeData">{{ country.Country }} </option>
              </select>
            </div>
          </div>

          <div class="field email-field">
            <label class="label">Cidade de Acolhimento</label>
            <div class="control has-icons-right">
              <input class="input is-success" type="text" name="destination_city" v-model="destination_city" />
            </div>
          </div>
          <div class="field email-field">
            <label class="label">Cidade de Origem</label>
            <div class="control has-icons-right">
              <input class="input is-success" type="text" name="origin_city" v-model="origin_city" />
            </div>
          </div>

          <button class="button is-rounded is-secondary is-fullwidth" @click="saveCloseModal()">
            Guardar
          </button>
          <button class="button is-rounded clear is-fullwidth" @click="clearSearch()">
            Limpar Pesquisa
          </button>
        </section>
      </div>
    </div>
  </section>
</template>
<script>
//ISO Data JSON
const postalCodeData = require("@/components/Auth/RegisterSteps/postalCodes/postal-codes.json");
export default {
  props: {
    isModalOpen: {
      type: Boolean,
      required: true
    }
  },
  name: "FilterModal",
  data() {
    return {
      postalCodeData,
      modalState: this.isModalOpen,
      destination_country: null,
      destination_city: "",
      origin_city: "",
      countryData: null,
      countryName: null
    };
  },
  created() {},
  methods: {
    closeModal(event) {
      this.$emit("clicked", false);
    },
    saveCloseModal(event) {
      if (this.countryData) {
        this.destination_country = this.countryData.ISO;
        this.countryName = this.countryData.Country;
      }
      

      this.$emit("clicked", false);
      this.$emit("data", this.destination_country, this.destination_city, this.origin_city, this.countryName);
    },

    clearSearch() {
      this.countryData = null;
      this.destination_country = null;
      this.destination_city = null;
      this.origin_city = null;
      this.countryName = null;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/auth";

.auth-div {
  bottom: 5%;
}

.field {
  margin-bottom: 0;
}

.email-field {
  position: relative;
  margin-bottom: 30px;
}

.button {
  margin-top: 25px;
  margin-bottom: 5px;
}

.select-origin {
  color: #bac3dc !important;
}

.select,
select {
  width: 100% !important;
}

.modal-card {
  border-radius: 20px;
}

.delete {
  float: right;
}

.clear {
  border: 1px solid $danger;
  color: $danger;
}

.title {
  font-size: 1.5rem;
}

.label,
input {
  font-size: 0.9rem;
}
</style>
