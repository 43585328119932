<template>
  <section>
    <div class="auth-div">
      <div class="has-text-centered">
        <p class="title has-text-primary">
          <b
            >Bem vindo<br />
            ao e.migra!</b
          >
        </p>
        <p class="small-text" style="justify-content: center">
          O seu registo foi concluído!<br />
          Irá receber um email para confirmar o seu registo
        </p>
      </div>
      <div class="auth-buttons">
        <button class="button register is-rounded is-secondary is-fullwidth" @click="goToPage('/login')">
          Fechar
        </button>
        <div class="register-steps center-inside is-fullwidth">
          <div></div>
          <div></div>
          <div></div>
          <div class="step-3"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { goToPage } from "@/mixins/shared/goToPage";
import { steps } from "@/mixins/shared/steps";

export default {
  mixins: [goToPage, steps],
  name: "Step4"
};
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/auth";

.auth-div {
  bottom: 5vh;
}

.auth-buttons {
  padding-top: 50px;
}

.register {
  margin-bottom: 50px;
}

.register-steps > .step-3 {
  background: #1d4696;
}
</style>
