<template>
  <section>
    <div class="field">
      <div class="control  has-icons-right">
        <input
          class="input is-rounded is-focused search-box"
          type="text"
          placeholder="Pesquisar"
          @typing="search"
          v-model="localMarketName"
        />
        <span class="icon is-small is-right">
          <i class="mdi mdi-magnify mdi-24px" @click="search"></i>
        </span>
      </div>
      <br />
      <div class="tabs is-toggle center-inside is-toggle-rounded">
        <div class="tabs-shadow">
          <ul>
            <li
              @click="search(0)"
              :class="{ 'is-active': activeTab === 0 }"
            >
              <a>
                <span><b>Nome</b></span>
              </a>
            </li>
            <li
              @click="search(1)"
              :class="{ 'is-active': activeTab === 1 }"
            >
              <a class="middle-tab">
                <span><b>Benefício</b></span>
              </a>
            </li>
            <li
              @click="search(2)"
              :class="{ 'is-active': activeTab === 2 }"
            >
              <a>
                <span><b>Setor</b></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="local-markets-list" v-if="markets !== null">
      <div class="columns is-mobile is-multiline">
        <div
          class="column is-6-mobile is-3-desktop is-3-tablet"
          v-for="(market, index) in markets"
          :key="index"
        >
          <div class="card">
            <div
              class="card-content"
              @click="goToPage('localmarket/' + market.id)"
            >
              <div class="media">
                <div class="media">
                  <div class="user center-inside">
                    <img
                      class="profile-photo"
                      v-if="market.photo"
                      :src="market.photo"
                    />
                    <i
                      v-else
                      class="file-icon mdi mdi-camera-outline mdi-36px"
                    ></i>
                  </div>
                </div>

                <div class="media-content" v-if="market.benefits.length > 0">
                  <p
                    :style="{ color: benefit.color }"
                    class="has-text-centered discount"
                    v-if="benefit.featured"
                    v-for="(benefit, index) in market.benefits"
                    :key="index"
                  >
                    <b
                      >{{ benefit.value
                      }}<span
                        v-if="
                          market.benefits[index].type ==
                            'percentage_purchase' ||
                            benefit.type == 'percentage_product'
                        "
                        >%</span
                      ><span v-else>€</span></b
                    >
                  </p>
                </div>
              </div>

              <div class="media">
                <div class="media-left">
                  <div class="media-content has-text-primary">
                    <p class="local-market-name">
                      <b>{{ market.name }}</b>
                    </p>
                  </div>
                  <div class="media-content has-text-dark ">
                    <p class="local-market-name">
                      <b>{{ market.local_market_area.name_pt }}</b>
                    </p>
                  </div>
                  <!--                  <div class="media-content has-text-secondary ">
                                      <b>25km</b>
                                    </div>-->
                </div>
                <div class="media-content has-text-centered"></div>
              </div>
            </div>
            <div
              v-if="market.benefits.length > 0 && benefit.featured"
              v-for="benefit in market.benefits"
              :style="{ 'background-color': benefit.color }"
              class="discount-type"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//API
import { getLocalMarketsAreas } from "@/api/apiBackOffice";
import { getLocalMarkets } from "@/api/apiLocalMarket";
//Mixins
import { userInfo } from "@/mixins/shared/userInfo";
import { goToPage } from "@/mixins/shared/goToPage";
import { loading } from "@/mixins/shared/loading";
//Components
import FilterModal from "@/components/LocalMarkets/FilterModal";

export default {
  mixins: [loading, userInfo, goToPage],
  /*  props: {
    isModalOpen: {
      type: Boolean,
      required: true,
    },
  }, */
  name: "ListLocalMarkets",
  components: {
    FilterModal,
  },
  data() {
    return {
      isModalOpen: false,
      markets: null,
      localMarketAreas: null,
      selected: null,
      localMarketName: "",
      activeTab: null,
      order: ""
    };
  },
  created() {
    this.setPageLoading(true);
    this.search();
  },
  methods: {
  

    search(index) {
      this.activeTab = index

      if (this.activeTab === 0) {
        this.order = 'alphabetic'
      }
      else if (this.activeTab === 1) {
        this.order ='sector'
      }
      else if (this.activeTab === 2) {
        this.order='benefit_type'
      }
      else this.order = ''

      const queryString = `name=${this.localMarketName}&order=${this.order}`;
      getLocalMarkets(queryString)
        .then((response) => {
          console.log(response);
          this.markets = response.data;
          this.setPageLoading(false);
        })
        .catch((error) => {
          this.setPageLoading(false);
        });

      getLocalMarketsAreas()
        .then((response) => {
          console.log(response);
          this.localMarketAreas = response.data;
          console.log(this.localMarketAreas);
        })
        .catch((error) => {
          this.setPageLoading(false);
        });
    },

    /* listAllMarkets() {
      getLocalMarkets()
        .then((response) => {
          this.markets = response.data;
          console.log(this.markets);
          this.setPageLoading(false);
        })
        .catch((error) => {
          this.setPageLoading(false);
        });

      getLocalMarketsAreas()
        .then((response) => {
          this.localMarketAreas = response.data;
          console.log(this.localMarketAreas);
        })
        .catch((error) => {
          this.setPageLoading(false);
        });
    }, */
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/localMarket";

.discount-type {
  width: 5px;
  height: 70%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  border-radius: 20px;
}

.local-market-name {
  white-space: nowrap;
  width: 100%;
}

.field {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.icon {
  margin-right: 12px;
}
.search-box {
  height: 50px;
  color: #bac3dc;
  border: none;

  /* box-shadow */
  -webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  -moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
}

.is-focused {
  -webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px !important;
  -moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px !important;
  box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px !important;
}

.search-box::placeholder {
  color: #bac3dc;
  border: none;
}

.control.has-icons-left .icon,
.control.has-icons-right .icon {
  color: #bac3dc;
  height: 2.5em;
  pointer-events: initial;
  position: absolute;
  top: 6px;
  width: 2.5em;
  z-index: 4;
}

.tabs.is-toggle.is-toggle-rounded li:first-child a {
  border-bottom-right-radius: 290486px;
  border-top-right-radius: 290486px;
}

.tabs.is-toggle.is-toggle-rounded li:last-child a {
  border-bottom-left-radius: 290486px;
  border-top-left-radius: 290486px;
}

.tabs-shadow {
  /* border-radius */
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  /* box-shadow */
  -webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  -moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
}

.tabs.is-toggle a {
  border: none;
  color: #1d4696;
}

.tabs {
  padding: 10px;
}

.tabs.is-toggle li.is-active a {
  color: #1d4696;
}

.middle-tab {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

p{
  font-size:0.75rem
}
</style>
