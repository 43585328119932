// initial state
const state = {
	clicked: false
};

// getters
const getters = {
	getState(state, getters) {
		return state.clicked;
	}
};

// mutations
const mutations = {
	setState(state, newState) {
		state.clicked = newState;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations
};
