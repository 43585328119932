<template>
  <section>
    <div v-if="room_info !== null" class="room-name">
      <div class="chat-name">
        <i @click="$router.go(-1)" class="mdi mdi-chevron-left mdi-48px"></i>
        <p  @click="goToPage('/emigrant/' +  conversation_info.id)" class="title has-text-primary">{{ conversation_info.name }}</p>
      </div>
      <hr />
    </div>
    <div class="messages-div" id="chat">
      <ul class="chat-list">
        <li
          :id="'li' + i"
          v-for="(message, i) in messages"
          :key="i"
          :class="message.user_id == my_user ? 'my-msg' : 'others-msg'"
        >
          {{ message.message }}
        </li>
      </ul>
    </div>
    <div class="send-msg">
      <div v-if="!is_request" class="field has-addons">
        <div class="control" style="width: 100%">
          <input
            @keydown.enter.exact="trigger"
            @keydown.enter.shift="msg += '\n'"
            class="input"
            v-model="msg"
            type="text"
            placeholder="Escrever uma mensagem"
          />
        </div>
        <div class="control">
          <a @click="sendMessage" ref="sendReply" class="button is-primary">
            <i class="mdi mdi-send mdi-24px"></i>
          </a>
        </div>
      </div>
      <div class="center-inside" v-else>
        <div>
          <button class="button is-rounded is-primary">
            <b @click="acceptMessage('accept')">aceitar</b>
          </button>
          <button
            @click="acceptMessage('decline')"
            class="button is-rounded is-danger"
          >
            <b>recusar</b>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//Mixins
import { loading } from "@/mixins/shared/loading";
import { goToPage } from "@/mixins/shared/goToPage";
//API
import { answerMessageRequest } from "@/api/apiChat";

export default {
  mixins: [loading, goToPage],
  name: "ChatRoom",
  data() {
    return {
      msg: "",
      open_room_id: "",
      my_user: "",
      messages: [],
      room_info: null,
      is_request: null,
      conversation_info: null,
      answerId: null,
    };
  },
  created() {
    this.setPageLoading(true);
    this.open_room_id = this.$route.params.room_id;
    this.my_user = this.$route.params.user_id;
    //pede as mensagens de uma sala, a que for clicada pelo utilizador
    this.$socket.emit("chat_ask_room", this.my_user, this.open_room_id);
    this.messages = [];
  },
  methods: {
    sendMessage() {
      if (!this.msg) return;
      let msg = {
        message: this.msg,
        room_id: this.open_room_id,
        user_id: this.my_user,
      };
      console.log(msg);
      //envia a mensagem para o servidor
      this.$socket.emit("chat_send_message", msg);
      //colocar a mensagem no array das mensagens
      this.messages.push(msg);
      this.msg = "";
      this.scrollToBottom();
    },
    trigger() {
      this.$refs.sendReply.click();
    },
    scrollToBottom() {
      /* var container = document.getElementById("chat");
       container.scrollTop = container.scrollHeight;
       console.log(container.scrollHeight);*/
    },
    acceptMessage(answer) {
      answerMessageRequest(this.answerId, answer)
        .then((response) => {
          this.is_request = false;
        })
        .catch((error) => {});
    },
  },
  sockets: {
    chat_get_room(data) {
      console.log(data);
      //devolve todas as mensagens de uma sala
      this.is_request = data.is_request;
      this.room_info = data.room;
      this.messages = data.messages;
      this.conversation_info = data.emigrants[0];
      if (this.is_request) this.answerId = data.pending_message_requests[0].id;
      this.setPageLoading(false);
      this.scrollToBottom();
    },
    chat_receive_message(data) {
      console.log(data);
      //quando recebe uma mensagem nova, se a sala aberta for a que recebeu a mensagem adiciona ao array, senao aumenta o numero de mensagens nao lidas da sala correspondente, se a sala ainda nao existir, vai buscar todas as informações das salas (falta ver se isto funciona direito)
      this.messages.push(data);
      this.scrollToBottom();
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/chat";

.room-name {
  position: fixed;
  width: 100%;
  background-color: white;
}

.send-msg {
  bottom: 10px;
  position: relative;
  width: 90%;
  margin: auto;
  padding-top: 10px;
  background-color: white;
  z-index: 36;
}

hr {
  margin: 0;
}

.title {
  margin-left: 0;
}

.chat-name {
  display: flex;
  align-items: center;
  width: 70vw;
}

.mdi-chevron-left {
  color: #1d4696;
}

a {
  border-radius: 50px !important;
  margin-left: 10px;
}

.messages-div {
  padding-top: 100px;
  height: calc(100vh - 50px);
  overflow-y: scroll;
}

.is-danger {
  margin-left: 10px;
}

.input {
  border-radius: 50px !important;
}
</style>
