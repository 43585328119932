<template>
	<section>
		<div class="goods-services-div">
			<p class="title has-text-primary"><b>Ajuda</b></p>

			<div class="settings-body">
				<div class="center-inside">
					<img class="img-header" src="../assets/images/header_emigra.png" />
				</div>
				<div class="info-container mt-6">
					<p class="subtitle">Ajuda</p>
					<p class="has-text-dark">
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
						nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
						eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
					</p>
					<hr />
					<p class="subtitle" >FAQs</p>
					<hr />
					<p @click="showTutorial" class="subtitle">Tutoriais</p>
					<hr />
					<p class="subtitle" @click="openModal">Reportar um problema!</p>
					<hr />
				</div>
			</div>
		</div>
		<Tutorial v-if="this.$store.getters['auth/getShowTutorial']" />
		<CreateHelpModal v-if="isModalOpen" :isModalOpen="isModalOpen" @clicked="closeModal"></CreateHelpModal>
	</section>
</template>

<script>
	import { deactivateAccount, deleteAccount } from '@/api/apiAuth';
	import CreateHelpModal from '@/components/Auth/Help/CreateHelpModal.vue';
	import { logout } from '@/mixins/auth/logout';
	import PrivacyPolicy from '@/components/Settings/PrivatePolicy.vue';
	import Terms from '@/components/Settings/TermsAndConditions.vue';
	import Tutorial from '@/components/HomePage/Tutorial';
	export default {
		mixins: [logout],
		components: {
			PrivacyPolicy,
			Terms,
			Tutorial,
			CreateHelpModal,
		},
		data() {
			return {
				privacyModal: false,
				termsModal: false,
				cookiesModal: false,
				activeTab: 1,
				userId: null,
				isDeleteModal: false,
				tutorial: null,
				isDeactivateModal: false,
				userType: null,
				isModalOpen: false,
			};
		},
		mounted() {
			this.userId = this.$store.getters['auth/getUserData'].id;
			this.userType = this.$store.getters['auth/getUserData'].user_type.type;
		},

		methods: {
			deactivate() {
				deactivateAccount(this.userId)
					.then(async response => {
						console.log(response.data);
						await this.logout();
					})
					.catch(error => {});
			},

			confirmDelete() {
				deleteAccount(this.userId)
					.then(async response => {
						console.log(response.data);
						await this.logout();
					})
					.catch(error => {});
			},

			showTutorial() {
				this.$store.commit('auth/setShowTutorial', true);
			},

			openModal() {
				this.isModalOpen = true;
			},
			closeModal(value) {
				console.log(value);
				this.isModalOpen = value;
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../assets/scss/auth';

	.goods-services-div {
		width: 100%;
		padding-top: 17px;
		background-color: white;
		padding-bottom: 10px;
	}

	.title {
		margin-left: 25px;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		margin-top: 8px;
		font-size: 1.5rem;
	}

	.settings-body {
		padding-left: 6%;
		padding-right: 6%;
	}

	.settings-body p:hover {
		cursor: pointer;
	}

	p {
		color: $primary;
	}

	.has-text-dark {
		text-align: justify;
	}

	.img-header {
		width: 100%;
		height: 150px;
		margin-top: 10px;
		object-fit: cover;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}

	hr {
		background-color: $primary;
		height: 2px !important;
	}
</style>
