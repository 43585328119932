<template>
  <section>
    <div class="tabs is-toggle center-inside is-toggle-rounded">
      <div class="tabs-shadow">

        <ul>
          <li @click="activeTab=1" :class="{'is-active':activeTab===1}">
            <a>
              <span><b>Anunciar</b></span>
            </a>
          </li>
          <li @click="activeTab=0" :class="{'is-active':activeTab===0}">
            <a>
              <span><b>Pequisar</b></span>
            </a>
          </li>
        </ul>

      </div>
    </div>

    <AskForRide :userData="userData" v-if="activeTab===0"></AskForRide>
    <OfferRide v-else :userData="userData"></OfferRide>
  </section>
</template>
<script>

//Components
import AskForRide from "@/components/Hitchhike/AskForHitchhike/AskForHitchhike";
import OfferRide from "@/components/Hitchhike/OfferHitchhike/OfferHitchhike";

//API
import {listUserVehicles} from "@/api/apiCar";

export default {
  name: "ChooseType",
  props: {
    userData: {
      type: Object,
      required: true
    }
  },
  components: {AskForRide, OfferRide},
  data() {
    return {
      activeTab: 0
    };
  },
  created() {
    console.log(this.userData)

  }
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/hitchhike";

.tabs.is-toggle.is-toggle-rounded li:first-child a {
  border-bottom-right-radius: 290486px;
  border-top-right-radius: 290486px;
}

.tabs.is-toggle.is-toggle-rounded li:last-child a {
  border-bottom-left-radius: 290486px;
  border-top-left-radius: 290486px;
}

.tabs-shadow {
  /* border-radius */
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  /* box-shadow */
  -webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  -moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;

}

.tabs.is-toggle a {
  border: none;
  color: #1d4696;
}

.tabs {
  padding: 10px;
}

.tabs.is-toggle li.is-active a {
  color: #1d4696;
}
</style>