<template>
	<section>
		<div class="auth-div">
			<p class="title has-text-primary"><b>Registo</b></p>
			<div class="auth-buttons has-text-centered">
				<button @click="setActiveStep(1, null)" class="button register is-rounded is-primary is-fullwidth"><i class="mdi mdi-email mdi-24px"> </i> Email</button>
				<button @click="setActiveStep(3, 'Google')" class="button register is-rounded is-primary is-fullwidth"><i class="mdi mdi-google mdi-24px"> </i> Google</button>
				<button @click="setActiveStep(3, 'Facebook')" class="button register is-rounded is-primary is-fullwidth"><i class="mdi mdi-facebook mdi-24px"></i> Facebook</button>
				<button @click="setActiveStep(3, 'Twitter')" class="button register is-rounded is-primary is-fullwidth"><i class="mdi mdi-twitter mdi-24px"></i> Twitter</button>
				<br />
				<p class="small-text">Já tem conta? <span class="link-text is-click" @click="goToPage('/login')">Efetue Login</span></p>
			</div>
		</div>
	</section>
</template>

<script>
	//Mixins
	import { socialAuth } from '@/mixins/auth/socialAuth';
	import { steps } from '@/mixins/shared/steps';
	import { goToPage } from '@/mixins/shared/goToPage';

	export default {
		mixins: [socialAuth, steps, goToPage],
		name: 'SelectRegister',
		components: {},
	};
</script>

<style lang="scss" scoped>
	@import '../../../assets/scss/auth';

	.auth-div {
		bottom: 3%;
	}

	.title {
		font-size: 1.5rem;
	}

	.auth-buttons {
		padding-top: 20px;
	}

	.register {
		margin-bottom: 20px;
	}

	.register:hover {
		background-color: white;
		color: #1d4696 !important;
		border: 1px solid #1d4696;
	}

	.register:hover > .mdi {
		color: #1d4696;
	}

	.mdi {
		color: white;
		margin-right: 10px;
	}
</style>
