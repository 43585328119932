<template>
  <section>
    <div style="margin-bottom: 100px" v-for="(discount, index) in benefitsList" :key="index">
      <div class="media">
        <div class="media-left">
          <div class="button is-rounded  is-half" style="color:white" v-if="discount.color" :style="{ backgroundColor: discount.color }">Benefício {{ index + 1 }}</div>
          <div class="button is-rounded  is-half" v-else>Benefício {{ index + 1 }}</div>
        </div>
        <div class="media-content"></div>
        <div class="media-right">
          <i v-show="discount.action !== 'remove'" class="mdi mdi-close-circle-outline mdi-36px" @click="removeBenefit(index)"> </i>
          <p class="removed-message" v-show="discount.action === 'remove'">
            Irá ser removido quando guardar as alterações
          </p>
        </div>
      </div>

      <div class="field email-field">
        <label class="label description-label">Tipo de desconto</label>
        <div class="select control">
          <select v-validate="'required'" name="type" v-model="discount.type" @change="updatedBenefit(index)">
            <option value="fixed_price_purchase">Desconto fixo na compra</option>
            <option value="fixed_price_product">Desconto fixo em produto</option>
            <option value="percentage_purchase">Desconto em % na compra</option>
            <option value="percentage_product">Desconto em % em produto</option>
          </select>

          <p v-show="messageError" class="help is-danger">
            Selecione um desconto!
          </p>
        </div>
      </div>

      <div class="field email-field">
        <label class="label description-label">Valor do desconto</label>
        <div class="control has-icons-right">
          <input class="input is-success" :class="{ 'error-text': errors.has('discount') }" type="number" v-model="discount.value" @keyup="updatedBenefit(index)" />
          <span class="icon is-small is-right">
            <i v-show="!messageError" class="required-icon mdi mdi-check mdi-24px"></i>
          </span>
        </div>
        <p v-show="messageError" class="help is-danger">
          Insira um valor de desconto!
        </p>
      </div>

      <label class="label description-label">Descrição</label>
      <textarea class="input is-success" type="text" v-model="discount.description" @keyup="updatedBenefit(index)" />

      <label class="label description-label">Cor</label>
      <div class="field email-field">
        <v-swatches :swatches="swatches" v-model="discount.color" @input="updatedBenefit(index)"></v-swatches>
        <br />
        <p v-show="messageError" class="help is-danger">
          Escolha uma cor!
        </p>
      </div>

      <label class="label description-label">Validade do desconto</label>
      <div class="field email-field">
        <vc-date-picker :min-date="tomorrow" :model-config="modelConfig" v-model="validities[index]" @input="updatedBenefit(index)" />
      </div>

      <div class="field email-field">
        <label class="label">Marcar como favorito</label>
        <div class="select control">
          <select v-validate="'required'" name="type" v-model="favourite[index]" @change="updatedBenefit(index)">
            <option :value="true">Sim</option>
            <option :value="false">Não</option>
          </select>

          <p v-show="messageError" class="help is-danger">
            Selecione uma opção!
          </p>
        </div>
      </div>

      <label class="label multiple">Acumulável com outros benefícios</label>
      <div class="select control">
        <select v-validate="'required'" name="type" v-model="multiple[index]" @change="updatedBenefit(index)">
          <option value="true">Sim</option>
          <option value="false">Não</option>
        </select>

        <p v-show="messageError" class="help is-danger">
          Selecione uma opção!
        </p>
      </div>
    </div>

    <button class="button is-rounded is-white is-fullwidth" style="margin-bottom:20px" @click="addBenefit">
      Adicionar Benefício
    </button>
  </section>
</template>

<script>
import { getLocalMarketsAreas } from "@/api/apiBackOffice";
import VSwatches from "vue-swatches";
export default {
  props: ["benefits"],
  components: {
    VSwatches
  },
  data() {
    return {
      newBenefits: this.benefits,
      multiple: [],
      favourite: [],
      colors: [],
      values: [],
      types: [],
      benefitDate: [],
      benefitsList: [],
      messageError: false,
      validities: [],
      tomorrow: new Date(),

      modelConfig: {
        type: "string"
      },
      swatches: ["#1d4696", "#f6a328", "#bac3dc", "#e64310"]
    };
  },
  methods: {
    addBenefit: function() {
      this.benefitsList.push({
        type: "",
        value: "",
        description: "",
        color: "#1d4696",
        featured: "",
        action: "new"
      });
    },

    removeBenefit: function(index) {
      this.benefitsList[index].action = "remove";
      this.benefitsList[index].featured = "false";
      this.emitToParent();
    },
    updatedBenefit(index) {
      if (this.benefitsList[index].action === "none") {
        this.benefitsList[index].action = "update";
      }
      this.benefitsList[index].featured = this.favourite[index];
      this.benefitsList[index].multiple = this.multiple[index];
      this.benefitsList[index].validity = this.validities[index];
      console.log(this.benefitsList[index].validity);
      this.emitToParent();
    },

    emitToParent(event) {
      console.log(this.favourite);

      let count;
      count = this.favourite.filter(item => item === true).length;

      if (count > 1) {
        this.$buefy.toast.open({
          message: "Apenas pode ter um benefício como favorito!",
          position: "is-top",
          type: "is-danger"
        });
      } else {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$emit("emitBenefits", {
              benefits: this.benefitsList
            });
            console.log(this.benefitsList);
          }
        });
      }
    }
  },
  created() {
    for (let i = 0; i < this.benefits.length; i++) {
      this.benefitsList.push({
        id: this.benefits[i].id,
        type: this.benefits[i].type,
        value: this.benefits[i].value,
        description: this.benefits[i].description,
        color: this.benefits[i].color,
        featured: this.benefits[i].featured,
        action: "none",
        multiple: this.benefits[i].multiple,
        validity: this.benefits[i].validity
      });
      this.multiple.push(this.benefits[i].multiple);
      this.favourite.push(this.benefits[i].featured);
      this.colors.push(this.benefits[i].color);
      this.types.push(this.benefits[i].types);
      this.values.push(this.benefits[i].value);
      this.validities.push(this.benefits[i].validity);

      console.log(this.benefits[i]);
    }
    console.log(this.benefitsList);
  },

  mounted() {
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
    console.log(this.tomorrow);
    getLocalMarketsAreas()
      .then(response => {
        console.log(response.data);
        this.localMarketAreas = response.data;
      })
      .catch(error => {});
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/auth";
@import "../../assets/scss/backofficeLocalMarketModal";

.button {
  border: 1px solid $dark !important;
  color: $primary;
}

.button:focus,
.button:active,
.button.is-active {
  border: 1px solid $dark !important;
}
.button:focus {
  border: 1px solid $dark !important;
}

.mdi-close-circle-outline::before {
  margin-top: 25px;
  margin-bottom: 5px;
  cursor: pointer;
}

.removed-message {
  width: 126px;
  font-size: 15px;
  color: $danger;
  transform: translateY(17px);
}
</style>
