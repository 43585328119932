// API
import { googleLogin, facebookLogin, twitterLogin } from '@/api/apiAuth';
//Mixins
import { loading } from '@/mixins/shared/loading';

import CapFirebase from '@/mixins/auth/CapacitorFirebaseAuth';

export const socialAuth = {
	mixins: [loading],
	data() {
		return {
			params: {
				client_id: process.env.VUE_APP_GOOGLE_ID,
			},
			renderParams: {
				width: 258,
				height: 40,
				longtitle: true,
			},
			fbId: process.env.VUE_APP_FB_APP_ID,
			FB: {},
			model: {},
			scope: {},

			pageType: null,
		};
	},
	created() {
		//Set PageType based on route name
		this.pageType = this.$route.name;
	},
	computed: {
		getPageType() {
			return this.pageType;
		},
	},
	methods: {
		//FACEBOOK
		facebookLogin() {
			this.setPageLoading(true);
			this.$store.commit('auth/setSocialType', 'facebook');
			CapFirebase.cfaSignInFacebook().subscribe(
				result => {
					facebookLogin(result.credential.accessToken, this.getPageType)
						.then(response => {
							if (response.status === 200) {
								this.$store.dispatch('auth/authenticateUser', response, {
									root: true,
								});
								this.goToPage('/');
							}
						})
						.catch(error => {
							this.$buefy.toast.open({
								message: error.response.data.message.pt,
								position: 'is-top',
								type: 'is-danger',
							});
						})
						.finally(() => {
							this.setPageLoading(false);
							this.$store.commit('auth/setSocialType', null);
						});
				},
				error => {
                    console.log(error);
					this.setPageLoading(false);
					this.$buefy.toast.open({
						message: this.$t('mixins.auth.socialAuth.invalidSocial'),
						position: 'is-top',
						type: 'is-danger',
					});
				},
				() => {
					this.$store.commit('auth/setSocialType', null);
				}
			);
		},
		//TWITTER
		twitterLogin() {
			this.setPageLoading(true);
			this.$store.commit('auth/setSocialType', 'twitter');
			CapFirebase.cfaSignInTwitter().subscribe(
				result => {
					twitterLogin(result.credential.accessToken, result.credential.secret, this.getPageType)
						.then(response => {
							if (response.status === 200) {
								this.$store.dispatch('auth/authenticateUser', response, {
									root: true,
								});
								this.goToPage('/');
							}
						})
						.catch(error => {
							console.log(error);
							this.$buefy.toast.open({
								message: error.response.data.message.pt,
								position: 'is-top',
								type: 'is-danger',
							});
						})
						.finally(() => {
							this.setPageLoading(false);
							this.$store.commit('auth/setSocialType', null);
						});
				},
				error => {
					this.setPageLoading(false);
					this.$buefy.toast.open({
						message: this.$t('mixins.auth.socialAuth.invalidSocial'),
						position: 'is-top',
						type: 'is-danger',
					});
				},
				() => {
					this.$store.commit('auth/setSocialType', null);
				}
			);
		},
		//GOOGLE
		googleLogin() {
			this.setPageLoading(true);
			this.$store.commit('auth/setSocialType', 'google');
			CapFirebase.cfaSignInGoogle().subscribe(
				result => {
					googleLogin(result.credential.idToken, this.getPageType)
						.then(response => {
							if (response.status === 200) {
								this.$store.dispatch('auth/authenticateUser', response, {
									root: true,
								});
								this.goToPage('/');
							}
						})
						.catch(error => {
							console.log(error);
							this.$buefy.toast.open({
								message: error.response.data.message.pt,
								position: 'is-top',
								type: 'is-danger',
							});
						})
						.finally(() => {
							this.setPageLoading(false);
							this.$store.commit('auth/setSocialType', null);
						});
				},
				error => {
					this.setPageLoading(false);
					this.$buefy.toast.open({
						message: this.$t('mixins.auth.socialAuth.invalidSocial'),
						position: 'is-top',
						type: 'is-danger',
					});
				},
				() => {
					this.$store.commit('auth/setSocialType', null);
				}
			);
		},
	},
};
