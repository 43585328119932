<template>
  <div class="local-markets-list">
    <div class="columns is-mobile is-multiline">
      <div class="column is-12-mobile is-12-desktop is-12-tablet">
        <div class="card">
          <div class="card-content">
            <highcharts
              class="hc"
              :options="chartOptions"
              ref="chart"
            ></highcharts>
          </div>
        </div>
      </div>
      <div class="column is-6-mobile is-6-desktop is-6-tablet">
        <div class="card " style="height: 100%">
          <div class="card-content">
            <div class="media">
              <div class="media-right">
                <div class="media-content has-text-primary">
                  <b>Países</b>
                </div>
                <div
                  class="media-content has-text-dark "
                  style="font-size: 14px"
                >
                  <b>Top 5</b>
                </div>
                <div class="media-content has-text-primary ">
                  <p v-for="(country, index) in countries">
                    <i
                      :class="
                        'mdi mdi-numeric-' + country[1] + '-circle mdi-24px'
                      "
                    ></i>
                    {{ country[0] }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-6-mobile is-6-desktop is-6-tablet">
        <div class="card">
          <div class="card-content">
            <highcharts
              class="hc"
              :options="singleColumnChart"
              ref="chart"
            ></highcharts>
          </div>
        </div>
      </div>
    </div>
    <div class="next-div has-text-centered">
      <button
        @click="setMarketInfo(1, null)"
        class="button is-rounded is-primary is-fullwidth"
      >
        Ler QR Code
      </button>
    </div>
  </div>
</template>

<script>
//Charts
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";

exportingInit(Highcharts);

//API
import { getLocalMarketStats } from "@/api/apiLocalMarket";
import { localMarketSteps } from "@/mixins/localMarkets/localMarketSteps";

export default {
  mixins: [localMarketSteps],
  name: "LocalMarketStats",
  data() {
    return {
      readQrCode: false,
      countries: [],
      //Line chart options
      chartOptions: {
        exporting: {
          enabled: false,
        },
        chart: {
          type: "line",
          height: "228px",
        },
        title: {
          style: {
            color: "#1d4696",
            fontFamily: "Poppins",
            fontWeight: "bold",
            fontSize: "16px",
          },
          align: "left",
          text: "Vendas",
        },

        subtitle: {
          style: {
            color: "#bac3dc",
            fontFamily: "Poppins",
            fontWeight: "bold",
            fontSize: "14px",
          },
          align: "left",
          text: "Mensal",
        },

        yAxis: {
          visible: false,
          title: {
            text: "Number of Employees",
          },
        },

        xAxis: {
          categories: [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez",
          ],
        },

        plotOptions: {
          line: {
            dataLabels: {
              enabled: false,
            },
            enableMouseTracking: false,
          },
        },
        series: [
          {
            showInLegend: false,
            name: "",
            data: [],
            color: "#f6a328",
          },
        ],
      },
      //Column Charts Comparison
      singleColumnChart: {
        exporting: {
          enabled: false,
        },
        chart: {
          type: "column",
          height: "228px",
        },
        title: {
          style: {
            color: "#1d4696",
            fontFamily: "Poppins",
            fontWeight: "bold",
            fontSize: "0.9rem",
          },
          align: "left",
          text: "Idades",
        },
        subtitle: {
          style: {
            color: "#bac3dc",
            fontFamily: "Poppins",
            fontWeight: "bold",
            fontSize: "0.9rem",
          },
          align: "left",
          text: "Users",
        },
        xAxis: {
          categories: [],
          crosshair: true,
        },
        yAxis: {
          visible: false,
          min: 0,
          title: {
            text: "",
          },
        },
        /*     tooltip: {
               headerFormat:
                   '<span style="font-size:10px">{point.key}</span><table>',
               pointFormat:
                   '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                   '<td style="padding:0"><b>{point.y:.1f} €</b></td></tr>',
               footerFormat: "</table>",
               shared: true,
               useHTML: true,
             },*/
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        series: [
          {
            showInLegend: false,
            name: "Idades",
            data: [],
            color: "#f6a328",
          },
        ],
        credits: {
          enabled: false,
        },
      },
    };
  },
  created() {
    getLocalMarketStats()
      .then((response) => {
        console.log(response);
        this.countries = response.data.countries;
        this.chartOptions.series[0].data = response.data.amounts_per_month;
        for (let i = 0; i < response.data.ages.length; i++) {
          this.singleColumnChart.xAxis.categories.push(
            response.data.ages[i].min + "-" + response.data.ages[i].max
          );
          this.singleColumnChart.series[0].data.push(
            response.data.ages[i].value
          );
        }
      })
      .catch((error) => {});
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/auth";
@import "../../../assets/scss/localMarket";

.local-markets-list {
  height: calc(100vh - 90px);
}

.country-list {
  border-radius: 20px;
  background-color: #f6a328;
  color: white;
}

.mdi-24px {
  color: #f6a328;
}

b,p,text{
  font-size:0.90rem;
}
</style>
