<template>
	<section>
		<div class="expanded-menu">
			<p class="title"><b>Menu</b></p>

			<div class="expanded-menu-items">
				<div v-if="userType === 'emigrant'" @click="goToRoute('/profile')" class="expanded-menu-item is-click">
					<p class="subtitle"><b>Perfil </b></p>
				</div>
				<div v-if="userType === 'emigrant'" @click="goToRoute('/')" class="expanded-menu-item is-click">
					<p class="subtitle"><b>Feed</b></p>
				</div>

				<div v-if="userType === 'local_market'" @click="goToRoute('/')" class="expanded-menu-item is-click">
					<p class="subtitle"><b>Dashboard</b></p>
				</div>
				<div v-if="userType === 'emigrant'" @click="goToRoute('/notifications')" class="expanded-menu-item is-click">
					<p class="subtitle">
						<b>Notificações</b>
						<span class="notification-expanded has-text-centered" v-if="getAllNotifications.filter(item => item.read === false).length > 0">{{
							getAllNotifications.filter(item => item.read === false).length
						}}</span>
					</p>
				</div>
				<div v-if="userType === 'emigrant'" class="expanded-menu-item is-click">
					<p @click="openItem('comunidade')" class="subtitle">
						<b>Comunidade</b>
					</p>
					<div v-if="itemName === 'comunidade'" class="expanded-menu-item-expanded">
						<p @click="goToRoute('/community/map')" class="is-click">Mapa</p>
						<p @click="goToRoute('/community/list')" class="is-click">Lista</p>
					</div>
				</div>
				<div v-if="userType === 'emigrant'" @click="goToRoute('/chat')" class="expanded-menu-item is-click">
					<p class="subtitle">
						<b>Chat</b>
						<span class="notification-expanded has-text-centered" v-if="getChatNotifications > 0">{{ getChatNotifications }}</span>
					</p>
				</div>
				<div v-if="userType === 'emigrant'" class="expanded-menu-item is-click">
					<p @click="openItem('mobilidade')" class="subtitle">
						<b>Mobilidade </b>
					</p>
					<div v-if="itemName === 'mobilidade'" class="expanded-menu-item-expanded">
						<p class="is-click" style="color: white"><b>Viagens</b></p>
						<p style="padding-left: 10px" class="is-click" @click="goToRoute('/flights')">
							Pesquisar
						</p>
						<p style="padding-left: 10px" class="is-click" @click="goToRoute('/myflights')">
							As minhas Viagens
						</p>
						<p class="is-click" style="color: white"><b>Boleias</b></p>
						<p style="padding-left: 10px" class="is-click" @click="goToRoute('/hitchhike')">
							Anunciar/Pesquisar
						</p>
						<p style="padding-left: 10px" class="is-click" @click="goToRoute('/myhitchhikes')">
							As minhas Boleias
						</p>
					</div>
				</div>
				<div v-if="userType === 'emigrant'" class="expanded-menu-item is-click">
					<p @click="openItem('bens')" class="subtitle"><b>Bens</b></p>
					<div v-if="itemName === 'bens'" class="expanded-menu-item-expanded">
						<p class="is-click" @click="goToRoute('/goods')">
							Anunciar/Pesquisar
						</p>
						<p class="is-click" @click="goToRoute('/mygoods')">Os meus bens</p>
					</div>
				</div>
				<div v-if="userType === 'emigrant'" class="expanded-menu-item is-click">
					<p @click="openItem('serviços')" class="subtitle"><b>Serviços </b></p>
					<div v-if="itemName === 'serviços'" class="expanded-menu-item-expanded">
						<p class="is-click" @click="goToRoute('/services')">
							Anunciar/Pesquisar
						</p>
						<p class="is-click" @click="goToRoute('/myservices')">
							Os meus serviços
						</p>
					</div>
				</div>
				<div v-if="userType === 'emigrant'" @click="goToRoute('/localmarket')" class="expanded-menu-item is-click">
					<p class="subtitle"><b>Negócio Local </b></p>
				</div>
				<div @click="goToRoute('/help')" class="expanded-menu-item is-click">
					<p class="subtitle"><b>Ajuda</b></p>
				</div>
				<div @click="goToRoute('/about')"  class="expanded-menu-item is-click">
					<p class="subtitle"><b>Sobre</b></p>
				</div>
				<div v-if="userType === 'local_market'" @click="logout"  class="expanded-menu-item is-click">
					<p class="subtitle"><b>Terminar Sessão</b></p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	//Mixins
	import { goToPage } from '@/mixins/shared/goToPage';
	import { notifications } from '@/mixins/notifications/notifications';
	import { hitchhikeSteps } from '@/mixins/hitchhikes/hitchhikeSteps';
	import { goodsSteps } from '@/mixins/goods/goodsSteps';
	import { servicesSteps } from '@/mixins/services/servicesSteps';
	import { localMarketSteps } from '@/mixins/localMarkets/localMarketSteps';
	import { logout } from '@/mixins/auth/logout';
	import { flightSteps } from '@/mixins/flight/flightSteps';
	import { userInfo } from '@/mixins/shared/userInfo';

	export default {
		mixins: [goToPage, notifications, hitchhikeSteps, goodsSteps, servicesSteps, localMarketSteps, logout, flightSteps, userInfo],
		name: 'ExpandedMenu',
		data() {
			return {
				itemName: '',
			};
		},
		methods: {
			openItem(item) {
				if (item === this.itemName) {
					this.itemName = '';
				} else {
					this.itemName = item;
				}
			},
			goToRoute(page) {
				this.$emit('close', false);
				// this.isOpen = false
				this.setHitchhike(0, null);
				this.setGoods(0, null);
				this.setServices(0, null);
				this.setMarketInfo(0, null);
				this.$store.commit('flightSteps/setActiveFlightStep', 0);
				this.goToPage(page);
			},
		},
		created() {
			console.log(this.userType);
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/menu';

	.title {
		color: white;
		margin-top: 23px;
		margin-left: 30px;
		font-size: 1.5rem;
		margin-top: 28px;
	}

	.subtitle {
		color: white;
		font-size: 1rem;
	}

	.mdi::before {
		font-size: 24px;
		position: absolute;
		right: 80px;
	}
</style>
