<template>
	<section>
		<CompleteRegister v-if="isFirstAuth"></CompleteRegister>
		<Tutorial v-else-if="!isFirstAuth && showTutorial && !confirmAddress"></Tutorial>
		<Feed v-else></Feed>
	</section>
</template>

<script>
	//Mixins
	import { userInfo } from '@/mixins/shared/userInfo';
	import { goToPage } from '@/mixins/shared/goToPage';
	//Components
	import CompleteRegister from '@/components/HomePage/CompleteRegister';
	import Tutorial from '@/components/HomePage/Tutorial';
	import Feed from '@/components/HomePage/Feed';

	import { refuseConfirmLocation } from '@/api/apiLocation';

	export default {
		name: 'Emigrant',
		mixins: [userInfo, goToPage],
		components: {
			Feed,
			CompleteRegister,
			Tutorial,
		},
		data() {
			return {};
		},
		methods: {
			refuse() {
				refuseConfirmLocation()
					.then(response => {})
					.catch(error => {
						this.isLoading = false;
						this.$buefy.toast.open({
							message: error.response.data.message.pt,
							position: 'is-top',
							type: 'is-danger',
						});
					});
			},
		},
		created() {
			console.log(this.confirmAddress);
			this.$socket.emit('init', { token: this.$store.getters['auth/getToken'] });
			this.$socket.emit('chat_ask_unread', this.$store.getters['auth/getToken']);
			console.log('Socket Connect');
			if (!this.confirmAddress) {
				this.$buefy.dialog.confirm({
					title: 'Confirmar Morada',
					canCancel: ['button'],
					message: '<p style="color: #1d4696">Para continuar a usar a app emigra por favor confirme a sua morada!</p>',
					cancelText: 'Agora não',
					confirmText: 'Confirmar',
					type: 'is-primary',
					onConfirm: () => this.goToPage('/confirm'),
					onCancel: () => this.refuse(),
				});
			}
		},
	};
</script>

<style lang="scss" scoped></style>
