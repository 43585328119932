<template>
  <section class="chat-rooms">
    <br>
    <div class="columns is-mobile horizontal-rooms">
      <div v-if="!room.is_request" class="column is-3" v-for="(room, i) in rooms" :key="i"
           @click="goToPage('/chat/'+room._id+'/'+userData.id)">
        <div class="user center-inside">
          <img class="profile-photo" v-if="room.photo" :src="room.photo">
          <i v-else class="file-icon mdi mdi-camera-outline mdi-36px"></i>
        </div>
        <p class="users-label">{{ room.name }}</p>
      </div>
    </div>
    <hr>
    <div style="max-height: 62vh;
  overflow-y: scroll;">
      <div v-if="room.has_messages&&!room.is_request" v-for="(room, i) in rooms" :key="i"
           @click="goToPage('/chat/'+room._id+'/'+userData.id)">
        <div class="media">
          <div class="media-left">
            <div class="user center-inside">
              <img class="profile-photo" v-if="room.photo" :src="room.photo">
              <i v-else class="file-icon mdi mdi-camera-outline mdi-36px"></i>
            </div>
          </div>
          <div class="media-content">
            <p class="has-text-primary">{{ room.name }}</p>
            <p :class="room.unread>0  ? 'has-text-primary' : 'has-text-dark'">{{ room.last_message.message }}</p>
          </div>
          <div>

          </div>
        </div>
        <br>
      </div>
    </div>

  </section>
</template>

<script>
//Mixins
import {userInfo} from "@/mixins/shared/userInfo";
import {goToPage} from "@/mixins/shared/goToPage";

export default {
  props: {
    rooms: {
      type: Array,
      required: true
    }
  },
  name: "AllChats",
  mixins: [userInfo, goToPage],
  data() {
    return {
    };
  },
  created() {

  }

};
</script>

<style scoped lang="scss">
@import "../../assets/scss/chat";

.columns {
  margin-left: 0;
  margin-bottom: 0;
}

hr {
  margin-top: 0
}

.has-text-primary {
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.has-text-dark {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>