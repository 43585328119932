export const localMarketSteps = {
    computed: {
        getLocalMarketStep() {
            return this.$store.getters["localMarketSteps/getActiveLocalMarketStep"]
        },
        getLocalMarketData() {
            return this.$store.getters["localMarketSteps/getLocalMarketData"]
        }
    },
    methods: {
        setMarketInfo(step, data,benefits) {
            this.$store.commit('localMarketSteps/setActiveLocalMarketStep', step)
            this.$store.commit('localMarketSteps/setLocalMarketData', data)
            this.$store.commit('localMarketSteps/setBenefits',benefits)
        }
    }
}
