<template>
  <section>
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <section class="modal-card-body">
          <button @click="closeModal()" class="delete" aria-label="close"></button>
          <p class=" modal-card-title title has-text-primary"><b>Adicionar Automóvel </b></p>
          <br />
          <div class="field email-field">
            <label class="label">Marca</label>
            <div class="select control">
              <select v-validate="'required'" v-model="carBrand" name="carBrand" @change="getCarModel">
                <option :value="brand.id" v-for="brand in carBrandsArray">{{ brand.name }}</option>
              </select>
            </div>
            <p v-show="errors.has('carBrand')" class="help is-danger">Selecione uma marca</p>
          </div>

          <div class="field email-field">
            <label class="label">Modelo</label>
            <div class="select control">
              <select v-validate="'required'" v-model="carModel" name="carModel">
                <option :value="model.id" v-for="model in carModelsArray">{{ model.name }}</option>
              </select>
            </div>
            <p v-show="errors.has('carModel')" class="help is-danger">Selecione um modelo</p>
          </div>

          <div class="field email-field">
            <label class="label">Ano</label>
            <div class="control has-icons-right">
              <input class="input is-success" :class="{ 'error-text': errors.has('carYear') }" type="number" v-validate="'required'" name="carYear" v-model="carYear" />
              <span class="icon is-small is-right">
                <i v-show="!errors.has('carYear') && carYear != '' && carYear > 1900 && carYear < currentYear" class="required-icon mdi mdi-check mdi-24px"></i>
              </span>
            </div>
            <p v-show="errors.has('carYear') || carYear <= 1900 || carYear > currentYear" class="help is-danger">Insira um ano válido</p>
          </div>

          <div class="field email-field">
            <label class="label">Cor</label>
            <div class="select control">
              <select v-validate="'required'" v-model="carColor" name="carColor">
                <option value="Preto">Preto</option>
                <option value="Branco">Branco</option>
                <option value="Prateado">Prateado</option>
                <option value="Cinzento">Cinzento</option>
                <option value="Vermelho">Vermelho</option>
                <option value="Azul">Azul</option>
                <option value="Castanho">Castanho</option>
                <option value="Verde">Verde</option>
                <option value="Amarelo">Amarelo</option>
                <option value="Outra">Outra</option>
              </select>
            </div>
            <p v-show="errors.has('carColor')" class="help is-danger">Selecione a cor do automóvel</p>
          </div>

          <div class="field email-field">
            <label class="label">Nº de lugares</label>
            <div class="select control">
              <select v-model="carSeats" name="carSeats">
                <option value="2">2</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="7">7</option>
                <option value="9">9</option>
              </select>
            </div>
            <p v-show="errors.has('carSeats')" class="help is-danger">Selecione o número de lugares existentes no automóvel</p>
          </div>
          <div class="field email-field">
            <label class="label">Matrícula</label>
            <div class="control has-icons-right">
              <input class="input is-success" :class="{ 'error-text': errors.has('carPlate') }" type="text" v-validate="'required'" name="carPlate" v-model="carPlate" />
              <span class="icon is-small is-right">
                <i v-show="!errors.has('carPlate') && carPlate != ''" class="required-icon mdi mdi-check mdi-24px"></i>
              </span>
            </div>
            <p v-show="errors.has('carPlate')" class="help is-danger">Insira a matrícula do seu automóvel</p>
          </div>

          <button class="button is-rounded is-primary is-fullwidth" :class="{ 'is-loading': isButtonLoading }" @click="saveCar">Guardar</button>
        </section>
      </div>
    </div>
  </section>
</template>
<script>
//API
import { createVehicle, listAllCarBrands, listCarModelsByCarBrand } from "@/api/apiCar";

export default {
  props: {
    isModalOpen: {
      type: Boolean,
      required: true
    },
    userCar: {
      type: Array,
      required: true
    }
  },
  name: "CreateCarModal",
  data() {
    return {
      isButtonLoading: false,
      modalState: this.isModalOpen,
      carBrandsArray: [],
      carModelsArray: [],
      carBrand: "",
      carModel: "",
      carColor: "Preto",
      carPlate: "",
      carSeats: 5,
      carYear: "",
      currentYear: null
    };
  },
  created() {
    console.log(this.userCar);
    let d = new Date();
    this.currentYear = d.getFullYear();

    listAllCarBrands()
      .then(response => {
        console.log(response);
        this.carBrandsArray = response.data;
        if (this.userCar.length > 0) {
          this.carBrand = this.userCar[0].car_model.car_brand_id;
        } else {
          this.carBrand = this.carBrandsArray[0].id;
        }

        listCarModelsByCarBrand(this.carBrand)
          .then(response => {
            this.carModelsArray = response.data;
            if (this.userCar.length > 0) {
              this.carModel = this.userCar[0].car_model_id;
              this.carColor = this.userCar[0].color;
              this.carSeats = this.userCar[0].places;
              this.carPlate = this.userCar[0].plate;
              this.carYear = this.userCar[0].year;
            } else {
              this.carModel = this.carModelsArray[0].id;
              this.carYear = this.currentYear;
            }
          })
          .catch(error => {});
      })
      .catch(error => {});
  },
  methods: {
    closeModal(event) {
      this.$emit("clicked", false);
    },
    getCarModel() {
      listCarModelsByCarBrand(this.carBrand)
        .then(response => {
          console.log(response);
          this.carModelsArray = response.data;
          this.carModel = this.carModelsArray[0].id;
        })
        .catch(error => {});
    },
    saveCar() {
      this.$validator.validateAll().then(result => {
        if (result && this.carYear > 1900 && this.carYear <= this.currentYear) {
          this.isButtonLoading = true;
          let car = {
            car_model_id: this.carModel,
            color: this.carColor,
            plate: this.carPlate,
            places: this.carSeats,
            year: this.carYear
          };
          createVehicle(car)
            .then(response => {
              console.log(response);
              this.isButtonLoading = true;
              this.closeModal();
              this.$buefy.toast.open({
                message: "Veículo adicionado",
                position: "is-top",
                type: "is-primary"
              });
            })
            .catch(error => {
              console.log(error);
              this.isButtonLoading = true;
              this.$buefy.toast.open({
                message: error.response.data.message.pt,
                position: "is-top",
                type: "is-danger"
              });
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/auth";

.auth-div {
  bottom: 5%;
}

.field {
  margin-bottom: 0;
}

.email-field {
  position: relative;
  margin-bottom: 40px;
}

.button {
  margin-top: 25px;
  margin-bottom: 5px;
}

.next-div {
  margin-top: 25px;
}

.register-steps > .step-1 {
  background: #1d4696;
}

.select-origin {
  color: #bac3dc !important;
}

.select,
select {
  width: 100% !important;
}

.modal-card {
  border-radius: 20px;
}

.delete {
  float: right;
}

.title {
  font-size: 1.5rem;
}

.label,
input,
select,
p {
  font-size: 0.9rem;
}

.small-text,
.link-text,
.radio {
  font-size: 0.75rem;
}

.input {
  padding-left: 9.8px;
}
</style>
