<template>
  <section>
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <section class="modal-card-body">
          <button
            @click="closeModal()"
            class="delete"
            aria-label="close"
          ></button>
          <p class=" modal-card-title title has-text-primary">
            <b>Política de Privacidade</b>
          </p>
          <br />
          <div id="policies-body" class="policies" @scroll="onScroll">
            <div class="policies-header">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
            <div class="policies-body">
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat. Duis aute irure dolor in reprehenderit in voluptate
              velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
              occaecat cupidatat non proident, sunt in culpa qui officia
              deserunt mollit anim id est laborum Lorem ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat. Duis aute irure dolor in reprehenderit in voluptate
              velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
              occaecat cupidatat non proident, sunt in culpa qui officia
              deserunt mollit anim id est laborum Lorem ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat. Duis aute irure dolor in reprehenderit in voluptate
              velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
              occaecat cupidatat non proident, sunt in culpa qui officia
              deserunt mollit anim id est laborum Lorem ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat. Duis aute irure dolor in reprehenderit in voluptate
              velit esse cillum dolore eu fugiat nulla pariatur. 
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    onScroll: function({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.disabledButton = false;
      }
    },
    closeModal(event) {
      this.$emit("clicked", false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/auth";

.auth-div {
  bottom: 5%;
}

.field {
  margin-bottom: 0;
}

.email-field {
  position: relative;
  margin-bottom: 40px;
}

.button {
  margin-top: 25px;
  margin-bottom: 5px;
}

.next-div {
  margin-top: 25px;
}

.register-steps > .step-1 {
  background: #1d4696;
}

.select-origin {
  color: #bac3dc !important;
}

.select,
select {
  width: 100% !important;
}

.modal-card {
  border-radius: 20px;
}

.delete {
  float: right;
}

textarea {
  resize: none;
  max-height: 80px;
}
</style>
