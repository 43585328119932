import {
    get,
    post, put, del
} from '@/utils/http';

export function follow(emigrant_id) {
    return post('/follows', {
        'emigrant_id': emigrant_id
    })
}

export function listFollows() {
    return post('/follows')
}

export function answerFollowRequest(follow_id, status) {
    return put('/follows/' + follow_id, {
        'status': status
    })
}

export function deleteFollow(follow_id) {
    return del('/follows/' + follow_id)
}