var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"ticket-container"},[_c('div',[_c('span',{staticClass:"open"},[_vm._v("Em aberto")]),_c('div',[_c('ul',[_c('draggable',_vm._b({attrs:{"group":"tickets","list":_vm.openTickets},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false},"change":_vm.log},model:{value:(_vm.openTickets),callback:function ($$v) {_vm.openTickets=$$v},expression:"openTickets"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.openTickets),function(ticket){return _c('li',{key:ticket.id,staticClass:"card open",attrs:{"name":!_vm.drag ? 'flip-list' : null,"type":"transition"},on:{"click":function($event){return _vm.openModal(
                  ticket.id,
                  ticket.type,
                  ticket.description,
                  ticket.createdAt,
                  ticket.email,
                  ticket.status
                )}}},[_c('div',[_c('span',{staticClass:"card-label"},[_vm._v("Tipo de problema: ")]),_c('span',[_vm._v(_vm._s(ticket.type)+" ")])]),_c('br'),_c('span',{staticClass:"card-label"},[_vm._v("Descrição do problema:")]),_c('span',{staticClass:"card-description"},[_vm._v(" "+_vm._s(ticket.description))]),_c('br'),_c('span',{staticClass:"card-label"},[_vm._v("Email: ")]),_c('span',[_vm._v(" "+_vm._s(ticket.email))]),_c('br')])}),0)],1)])])]),_c('div',{staticClass:"ticket-container"},[_vm._m(0),_c('div',[_c('ul',[_c('draggable',_vm._b({attrs:{"group":"tickets","list":_vm.progressTickets},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false},"change":_vm.log},model:{value:(_vm.progressTickets),callback:function ($$v) {_vm.progressTickets=$$v},expression:"progressTickets"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.progressTickets),function(ticket){return _c('li',{key:ticket.id,staticClass:"card in-progress",on:{"click":function($event){return _vm.openModal(
                ticket.id,
                ticket.type,
                ticket.description,
                ticket.createdAt,
                ticket.email,
                ticket.status
              )}}},[_c('div',[_c('span',{staticClass:"card-label"},[_vm._v("Tipo de problema: ")]),_c('span',[_vm._v(_vm._s(ticket.type)+" ")])]),_c('br'),_c('span',{staticClass:"card-label"},[_vm._v("Descrição do problema:")]),_c('span',{staticClass:"card-description"},[_vm._v(" "+_vm._s(ticket.description))]),_c('br'),_c('span',{staticClass:"card-label"},[_vm._v("Email: ")]),_c('span',[_vm._v(" "+_vm._s(ticket.email))]),_c('br')])}),0)],1)])]),_c('div',{staticClass:"ticket-container"},[_vm._m(1),_c('div',[_c('ul',[_c('draggable',_vm._b({attrs:{"group":"tickets","list":_vm.solvedTickets},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false},"change":_vm.log},model:{value:(_vm.solvedTickets),callback:function ($$v) {_vm.solvedTickets=$$v},expression:"solvedTickets"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.solvedTickets),function(ticket){return _c('li',{key:ticket.id,staticClass:"card solved",on:{"click":function($event){return _vm.openModal(
                ticket.id,
                ticket.type,
                ticket.description,
                ticket.createdAt,
                ticket.email,
                ticket.status
              )}}},[_c('div',[_c('span',{staticClass:"card-label"},[_vm._v("Tipo de problema: ")]),_c('span',[_vm._v(_vm._s(ticket.type)+" ")])]),_c('br'),_c('span',{staticClass:"card-label"},[_vm._v("Descrição do problema:")]),_c('span',{staticClass:"card-description"},[_vm._v(" "+_vm._s(ticket.description))]),_c('br'),_c('span',{staticClass:"card-label"},[_vm._v("Email: ")]),_c('span',[_vm._v(" "+_vm._s(ticket.email))]),_c('br')])}),0)],1)])]),_c('div',{staticClass:"ticket-container"},[_vm._m(2),_c('div',[_c('ul',[_c('draggable',_vm._b({attrs:{"group":"tickets","list":_vm.closedTickets},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false},"change":_vm.log},model:{value:(_vm.closedTickets),callback:function ($$v) {_vm.closedTickets=$$v},expression:"closedTickets"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.closedTickets),function(ticket){return _c('li',{key:ticket.id,staticClass:"card closed",on:{"click":function($event){return _vm.openModal(
                ticket.id,
                ticket.type,
                ticket.description,
                ticket.createdAt,
                ticket.email,
                ticket.status
              )}}},[_c('div',[_c('span',{staticClass:"card-label"},[_vm._v("Tipo de problema: ")]),_c('span',[_vm._v(_vm._s(ticket.type)+" ")])]),_c('br'),_c('span',{staticClass:"card-label"},[_vm._v("Descrição do problema:")]),_c('span',{staticClass:"card-description"},[_vm._v(" "+_vm._s(ticket.description))]),_c('br'),_c('span',{staticClass:"card-label"},[_vm._v("Email: ")]),_c('span',[_vm._v(" "+_vm._s(ticket.email))]),_c('br')])}),0)],1)])]),(_vm.isModalOpen)?_c('TicketModal',{attrs:{"isModalOpen":_vm.isModalOpen,"id":_vm.modalId,"problem":_vm.modalProblem,"description":_vm.modalDescription,"created":_vm.modalCreatedAt,"email":_vm.modalEmail,"status":_vm.modalStatus},on:{"clicked":_vm.closeModal}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"in-progress"},[_vm._v("Em progresso")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"solved"},[_vm._v("Resolvidos")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"closed"},[_vm._v("Fechados")])])}]

export { render, staticRenderFns }