<template>
  <section>
    <div v-if="getStep===1||getStep===4||getStep===0" class="logo center-inside">
      <img width="140" src="../assets/logos/logo.png">
    </div>
    <!-- C H O O S E  R E G I S T E R -->
    <SelectRegister v-if="getStep===0"></SelectRegister>
    <!--S T E P  1-->
    <Step1 v-else-if="getStep===1"></Step1>
    <!--S T E P  2-->
    <Step2 v-else-if="getStep===2"></Step2>
    <!--S T E P  3-->
    <PrivacyPolicies v-else-if="getStep===3"></PrivacyPolicies>
    <!--S T E P  4-->
    <Step4 v-else-if="getStep===4"></Step4>
  </section>
</template>

<script>
//Components
import SelectRegister from "@/components/Auth/RegisterSteps/SelectRegister";
import PrivacyPolicies from "@/components/Auth/RegisterSteps/PrivacyPolicies";
import Step1 from "@/components/Auth/RegisterSteps/Step1";
import Step2 from "@/components/Auth/RegisterSteps/Step2";
import Step4 from "@/components/Auth/RegisterSteps/Step4";
//Mixins
import {steps} from "@/mixins/shared/steps";

export default {
  name: "Register",
  mixins: [steps],
  components: {
    SelectRegister,
    PrivacyPolicies,
    Step1,
    Step2,
    Step4
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/auth";


</style>