import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
	storage: window.localStorage,
});

import language from './modules/language';
import auth from './modules/auth';
import steps from '@/store/modules/steps';
import loading from '@/store/modules/loading';
import notifications from '@/store/modules/notifications';
import backofficeSidebar from '@/store/modules/backofficeSidebar';
import hitchhikeSteps from '@/store/modules/hitchhikeSteps';
import goodsSteps from '@/store/modules/goodsSteps';
import servicesSteps from '@/store/modules/servicesSteps';
import localMarketSteps from '@/store/modules/localMarketSteps';
import feedSteps from '@/store/modules/feedSteps';
import flightSteps from './modules/flightSteps';

export default new Vuex.Store({
	modules: {
		language,
		auth,
		steps,
		loading,
		notifications,
		backofficeSidebar,
		hitchhikeSteps,
		goodsSteps,
		servicesSteps,
		localMarketSteps,
		feedSteps,
		flightSteps,
	},
	plugins: [vuexLocal.plugin],
});
