export const feedSteps = {
    computed: {
        getFeedStep() {
            return this.$store.getters["feedSteps/getActiveFeedStep"]
        },
        getFeedData() {
            return this.$store.getters["feedSteps/getFeedData"]
        }
    },
    methods: {
        setFeedInfo(step, data) {
            this.$store.commit('feedSteps/setActiveFeedStep', step)
            this.$store.commit('feedSteps/setFeedData', data)
        }
    }
}
