<template>
	<section>
		<div class="goods-services-fields">
			<div class="field email-field">
				<label class="label">Bem</label>
				<div class="control has-icons-right">
					<input class="input is-success" type="text" name="title" v-model="searchText" />
				</div>
			</div>
			<br />
			<div class="field email-field">
				<label class="label">Categoria</label>
				<br />
				<div class="columns is-mobile is-multiline">
					<div v-if="good.icon !== null" class="column is-4 center-inside" v-for="good in goodsList">
						<div
							@click="setSelection(good.id)"
							class="icon-button is-click has-text-centered center-inside"
							:class="{ 'icon-button-selected': selectedGood === good.id }"
						>
							<p class="selected-text" v-if="selectedGood === good.id">
								<b>{{ good.name_pt }}</b>
							</p>
							<i v-else class="file-icon mdi  mdi-36px" :class="good.icon"></i>
						</div>
					</div>
				</div>
			</div>

			<br />
			<br />
			<div class="field email-field">
				<label class="label">Tipo</label>
				<div class="select control">
					<select v-model="goodType" name="goods">
						<option value="sell">Vender</option>
						<option value="buy">Comprar</option>
						<option value="donate">Doar</option>
						<option value="exchange">Trocar</option>
					</select>
				</div>
			</div>
			<br />
			<div class="next-div has-text-centered">
				<button class="button is-rounded is-primary is-fullwidth" @click="searchGoods" :class="{ 'is-loading': isLoading }">
					Pesquisar
				</button>
			</div>
			<br />
			<br />
		</div>
	</section>
</template>

<script>
	//API
	import { searchGoods } from '@/api/apiGoodsAndServices';
	//Mixins
	import { goodsSteps } from '@/mixins/goods/goodsSteps';

	export default {
		mixins: [goodsSteps],
		props: {
			goodsList: {
				type: Array,
				required: true,
			},
		},
		name: 'SearchGoods',
		data() {
			return {
				selectedGood: null,
				searchText: '',
				goodType: null,
				isLoading: false,
			};
		},
		created() {},
		methods: {
			setSelection(good_id) {
				if (this.selectedGood === good_id) {
					this.selectedGood = null;
				} else {
					this.selectedGood = good_id;
				}
			},
			searchGoods() {
				this.isLoading = true;
				this.$validator.validateAll().then(result => {
					if (result) {
						let text = '';
						let category = '';
						if (this.searchText !== '') {
							text = '&text=' + this.searchText;
						}
						if (this.selectedGood !== null) {
							category = '&article_type=' + this.selectedGood;
						}
						if (this.goodType === null) {
							this.goodType = 'all';
						}
						let data = 'transaction=' + this.goodType + text + category;

						searchGoods(data)
							.then(response => {
								console.log(response);
								if (response.data.length <= 0) {
									this.$buefy.toast.open({
										message: 'Não existem bens com as carateristicas selecionadas',
										position: 'is-top',
										type: 'is-danger',
									});
								} else {
									this.setGoods(1, response.data, this.goodType);
								}
								this.isLoading = false;
							})
							.catch(error => {
								this.isLoading = false;
								this.$buefy.toast.open({
									message: error.response.data.message.pt,
									position: 'is-top',
									type: 'is-danger',
								});
							});
					} else {
						this.isLoading = false;
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/auth';
	@import '../../assets/scss/goodsAndServices';

	.select,
	select {
		width: 100% !important;
	}
</style>
