export const hitchhikeSteps = {
    computed: {
        getHitchhikeStep() {
            return this.$store.getters["hitchhikeSteps/getActiveHitchhikeStep"]
        },
        getHitchhikeData() {
            return this.$store.getters["hitchhikeSteps/getHitchhikeData"]
        }
    },
    methods: {
        setHitchhike(step, data) {
            this.$store.commit('hitchhikeSteps/setActiveHitchhikeStep', step)
            this.$store.commit('hitchhikeSteps/setHitchhikeData', data)
        }
    }
}
