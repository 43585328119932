// initial state
const state = {
    activeServicesStep: 0,
    servicesData: null,

}

// getters
const getters = {

    getActiveServicesStep(state, getters) {
        return state.activeServicesStep
    },
    getServicesData(state, getters) {
        return state.servicesData
    }
}

// mutations
const mutations = {
    setActiveServicesStep(state, activeServicesStep) {
        state.activeServicesStep = activeServicesStep
    },
    setServicesData(state, servicesData) {
        state.servicesData = servicesData
    }
}


export default {
    namespaced: true,
    state,
    getters,
    mutations
}
