<template>
  <section v-if="userData">
    <div v-if="getStep===0" class="logo center-inside">
      <img width="140" src="../../assets/logos/logo.png">
    </div>
    <!--S T E P  1-->
    <Step1 :userData="userData" v-if="getStep===0"></Step1>
    <!--S T E P  2-->
    <Step2 :userData="userData" v-else-if="getStep===1"></Step2>
    <!--S T E P  3-->
    <Step3 :userData="userData" v-else-if="getStep===2"></Step3>
  </section>
</template>

<script>
//Components

import Step1 from "@/components/Auth/CompleteRegister/Step1";
import Step2 from "@/components/Auth/CompleteRegister/Step2";
import Step3 from "@/components/Auth/CompleteRegister/Step3";
//Mixins
import {steps} from "@/mixins/shared/steps";
import {userInfo} from "@/mixins/shared/userInfo";

export default {
  name: "CompleteRegister",
  mixins: [steps, userInfo],
  components: {
    Step1,
    Step2,
    Step3
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/auth";

</style>