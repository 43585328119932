export const goodsSteps = {
    computed: {
        getGoodsStep() {
            return this.$store.getters["goodsSteps/getActiveGoodsStep"]
        },
        getGoodsData() {
            return this.$store.getters["goodsSteps/getGoodsData"]
        },
        getGoodType(){
            return this.$store.getters["goodsSteps/getGoodType"]
        }
    },
    methods: {
        setGoods(step, data,type) {
            this.$store.commit('goodsSteps/setActiveGoodsStep', step)
            this.$store.commit('goodsSteps/setGoodsData', data)
            this.$store.commit('goodsSteps/setGoodType', type)
        }
    }
}
