// initial state
const state = {
    isPageLoading: false,
}

// getters
const getters = {

    getLoadingState(state, getters) {
        return state.isPageLoading
    }
}

// mutations
const mutations = {
    setLoadingState(state, isPageLoading) {
        state.isPageLoading = isPageLoading
    }
}


export default {
    namespaced: true,
    state,
    getters,
    mutations
}
