<template>
  <section>
    <div class="local-markets-div">
      <p class="title has-text-primary">
        <b>Negócio Local</b>
      </p>
     

      <ListLocalMarkets
    
        :isModalOpen="isModalOpen"
      ></ListLocalMarkets>
    </div>
  </section>
</template>
<script>
//Components
import ListLocalMarkets from "@/components/LocalMarkets/ListLocalMarkets";

//Mixins
import { userInfo } from "@/mixins/shared/userInfo";

import { getLocalMarkets } from "@/api/apiLocalMarket";

export default {
  mixins: [userInfo],
  name: "LocalMarkets",
  components: { ListLocalMarkets },
  data() {
    return {
      isModalOpen: false,
     
    };
  },
  created() {},
  methods: {
   

   
  },
  watch: {
		searchValue() {
			this.search();
		},
	},
};
</script>
<style scoped lang="scss">
@import "../assets/scss/localMarket";

.title {
  margin-left: 25px;
  overflow: unset;
  font-size: 1.5rem;
  margin-top:8px
}




</style>
