<template>
  <section>
    <div class="compact-menu">
      <div class="compact-menu-items">
        <div class="center-inside is-click">
          <div
            v-if="userType === 'emigrant'"
            class="user"
            :class="{ 'center-inside': !userData.info.photo }"
          >
            <img
              @click="goToRoute('/profile')"
              class="photo-compact"
              v-if="userData.info.photo"
              :src="userData.info.photo"
            />
            <i v-else class="mdi mdi-camera-outline mdi-24px"></i>
          </div>
        </div>
        <div class="compact-menu-item is-click">
          <i @click="goToRoute('/')" class="mdi  mdi-home-outline "></i>
        </div>
        <div
          v-if="userType === 'emigrant'"
          @click="goToRoute('/notifications')"
          class="compact-menu-item is-click"
        >
          <i class="mdi  mdi-bell-outline"></i>
          <span
            class="notification-compact center-inside"
            v-if="
              getAllNotifications.filter((item) => item.read === false).length >
                0
            "
            >{{
              getAllNotifications.filter((item) => item.read === false).length
            }}</span
          >
        </div>
        <div v-if="userType === 'emigrant'" class="compact-menu-item is-click">
          <i @click="goToRoute('/community/map')" class="mdi   mdi-web"></i>
        </div>
        <div v-if="userType === 'emigrant'" class="compact-menu-item is-click">
          <i @click="goToRoute('/chat')" class="mdi  mdi-chat-outline"></i>
          <span
            class="notification-compact center-inside"
            v-if="getChatNotifications > 0"
            >{{ getChatNotifications }}</span
          >
        </div>
        <div v-if="userType === 'emigrant'" class="compact-menu-item is-click">
          <i @click="goToRoute('/flights')" class="mdi  mdi-airplane"></i>
        </div>
        <div v-if="userType === 'emigrant'" class="compact-menu-item is-click">
          <i @click="goToRoute('/goods')" class="mdi  mdi-shopping-outline"></i>
        </div>
        <div v-if="userType === 'emigrant'" class="compact-menu-item is-click">
          <i
            @click="goToRoute('/services')"
            class="mdi  mdi-layers-triple-outline"
          ></i>
        </div>
        <div v-if="userType === 'emigrant'" class="compact-menu-item is-click">
          <i
            @click="goToRoute('/localmarket')"
            class="mdi  mdi-store"
          ></i>
        </div>
        <div class="compact-menu-item is-click">
          <i @click="goToRoute('/about')" class="mdi  mdi-information-outline"></i>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//Mixins
import { userInfo } from "@/mixins/shared/userInfo";
import { goToPage } from "@/mixins/shared/goToPage";
import { notifications } from "@/mixins/notifications/notifications";
import { hitchhikeSteps } from "@/mixins/hitchhikes/hitchhikeSteps";

export default {
  mixins: [userInfo, goToPage, notifications, hitchhikeSteps],
  name: "CompactMenu",
  data() {
    return {};
  },
  methods: {
    openItem(item) {
      if (item === this.itemName) {
        this.itemName = "";
      } else {
        this.itemName = item;
      }
    },
    goToRoute(page) {
      this.$emit("close", false);
      this.setHitchhike(0, null);
      this.goToPage(page);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/menu";

.photo-compact {
  border-radius: 50%;
  height: 60px !important;
  width: 60px !important;
}

.mdi{
  font-size:30px
}
</style>
