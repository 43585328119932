<template>
  <b-table :data="data">
    <b-table-column>
      <template v-slot="props">
        <div class="mobile">
          <div class="file-cta center-inside">
            <img
              class="profile-photo"
              v-if="props.row.photo"
              :src="props.row.photo"
            />

            <i v-else class="file-icon mdi mdi-camera-outline mdi-48px"></i>
          </div>
        </div>
      </template>
    </b-table-column>
    <template v-for="column in columns">
      <b-table-column :key="column.id" v-bind="column">
        <template
          v-if="column.searchable && !column.numeric"
          #searchable="props"
        >
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder="Pesquisa"
            icon="magnify"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          {{ props.row[column.field] }}
        </template>
      </b-table-column>
    </template>

    <b-table-column label="Email">
      <template v-slot="props">
        {{ props.row.user.email }}
      </template>
    </b-table-column>

    <b-table-column v-slot="props">
      <b-button
        rounded
        class="deactivate-account"
        v-if="props.row.user.active === true"
        @click="deactivate(props.row.user_id)"
        >Desativar Conta</b-button
      >
      <b-button
        rounded
        v-else
        class="activate-account"
        @click="activate(props.row.user_id)"
        >Ativar Conta</b-button
      >
    </b-table-column>
  </b-table>
</template>

<script>
import {
  getUsers,
  deactivateAccount,
  activateAccount,
} from "@/api/apiBackOffice";

export default {
  data() {
    return {
      data: [],
      id: "",
      columns: [
        {
          field: "name",
          label: "Nome",
          searchable: true,
          width: "400",
        },
        {
          field: "origin_country",
          label: "País de origem",
          searchable: false,
          centered: true,
        },
        {
          field: "destination_country",
          label: "País de acolhimento",
          searchable: false,
          centered: true,
        },
      ],
    };
  },
  methods: {
    log(index) {
      console.log(index);
    },

    deactivate(id) {
      deactivateAccount(id)
        .then((response) => {
          console.log(response);
          getUsers()
            .then((response) => {
              console.log(response.data);
              this.data = response.data;
            })
            .catch((error) => {});
        })
        .catch((error) => {
          console.log(error);
        });
    },

    activate(id) {
      activateAccount(id)
        .then((response) => {
          console.log(response);
          getUsers()
            .then((response) => {
              console.log(response.data);
              this.data = response.data;
            })
            .catch((error) => {});
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    getUsers()
      .then((response) => {
        console.log(response.data);
        this.data = response.data;
      })
      .catch((error) => {});
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/backofficeUser";

</style>
