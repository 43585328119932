import {
    get,
    post, put
} from '@/utils/http';


export function getLocalMarkets(queryString) {
    return get('/localmarkets?'+queryString)
}

export function getLocalMarketByID(id) {
    return get('/localmarkets/' + id)
}

export function activateLocalMarketAccount(user_id, code, data) {
    return put('/users/' + user_id + '/activate?activation_code=' + code, data)
}

export function getLocalMarketStats() {
    return get('/purchases/graphs?year=2021',)
}

export function purchase(data) {
    return post('/purchases', data)
}

export function getPurchaseById(id) {
    return get('/purchases/' + id)
}

export function validatePurchase(id, answer) {
    return put('/purchases/' + id, {
        'answer': answer
    })
}