import { post } from '@/utils/http';

export function confirmGeoLocation(latLng, accuracy) {
	return post('/emigrants/confirm-location', {
		LatLng: latLng,
		accuracy: accuracy,
	});
}

export function refuseConfirmLocation() {
	return post('/emigrants/refuse-confirm-location',{});
}
