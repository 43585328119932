import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import VeeValidate from 'vee-validate';
import VueCookies from 'vue-cookies';
import Buefy from 'buefy';
import vuexI18n from 'vuex-i18n';
import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import VueSocketIO from 'vue-socket.io';
import Vue2TouchEvents from 'vue2-touch-events';
import HighchartsVue from 'highcharts-vue';
import ChartsCard from '../src/layout/ChartsCard';
import VCalendar from 'v-calendar';
import BusinessHours from 'vue-business-hours';
import 'vue-swatches/dist/vue-swatches.css';
import VueQrcodeReader from 'vue-qrcode-reader';
import Media from '@dongido/vue-viaudio';
import Waterfall from 'vue-waterfall/lib/waterfall';
import WaterfallSlot from 'vue-waterfall/lib/waterfall-slot';
import VueNativeNotification from 'vue-native-notification';
import "vue-select/dist/vue-select.css";

Vue.component('BusinessHours', BusinessHours);
Vue.use(Vue2TouchEvents);
Vue.use(HighchartsVue);
Vue.component('chart-card', ChartsCard);
Vue.component('Media', Media);
Vue.component('watterfall', Waterfall);
Vue.component('watterfallSlot', WaterfallSlot);

Vue.use(VueNativeNotification, {
	// Automatic permission request before
	// showing notification (default: true)
	requestOnNotify: true,
});

try {
	Vue.notification.requestPermission().then(console.log);
} catch (error) {
	console.log(error);
}

//Validate
Vue.use(VeeValidate);
//Buefy
Vue.use(Buefy);

// Cookies
Vue.use(VueCookies);
VueCookies.config('31d');

const moment = require('moment');
require('moment/locale/pt');

Vue.use(require('vue-moment'), {
	moment,
});

Vue.use(VCalendar, {
	componentPrefix: 'vc',
});

//QRCode Read Discount
Vue.use(VueQrcodeReader);

//Translations
import translationsPt from './translations/pt-PT/pt-PT';
import translationsEn from './translations/en-GB/en-GB';

Vue.use(vuexI18n.plugin, store);
Vue.i18n.add('pt', translationsPt);
Vue.i18n.add('en', translationsEn);

const browserLanguage = 'pt';
Vue.i18n.set(browserLanguage);

Vue.component('GmapCluster', GmapCluster);
Vue.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GMAPS_KEY,
		libraries: 'places',
	},
});

Vue.use(
	new VueSocketIO({
		debug: false,
		connection: process.env.VUE_APP_API_URL,
		vuex: {
			store,
			actionPrefix: 'SOCKET_',
			mutationPrefix: 'SOCKET_',
		},
	})
);

//Requires Auth
import requiresAuth from './utils/requiresAuth';

requiresAuth();

import update from '@/mixins/update';
Vue.mixin(update);

import vSelect from "vue-select";

Vue.component("v-select", vSelect);

Vue.config.productionTip = false;

// import { App as CapApp } from '@capacitor/app';

// CapApp.addListener('appStateChange', ({ isActive }) => {
// 	alert('App state changed. Is active? ' + isActive);
// });

// CapApp.addListener('appUrlOpen', data => {
// 	alert('App opened with URL:' + data);
// });

// CapApp.addListener('appRestoredResult', data => {
// 	alert('Restored state:' + data);
// });



new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app');
