import { get, post, put, verifyZipCode, del } from '@/utils/http';

export function login(email, password) {
	return post('/auth', {
		email: email,
		password: password,
	});
}

export function googleLogin(google_token_id, type) {
	return post('/auth/google?type=' + type, {
		google_token_id,
	});
}

export function facebookLogin(facebook_token_id, type) {
	return post('/auth/facebook?type=' + type, {
		facebook_token_id,
	});
}

export function getTwitterToken(count) {
	return get('/auth/twitter/request_token?teste=' + count);
}

export function twitterLogin(twitter_token_id, twitter_secret, type) {
	return post('/auth/twitter?type=' + type, {
		twitter_token_id,
		twitter_secret,
	});
}

export function tokenAuth() {
	return get('/auth');
}

export function verifyHostZip(adress, country) {
	console.log(adress);
	console.log(country);
	return verifyZipCode(`https://maps.googleapis.com/maps/api/geocode/json?address=${adress},+${country}&key=AIzaSyAUxGHfPU3ycw-4cbhOgRNdO7L4m9t7UIY`);
}

export function verifyOriginZip(adress) {
	console.log(adress);
	return verifyZipCode(`https://maps.googleapis.com/maps/api/geocode/json?address=${adress},+PT&key=AIzaSyAUxGHfPU3ycw-4cbhOgRNdO7L4m9t7UIY`);
}

export function deactivateAccount(id) {
	return put('/users/' + id + '/deactivate');
}

export function deleteAccount(id) {
	return del('/users/' + id);
}