<template>
  <section>
    <div class="goods-services-div" v-if="goodInfo !== null && goodInfo.closed === false">
      <div class="goods-header">
        <i @click="$router.go(-1)" class="mdi mdi-chevron-left mdi-48px"></i>
        <p class="title has-text-primary"><b>Bens</b></p>
      </div>

      <div class="goods-services-fields">
        <div class="center-inside good-image">
          <img :src="goodInfo.photo" />
        </div>

        <br />
        <p style="font-size: 20px" class="has-text-primary">
          <b>{{ goodInfo.title }}</b>
        </p>
        <hr />
        <p class="has-text-dark">{{ goodInfo.description }}</p>
        <hr v-if="goodInfo.transaction !='exchange' && goodInfo.transaction !='donate'" />
        <p v-if="goodInfo.transaction !='exchange' && goodInfo.transaction !='donate'" style="font-size: 20px" class="has-text-primary">
          <b>{{ goodInfo.price }}€</b>
        </p>
        <hr />
        <div class="media" @click="$router.push('/emigrant/' + goodInfo.emigrant_id)">
          <div class="media-left">
            <div class="user center-inside">
              <img class="profile-photo" v-if="goodInfo.emigrant.photo" :src="goodInfo.emigrant.photo" />
              <i v-else class="file-icon mdi mdi-camera-outline mdi-36px"></i>
            </div>
          </div>
          <div class="media-content">
            <p class="has-text-primary">
              <b>{{ goodInfo.emigrant.name }}</b>
            </p>
          </div>
          <div class="media-right">
            <div class="media-content">
              <p class="has-text-secondary">
                <b>{{ goodInfo.distance.toFixed(0) }} km</b>
              </p>
            </div>
          </div>
        </div>
        <hr />
        <gmap-map
          v-if="goodInfo.share_location"
          ref="map"
          class="map-size"
          :zoom="12"
          :center="{
            lat: goodInfo.emigrant.destination_latitude,
            lng: goodInfo.emigrant.destination_longitude
          }"
          :options="options"
        >
          <gmap-marker
            :icon="mapMarker"
            :position="{
              lat: goodInfo.emigrant.destination_latitude,
              lng: goodInfo.emigrant.destination_longitude
            }"
          >
          </gmap-marker>
        </gmap-map>
        <div class="next-div has-text-centered">
          <!--          <a class="button is-rounded is-primary is-fullwidth" href="tel:123-456-7890">123-456-7890</a>-->
          <button class="button is-rounded is-primary is-fullwidth" @click="goToChat">
            Contactar
          </button>
        </div>
      </div>
    </div>
    <div style="height:100vh" class="goods-services-div has-text-centered" v-else>
      <div class="goods-header">
        <i @click="$router.go(-1)" class="mdi mdi-chevron-left mdi-48px"></i>
        <p class="title has-text-primary"><b>Bens</b></p>
      </div>
      <p style="line-height: 85vh" class="has-text-danger">Este artigo já não está disponível!</p>
    </div>
  </section>
</template>

<script>
//API
import { sendMessageRequest } from "@/api/apiChat";
import { getGoodById } from "@/api/apiGoodsAndServices";

//Mixins
import { loading } from "@/mixins/shared/loading";
import { userInfo } from "@/mixins/shared/userInfo";
import { goToPage } from "@/mixins/shared/goToPage";

//MAP Style
const mapStyle = require("@/components/Community/Map/mapStyle.json");
export default {
  mixins: [loading, userInfo, goToPage],
  name: "GoodPage",
  data() {
    return {
      goodInfo: null,
      mapMarker: require("@/assets/images/mapMarkers/mapMarker.png"),
      options: {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        styles: mapStyle
      }
    };
  },
  created() {
    this.setPageLoading(true);
    getGoodById(this.$route.params.good_id)
      .then(response => {
        console.log(response);
        this.goodInfo = response.data;
        this.setPageLoading(false);
        console.log(this.goodInfo);
      })
      .catch(error => {
        this.setPageLoading(false);
      });
  },
  methods: {
    goToChat() {
      console.log(this.goodInfo.emigrant.room);
      console.log(this.userData.id);
      console.log(this.user);
      if (this.goodInfo.emigrant.room !== null) {
        this.goToPage("/chat/" + this.goodInfo.emigrant.room._id + "/" + this.userData.id);
      } else {
        sendMessageRequest(this.goodInfo.emigrant_id)
          .then(response => {
            console.log(response);
            this.goToPage("/chat/" + response.data.room._id + "/" + this.userData.id);
          })
          .catch(error => {});
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/auth";
@import "../../assets/scss/goodsAndServices";

.select,
select {
  width: 100% !important;
}

.email-field {
  margin-top: 30px;
  margin-bottom: 20px;
}

.file-icon {
  margin-right: 0;
  color: white;
}

.file-cta {
  width: 400px;
  height: 200px;
  padding: 0;
  background-color: #bac3dc !important;
}

.button {
  margin-top: 30px;
}

hr {
  background-color: whitesmoke !important;
}

.goods-services-fields {
  height: calc(100vh - 90px);
}

.goods-header {
  display: flex;
  align-items: center;
  width: 70vw;
}

.mdi-chevron-left {
  color: #1d4696;
}

.good-image {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .good-image {
    width: 200px;
  }
}
</style>
