<template>
  <section>
    <div class="logo center-inside">
      <img width="140" src="../../assets/logos/logo.png" />
    </div>
    <div class="auth-div">
      <div class="has-text-centered">
        <p class="subtitle has-text-primary"><b>A um clique de casa!</b></p>
        <p class="label-text" style="justify-content: center">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.
        </p>
      </div>
      <div class="auth-buttons">
        <button class="button register is-rounded is-primary is-fullwidth" @click="goToPage('/register')">
          Registar
        </button>
        <button class="button is-rounded white-button is-fullwidth" @click="goToPage('/login')">
          Login
        </button>
      </div>
      <div class="help-container">
        <span class="needHelp" @click="openModal">
          Precisa de ajuda?
        </span>
        <p @click="$router.push('/terms')" class="back">Termos e Condições</p>
        <p @click="$router.push('/privacy')" class="back">Política de Privacidade</p>
      
        <p class="back"><a href="https://www.appemigra.com">Sobre</a></p>
      </div>
    </div>
    <CreateHelpModal v-if="isModalOpen" :isModalOpen="isModalOpen" @clicked="closeModal"></CreateHelpModal>
  </section>
</template>

<script>
//Mixins
import { goToPage } from "@/mixins/shared/goToPage";
import CreateHelpModal from "../Auth/Help/CreateHelpModal";
import PrivacyPolicy from "@/components/Settings/PrivatePolicy.vue";
import Terms from "@/components/Settings/TermsAndConditions.vue";

export default {
  mixins: [goToPage],
  name: "Auth",
  components: {
    CreateHelpModal,
    PrivacyPolicy,
    Terms
  },
  data() {
    return {
      isModalOpen: false,
      termsModal: false,
      privacyModal: false
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal(value) {
      this.isModalOpen = value;
    },
    closePrivacyModal(value) {
      this.privacyModal = value;
    },
    closeTermsModal(value) {
      this.termsModal = value;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/auth";

.auth-div {
  bottom: 10%;
}

.auth-buttons {
  padding-top: 50px;
}

.register {
  margin-bottom: 20px;
}

.help-container {
  padding-top: 10px;
}
.needHelp {
  color: $secondary;
  font-size: 0.9rem;
}

.needHelp:hover {
  cursor: pointer;
}

.back {
  color: $primary;
  font-size: 0.75rem;
}
.back:hover {
  cursor: pointer;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: $primary;
}

a:hover {
  text-decoration: none;
  color: $primary;
}
</style>
