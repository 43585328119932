<template>
  <section class="section-card">
    <div class="card">
      <div class="content">
        <div class="header">
          <div class="diff-file-cta center-inside inline">
            <i class="diff-file-icon mdi mdi-camera-outline mdi-26px"></i>
          </div>
          <div class="inline">
            <span class="new-post-text">Criar nova publicação</span>
          </div>
        </div>
        <img v-if="feedImage && feedImage.type.includes('image')" class="feed-image" :src="getFileURL" />
        <Media v-else-if="feedImage && feedImage.type.includes('video')" class="feed-video" :kind="'video'" :controls="true" :src="getFileURL"> </Media>
        <div v-else class="file-post center-inside ">
          <i class="file-icon-post  mdi mdi-camera-outline mdi-48px"></i>
        </div>
        <p v-show="showImageError" class="help is-danger">
          Introduza uma imagem ou vídeo!
        </p>
        <textarea
          class="input is-success"
          placeholder="Insira uma descrição"
          :class="{ 'error-text': errors.has('requestDescription') }"
          type="text"
          v-model="description"
          v-validate="'required'"
          name="description"
        />
        <p v-show="errors.has('description')" class="help is-danger">
          Introduza uma descrição
        </p>
      </div>

      <div class=" media">
        <div class="file-cta center-inside inline ">
          <b-upload v-model="feedImage" class="file-label" capture="camera">
            <i class="file-icon mdi mdi-camera-outline mdi-26px"></i>
          </b-upload>
        </div>
        <div class="file-cta center-inside inline ">
          <b-upload v-model="feedImage" class="file-label" accept="image/*">
            <i class="file-icon mdi mdi-image-outline mdi-26px"></i>
          </b-upload>
        </div>
        <div class="file-cta center-inside inline ">
          <b-upload v-model="feedImage" class="file-label" accept="video/*">
            <i class="file-icon mdi mdi-video-outline mdi-26px"></i>
          </b-upload>
        </div>
        <div class="media-content"></div>

        <div class="media-right">
         
        </div>
      </div>
    </div>

    <div class="next-div has-text-centered">
      <button class="button is-rounded is-primary publish" @click="create">
        Publicar
      </button>
      <button class="button is-rounded is-danger cancel" @click="checkBack()">
        Cancelar
      </button>
    </div>
    <br />
  </section>
</template>

<script>
import { createPost } from "@/api/apiFeed";
import { feedSteps } from "@/mixins/feed/feedSteps";
export default {
  name: "CreatePublication",
  mixins: [feedSteps],
  data() {
    return {
      feedImage: null,
      description: "",
      showImageError: false
    };
  },

  computed: {
    getFileURL() {
      if (this.feedImage) {
        return window.URL.createObjectURL(this.feedImage);
      }
    }
  },

  methods: {
    create() {
      let post = {
        photo: this.feedImage,
        description: this.description
      };
      if (!this.feedImage) {
        this.showImageError = true;
      } else this.showImageError = false;
      this.$validator.validateAll().then(result => {
        if (result) {
          createPost(post)
            .then(response => {
              console.log(response);

              this.$buefy.toast.open({
                message: "Publicação criada com sucesso!",
                position: "is-top",
                type: "is-primary"
              });
              this.$store.commit("feedSteps/setActiveFeedStep", 0);
            })
            .catch(error => {
              this.$buefy.toast.open({
                message: "Não foi possível criar a publiação!",
                position: "is-top",
                type: "is-danger"
              });
            });
        }
      });
    },

     checkBack() {
      if (this.getFeedStep === 1) {
        this.$store.commit("feedSteps/setActiveFeedStep", 0);
      } else this.$router.go(-1);
    }
  },

  created() {
    console.log(this.$store.getters["feedSteps/getActiveFeedStep"]);
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/createPublication";
</style>
