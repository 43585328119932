<template>
  <!-- Name -->
  <section>


    <table style="width:100%; height:60px; margin-bottom:6px">
      <tr>
        <th style="text-align: right" @click="goToPage('/emigrant/'+selectedUser.id)">
          <div class="user center-inside">
            <img class="profile-photo" v-if="selectedUser.img" :src="selectedUser.img">
            <i v-else class="file-icon mdi mdi-camera-outline mdi-36px"></i>
          </div>
        </th>
        <th style="padding-left: 10px;padding-right: 10px" @click="goToPage('/emigrant/'+selectedUser.id)"><p
            class="subtitle">{{ selectedUser.name }}</p>
          <p class="distance"><b>{{ selectedUser.distance }}km</b></p></th>
        <th style="vertical-align: bottom;text-align: left">
          <button v-if="selectedUser.follows==='none'" class="button is-rounded is-primary"
                  @click="followUser(selectedUser.id)">
            <b>seguir</b>
          </button>
          <button @click="unfollowUser(selectedUser.follow_id)"
                  v-else-if="selectedUser.follows==='pending'"
                  class="button is-rounded pending-button"><b>pendente</b>
          </button>
          <button @click="goToPage('/notifications')" v-else-if="selectedUser.follows==='answering'"
                  class="button is-rounded pending-button"><b>solicitado</b>
          </button>
          <button @click="unfollowUser(selectedUser.follow_id)" v-else-if="selectedUser.follows==='following'"
                  class="button is-rounded following-button"><b>a
            seguir</b>
          </button>
        </th>
      </tr>
    </table>
  </section>

</template>

<script>
//Mixins
import {goToPage} from "@/mixins/shared/goToPage";
import {userInfo} from "@/mixins/shared/userInfo";
import {followUser} from "@/mixins/follow/followUser";

export default {
  name: "MapInfoWindow",
  mixins: [goToPage, userInfo, followUser],
  props: {
    selectedUser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  mounted() {
    console.log(this.selectedUser)
  }
}
</script>

<style scoped>
.file-icon {
  margin-right: 0;
  color: white;
}

.user {
  width: 55px;
  height: 55px;
  padding: 0;
  border-radius: 50%;
  background-color: #bac3dc !important;
}

.profile-photo {
  border-radius: 50%;
  width: 55px;
  height: 55px;
}

.subtitle {
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  color: #1d4696;
  margin-top: 15px;
  margin-bottom: 5px;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.pending-button {
  background-color: #bac3dc;
  color: white;
}

.distance {
  margin-bottom: 10px;
  color: #bac3dc;
}

.button {
  font-size: 12px;
  width: 70px;
  height: 25px;
  padding: 0;
  margin-right: 6px;
}

.following-button {
  background-color: #f6a328;
  color: white;
}

th {
  vertical-align: middle;
}
</style>