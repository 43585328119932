import {
    get,
    post, put
} from '@/utils/http';


export function sendMessageRequest(id) {
    return post('/messagerequests', {
        emigrant_id: id
    })
}

export function answerMessageRequest(id, status) {
    return put('/messagerequests/' + id, {
        'status': status
    })
}

export function listMessagesRequest() {
    return get('/messagerequests')
}
