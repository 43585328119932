import {MapElementFactory} from "vue2-google-maps";


export default MapElementFactory({

    name: "directionsRenderer",

    ctr() {
        return window.google.maps.DirectionsRenderer;
    },

    events: [],

    mappedProps: {},

    props: {},

    afterCreate(directionsRenderer) {
        let data = this.$store.getters["hitchhikeSteps/getHitchhikeData"]
        directionsRenderer.setDirections(data.routes);
    }
});