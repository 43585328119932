<template>
	<section>
		<div class="auth-div">
			<p class="title has-text-primary"><b>Registo</b></p>

			<label class="label">Local de Acolhimento</label>
			<div class="email-field ">
				<v-select
					class="custom"
					label="country_data"
					v-validate="{
						required: true,
					}"
					name="hostName"
					v-model="name"
					:options="postalCodeHostData"
					><span slot="no-options">Não foi encontrado nenhum resultado.</span></v-select
				>
				<p v-show="errors.has('hostName')" class="help is-danger">
					Selecione um país de acolhimento!
				</p>
			</div>

			<div class="email-field ">
				<div class="field has-addons">
					<!-- <div class="control">
						<div class="select">
							<select style="width:78px" v-model="zip_data.host_country">
								<option :value="zip" v-for="zip in postalCodeHostData" v-if="zip.Regex !== '' || zip.Regex"
									>{{ zip.ISO }}
									<pre>&nbsp;&nbsp;</pre>
									-
									{{ zip.Country }}
								</option>
							</select>
						</div>
					</div> -->
					<div style="width: 100%" class="control has-icons-right">
						<input
							ref="host"
							class="input is-success"
							type="text"
							v-validate="{
								required: true,
								regex: zip_data.host_country.Regex,
							}"
							name="host"
							placeholder="Ex: 70004"
							:class="{ 'error-text': errors.has('host') }"
							v-model="zip_data.zip_host"
						/>
						<span class="icon is-small is-right">
							<i v-show="!errors.has('host') && zip_data.zip_host != ''" class="required-icon mdi mdi-check mdi-24px"></i>
						</span>
					</div>
				</div>
				<p v-show="errors.has('host')" class="help is-danger">
					Formato de código postal inválido
				</p>
			</div>

			<label class="label">Local de Origem</label>
			<div class="field has-addons">
				<div class="control">
					<div class="select ">
						<select class="select-origin" disabled>
							<option>{{ zip_data.origin_country.ISO }} </option>
						</select>
					</div>
				</div>
				<div style="width: 100%" class="control has-icons-right">
					<input
						class="input is-success"
						:class="{ 'error-text': errors.has('origin') }"
						type="text"
						placeholder="Ex: 4490-243"
						v-validate="{
							required: true,
							regex: zip_data.origin_country.Regex,
						}"
						name="origin"
						v-model="zip_data.zip_origin"
					/>
					<span class="icon is-small is-right">
						<i v-show="!errors.has('origin') && zip_data.zip_origin != ''" class="required-icon mdi mdi-check mdi-24px"></i>
					</span>
				</div>
			</div>
			<p v-show="errors.has('origin')" class="help is-danger">
				Formato de código postal inválido
			</p>
			<br />
			<div class="next-div has-text-centered">
				<p class="small-text">
					Ao continuar, está a concordar com os
					<a @click="goToPage('/terms')" class="link-text">Termos de Utilzação</a>, e
					<a @click="goToPage('/privacy')" class="link-text">Política de privacidade</a>
				</p>
				<button class="button is-rounded is-primary is-fullwidth" @click="nextStep">
					Seguinte
				</button>
				<div class="register-steps center-inside is-fullwidth">
					<div class="step-1"></div>
					<div @click="nextStep"></div>
					<div></div>
					<div></div>
				</div>
				<p class="small-text">
					Já tem conta?
					<span class="link-text is-click" @click="goToPage('/login')">Efetue Login</span>
				</p>
			</div>
		</div>
	</section>
</template>

<script>
	//Mixins
	import { steps } from '@/mixins/shared/steps';
	import { goToPage } from '@/mixins/shared/goToPage';
	import { verifyHostZip, verifyOriginZip } from '@/api/apiAuth';

	//PostalCode Data JS
	import countryCodes from '@/components/Auth/RegisterSteps/postalCodes/postal-codes.js';
	//PostalCode Data JSON
	const postalCodeData = require('@/components/Auth/RegisterSteps/postalCodes/postal-codes.json');
	const postalCodeHostData = require('@/components/Auth/RegisterSteps/postalCodes/postal-codes-withoutPortugal.json');
	export default {
		mixins: [steps, goToPage],
		name: 'Step1',
		data() {
			return {
				//countryCodes,JS
				postalCodeData,
				postalCodeHostData,
				zip_data: {
					// host_country: countryCodes[169],JS
					host_country: postalCodeHostData[77],
					zip_host: '',
					// origin_country: countryCodes[169],JS
					origin_country: postalCodeData[179],
					zip_origin: '',
				},
				name: postalCodeHostData[77],
			};
		},

		created() {
			console.log(this.getStepData);
			if (this.getStepData) {
				if (this.getStepData.zip_host) {
					this.zip_data.zip_host = this.getStepData.zip_host;
				}
				if (this.getStepData.host_country) {
					this.zip_data.host_country = this.getStepData.host_country;
				}

				if (this.getStepData.zip_origin) {
					this.zip_data.zip_origin = this.getStepData.zip_origin;
				}
			}
		},
		mounted() {
			this.$refs.host.focus();
			this.postalCodeHostData.map(function(x) {
				return (x.country_data = x.ISO + ' - ' + x.Country);
			});
		},
		methods: {
			nextStep() {
				this.$validator.validateAll().then(result => {
					if (result) {
						this.zip_data.host_country = this.name;
						console.log(this.zip_data.host_country);
						let originZip = this.zip_data.zip_origin.replace(/-/g, '');
						let hostZip = this.zip_data.zip_host.replace(/-/g, '');
						verifyHostZip(hostZip, this.zip_data.host_country.ISO)
							.then(response => {
								console.log(response.data.status);
								if (response.data.status === 'ZERO_RESULTS') {
									this.$buefy.toast.open({
										message: 'Código postal inválido. Verifique os códigos postais.',
										position: 'is-top',
										type: 'is-danger',
									});
								} else {
									verifyOriginZip(originZip)
										.then(response => {
											console.log(response);
											if (response.data.status === 'ZERO_RESULTS') {
												this.$buefy.toast.open({
													message: 'Código postal inválido. Verifique os códigos postais.',
													position: 'is-top',
													type: 'is-danger',
												});
											} else {
												this.setActiveStep(2, this.zip_data);
											}
										})
										.catch(error => {
											this.isLoading = false;
											this.$buefy.toast.open({
												message: 'Verifique os códigos postais',
												position: 'is-top',
												type: 'is-danger',
											});
										});
								}
							})
							.catch(error => {
								this.isLoading = false;
								this.$buefy.toast.open({
									message: 'Verifique os códigos postais',
									position: 'is-top',
									type: 'is-danger',
								});
							});
					} else {
						this.isLoading = false;
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../../assets/scss/auth';

	.auth-div {
		top: 24%;
	}

	.field {
		margin-bottom: 0;
	}

	.email-field {
		margin-bottom: 40px;
	}

	.button {
		margin-top: 25px;
		margin-bottom: 5px;
	}

	.next-div {
		margin-top: 25px;
	}

	.register-steps > .step-1 {
		background: #1d4696;
	}

	.select-origin {
		color: #bac3dc !important;
	}

	.title {
		font-size: 1.5rem;
	}

	.label {
		font-size: 0.9rem;
	}

	.small-text,
	.link-text {
		font-size: 0.75rem;
	}

	select {
		width: 78px;
	}

	/deep/ .custom .vs__search::placeholder,
	/deep/ .custom .vs__dropdown-toggle,
	/deep/ .custom .vs__dropdown-menu {
		border: none;
	}

	/deep/ .custom .vs__selected {
		color: #1d4696;
	}

	/deep/ .custom .vs__clear,
	/deep/ .custom .vs__open-indicator {
		fill: $secondary;
	}

	.custom {
		margin-left: -10px;
		margin-bottom: 10px;
	}
</style>
