<template>
  <div>
    <div class="ticket-container">
      <div>
        <span class="open">Em aberto</span>
        <div>
          <ul>
            <draggable
              v-bind="dragOptions"
              v-model="openTickets"
              @start="drag = true"
              @end="drag = false"
              @change="log"
              group="tickets"
              :list="openTickets"
            >
              <li
                v-for="ticket in openTickets"
                :key="ticket.id"
                :name="!drag ? 'flip-list' : null"
                class="card open"
                type="transition"
                @click="
                  openModal(
                    ticket.id,
                    ticket.type,
                    ticket.description,
                    ticket.createdAt,
                    ticket.email,
                    ticket.status
                  )
                "
              >
                <div>
                  <span class="card-label">Tipo de problema: </span>
                  <span>{{ ticket.type }} </span>
                </div>
                <br />
                <span class="card-label">Descrição do problema:</span>
                <span class="card-description"> {{ ticket.description }}</span
                ><br />
                <span class="card-label">Email: </span>
                <span> {{ ticket.email }}</span
                ><br />
              </li>
            </draggable>
          </ul>
        </div>
      </div>
    </div>
    <div class="ticket-container">
      <div>
        <span class="in-progress">Em progresso</span>
      </div>
      <div>
        <ul>
          <draggable
            v-bind="dragOptions"
            v-model="progressTickets"
            @start="drag = true"
            @end="drag = false"
            @change="log"
            group="tickets"
            :list="progressTickets"
          >
            <li
              v-for="ticket in progressTickets"
              :key="ticket.id"
              class="card in-progress"
              @click="
                openModal(
                  ticket.id,
                  ticket.type,
                  ticket.description,
                  ticket.createdAt,
                  ticket.email,
                  ticket.status
                )
              "
            >
              <div>
                <span class="card-label">Tipo de problema: </span>
                <span>{{ ticket.type }} </span>
              </div>
              <br />
              <span class="card-label">Descrição do problema:</span>
              <span class="card-description"> {{ ticket.description }}</span
              ><br />
              <span class="card-label">Email: </span>
              <span> {{ ticket.email }}</span
              ><br />
            </li>
          </draggable>
        </ul>
      </div>
    </div>
    <div class="ticket-container">
      <div>
        <span class="solved">Resolvidos</span>
      </div>
      <div>
        <ul>
          <draggable
            v-bind="dragOptions"
            v-model="solvedTickets"
            @start="drag = true"
            @end="drag = false"
            @change="log"
            group="tickets"
            :list="solvedTickets"
          >
            <li
              v-for="ticket in solvedTickets"
              :key="ticket.id"
              class="card solved"
              @click="
                openModal(
                  ticket.id,
                  ticket.type,
                  ticket.description,
                  ticket.createdAt,
                  ticket.email,
                  ticket.status
                )
              "
            >
              <div>
                <span class="card-label">Tipo de problema: </span>
                <span>{{ ticket.type }} </span>
              </div>
              <br />
              <span class="card-label">Descrição do problema:</span>
              <span class="card-description"> {{ ticket.description }}</span
              ><br />
              <span class="card-label">Email: </span>
              <span> {{ ticket.email }}</span
              ><br />
            </li>
          </draggable>
        </ul>
      </div>
    </div>
    <div class="ticket-container">
      <div>
        <span class="closed">Fechados</span>
      </div>
      <div>
        <ul>
          <draggable
            v-bind="dragOptions"
            v-model="closedTickets"
            @start="drag = true"
            @end="drag = false"
            @change="log"
            group="tickets"
            :list="closedTickets"
          >
            <li
              v-for="ticket in closedTickets"
              :key="ticket.id"
              class="card closed"
              @click="
                openModal(
                  ticket.id,
                  ticket.type,
                  ticket.description,
                  ticket.createdAt,
                  ticket.email,
                  ticket.status
                )
              "
            >
              <div>
                <span class="card-label">Tipo de problema: </span>
                <span>{{ ticket.type }} </span>
              </div>
              <br />
              <span class="card-label">Descrição do problema:</span>
              <span class="card-description"> {{ ticket.description }}</span
              ><br />
              <span class="card-label">Email: </span>
              <span> {{ ticket.email }}</span
              ><br />
            </li>
          </draggable>
        </ul>
      </div>
    </div>
    <TicketModal
      v-if="isModalOpen"
      :isModalOpen="isModalOpen"
      @clicked="closeModal"
      :id="modalId"
      :problem="modalProblem"
      :description="modalDescription"
      :created="modalCreatedAt"
      :email="modalEmail"
      :status="modalStatus"
    ></TicketModal>
  </div>
</template>

<script>
import { getTickets, updateTicketStatus } from "@/api/apiBackOffice";
import TicketModal from "../Backoffice/TicketModal";
import draggable from "vuedraggable";
export default {
  components: {
    TicketModal,
    draggable,
  },
  data() {
    return {
      openTickets: [],
      progressTickets: [],
      solvedTickets: [],
      closedTickets: [],
      isModalOpen: false,
      modalId: "",
      modalProblem: "",
      modalDescription: "",
      modalCreatedAt: "",
      modalEmail: "",
      modalStatus: "",
      drag: false,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    openModal(id, type, description, created, email, status) {
      this.modalId = id;
      this.modalProblem = type;
      this.modalDescription = description;
      this.modalCreatedAt = created;
      this.modalEmail = email;
      this.modalStatus = status;
      this.isModalOpen = true;
    },
    closeModal(value) {
      this.isModalOpen = value;
    },
    log: function(evt) {
      let id = evt.added.element.id;

      if (this.openTickets.find((ticket) => ticket.id === id)) {
        updateTicketStatus(id, "open")
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.progressTickets.find((ticket) => ticket.id === id)) {
        updateTicketStatus(id, "in-progress")
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.solvedTickets.find((ticket) => ticket.id === id)) {
        updateTicketStatus(id, "solved")
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.closedTickets.find((ticket) => ticket.id === id)) {
        updateTicketStatus(id, "closed")
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getTicketsUpdated() {
      getTickets()
        .then((response) => {
          this.openTickets = response.data.open;
          this.progressTickets = response.data["in-progress"];
          this.solvedTickets = response.data.solved;
          this.closedTickets = response.data.closed;
          console.log(this.openTickets);
        })
        .catch((error) => {});
    },
  },
  created() {
    getTickets()
      .then((response) => {
        this.openTickets = response.data.open;
        this.progressTickets = response.data["in-progress"];
        this.solvedTickets = response.data.solved;
        this.closedTickets = response.data.closed;
        console.log(this.openTickets);
      })
      .catch((error) => {});
  },
  updated() {
    getTickets()
      .then((response) => {
        this.openTickets = response.data.open;
        this.progressTickets = response.data["in-progress"];
        this.solvedTickets = response.data.solved;
        this.closedTickets = response.data.closed;
      })
      .catch((error) => {});
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/backofficeTickets";



</style>
