<template>
  <section>
    <div class="hitchhike-fields">
      <div class="columns is-gapless is-multiline">
        <div class="column is-4-desktop is-6-tablet" v-for="(hitchhike,index) in favouriteCarTrips" v-if="!hitchhike.car_trip.trash">
          <div class="card">
            <div class="card-content">
              <div class="media">
                <div @click="goToPage('/emigrant/'+hitchhike.car_trip.vehicle.emigrant_id)" class="media-left">
                  <div class="user center-inside">
                    <img class="profile-photo" v-if="hitchhike.car_trip.vehicle.emigrant.photo"
                         :src="hitchhike.car_trip.vehicle.emigrant.photo">
                    <i v-else class="file-icon mdi mdi-camera-outline mdi-36px"></i>
                  </div>
                </div>
                <div class="media-content">
                  <p class="has-text-primary"><b>{{ hitchhike.car_trip.vehicle.emigrant.name }}</b></p>
                  <p class="has-text-dark">{{ hitchhike.car_trip.vehicle.car_model.car_brand.name }} | {{
                      hitchhike.car_trip.vehicle.year
                    }}</p>
                </div>
                <div class="media-right">
                  <div class="media-content">
                    <p class="has-text-secondary"><b>{{ hitchhike.car_trip.distance_text }}</b></p>
                    <p class="has-text-primary"><b>{{ hitchhike.hitchhike_places }} Lug.</b></p>
                  </div>
                </div>

                <div class="media-right">
                  <div class="media-content is-click" @click="removeFavourite(hitchhike.id, index)">
                    <i class="mdi mdi-heart mdi-24px not-favourite"></i>
                  </div>
                </div>
              </div>

              <!--          <div class="media">
                          <div class="media-left">
                            <div class="media-content">
                              <p class="has-text-primary"><b>{{ hitchhike.arrival_country }}-{{ hitchhike.departure_country }}</b></p>
                            </div>
                          </div>
                        </div>-->

              <div class="media">
                <div class="media-left">
                  <div class="media-content">
                    <p class="has-text-primary">
                      <b>{{ hitchhike.car_trip.departure_date }}</b></p>
                  </div>
                </div>
                <div class="media-content has-text-centered">
                  <p class="has-text-primary"><b>{{ hitchhike.car_trip.departure_hour }}</b></p>
                </div>
                <div class="media-right">
                  <div class="media-content">

                    <button class="button is-rounded is-primary is-fullwidth"
                            :class="{'is-loading':isButtonLoading&&hitchhike.id===selectedCartripId}"
                            @click="createHitchhike(hitchhike, index)"><b>Requisitar</b>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
//API
import {requestHitchhike, deleteWishCartrip} from "@/api/apiRides";
//Mixins
import {userInfo} from "@/mixins/shared/userInfo";
import {goToPage} from "@/mixins/shared/goToPage";
import {loading} from "@/mixins/shared/loading";

export default {
  mixins: [loading, userInfo, goToPage],
  props: {
    favouriteCarTrips: {
      type: Array,
      required: true
    }
  },
  name: "Favourites",
  data() {
    return {
      isButtonLoading: false,
      selectedCartripId: null

    }
  },
  created() {

  },
  methods: {
    removeFavourite(id, index) {
      deleteWishCartrip(id)
          .then(response => {
            this.favouriteCarTrips.splice(index,1)
          })
          .catch(error => {

          });
    },
    createHitchhike(hitchhike, index) {
      this.selectedCartripId = hitchhike.id
      this.isButtonLoading = true;

      let data = {
        hitchhike_places: hitchhike.hitchhike_places,
        car_trip_id: hitchhike.car_trip.id,
        departure_country: hitchhike.departure_country,
        departure_zip_code: hitchhike.departure_zip_code,
        arrival_zip_code: hitchhike.arrival_zip_code,
        arrival_country: hitchhike.arrival_country
      }
      console.log(data)
      requestHitchhike(data)
          .then(response => {
            this.isButtonLoading = false;
            this.$buefy.toast.open({
              message: 'Boleia criada com sucesso',
              position: "is-top",
              type: "is-primary"
            });
            this.removeFavourite(hitchhike.id, index)
          })
          .catch(error => {
            this.isButtonLoading = false;
            this.$buefy.toast.open({
              message: error.response.data.message.pt,
              position: "is-top",
              type: "is-danger"
            });
          });
    }

  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/hitchhike";

.hitchhike-fields {
  width: 100%;
  height: calc(100vh - 165px);
  position: absolute;
  padding-left: 0;
  padding-right: 0;
  overflow-y: scroll;
}

.card {
  width: 93%;
  border-radius: 25px;
  margin: 10px auto;
  -webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  -moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;

}

.media {
  border: none;
  margin-top: 0;
}

p {
  font-size: 0.9rem;
}
</style>