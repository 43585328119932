<template>
  <div class="auth-div">
    <div class="header">
      <i class="mdi mdi-chevron-left mdi-36px" @click="$router.back()"></i>
      <span class="title">Negócio Local</span>
    </div>
    <br />
    <div class="tabs is-toggle center-inside is-toggle-rounded">
      <div class="tabs-shadow">
        <ul>
          <li @click="activeTab = 0" :class="{ 'is-active': activeTab === 0 }">
            <a>
              <span><b>Negócio</b></span>
            </a>
          </li>
          <li @click="activeTab = 1" :class="{ 'is-active': activeTab === 1 }">
            <a>
              <span><b>Benefícios</b></span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <edit-local-market
      v-if="activeTab === 0"
      @emitLocalMarket="emitLocalMarket"
      :name="marketName"
      :photo="marketPhoto"
      :area="marketArea"
      :email="marketEmail"
      :phone="marketPhone"
      :description="marketDescription"
      :country="marketCountry"
      :zipCode="marketZip"
      :site="marketSite"
      :schedules="marketSchedules"
    ></edit-local-market>

    <edit-benefits
      @emitBenefits="emitBenefits"
      :benefits="marketBenefits"
      v-if="activeTab === 1"
    ></edit-benefits>

    <button
      class="button is-rounded is-primary is-fullwidth"
      style="color:white; height:55px"
      :class="{ 'is-loading': isButtonLoading }"
      @click="update"
    >
      Guardar
    </button>
    <br />
  </div>
</template>

<script>
import { updateLocalMarket } from "@/api/apiBackOffice";
import EditLocalMarket from "@/components/Backoffice/EditMarket.vue";
import EditBenefits from "@/components/Backoffice/EditBenefits.vue";
export default {
  components: {
    EditLocalMarket,
    EditBenefits,
  },
  data() {
    return {
      activeTab: 0,
      isButtonLoading: false,
      marketId: null,
      marketName: null,
      marketEmail: null,
      marketPhone: null,
      marketDescription: null,
      marketCountry: null,
      marketZip: null,
      marketSite: null,
      marketArea: null,
      marketSchedules: {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
      },
      marketBenefits: null,
      marketPhoto: null,
      days: null,
    };
  },
  created() {
    this.marketId = this.$route.params.id;
    this.marketName = this.$route.params.name;
    this.marketEmail = this.$route.params.email;
    this.marketPhone = this.$route.params.phone;
    this.marketDescription = this.$route.params.description;
    this.marketCountry = this.$route.params.country;
    this.marketZip = this.$route.params.zip;
    this.marketSite = this.$route.params.url;
    this.marketArea = this.$route.params.area;
    this.marketBenefits = this.$route.params.benefits;
    this.marketPhoto = this.$route.params.photo;
    this.days = this.$route.params.schedules;

    this.days.map((day) => {
      this.marketSchedules[day.week_day].push(day);
      this.marketSchedules[day.week_day].sort((a, b) =>
        a.open > b.open ? 1 : -1
      );
    });

    window.scrollTo(0, 0);
  },

  methods: {
    emitLocalMarket(value) {
      this.marketName = value.name;
      this.marketPhoto = value.photo;
      this.marketArea = value.area;
      this.marketEmail = value.email;
      this.marketPhone = value.phone;
      this.marketDescription = value.description;
      this.marketCountry = value.country;
      this.marketZip = value.zip;
      this.marketSite = value.site;
      this.marketSchedules = value.days;
    },

    emitBenefits(value) {
      this.marketBenefits = value.benefits;
      console.log(this.marketBenefits);
    },

    update() {
      let allBenefits = [];
      for (let i = 0; i < this.marketBenefits.length; i++) {
        if (
          this.marketBenefits[i].featured === true &&
          this.marketBenefits[i].action !== "remove"
        ) {
          allBenefits.push({
            id: this.marketBenefits[i].id,
            type: this.marketBenefits[i].type,
            value: this.marketBenefits[i].value,
            description: this.marketBenefits[i].description,
            color: this.marketBenefits[i].color,
            featured: true,
            action: this.marketBenefits[i].action,
          });

          if (this.marketBenefits[i].multiple === "true") {
            allBenefits[i].multiple = true;
          } else {
            allBenefits[i].multiple = false;
          }

          if (this.marketBenefits[i].validity) {
            allBenefits[i].validity = this.marketBenefits[i].validity;
          } 
        } else {
          allBenefits.push({
            id: this.marketBenefits[i].id,
            type: this.marketBenefits[i].type,
            value: this.marketBenefits[i].value,
            description: this.marketBenefits[i].description,
            color: this.marketBenefits[i].color,
            featured: false,
            action: this.marketBenefits[i].action,
          });
          if (this.marketBenefits[i].multiple === "true") {
            allBenefits[i].multiple = true;
          } else {
            allBenefits[i].multiple = false;
          }

          if (this.marketBenefits[i].validity) {
            allBenefits[i].validity = this.marketBenefits[i].validity;
          } 
        }
      }
      for (let index = 0; index < allBenefits.length; index++) {
        console.log(allBenefits[index].action);
        if (!allBenefits[index].action) {
          allBenefits[index].action = "none";
        }
      }

      console.log(allBenefits);

      this.marketBenefits = allBenefits;
      console.log(this.marketBenefits);

      let newPhoto;
      if (this.marketPhoto !== null) {
        newPhoto = this.marketPhoto;
      } else newPhoto = this.photo;

      let data = {
        name: this.marketName,
        email: this.marketEmail,
        zip_code: this.marketZip,
        country: this.marketCountry,
        local_market_area_id: this.marketArea,
        days: this.marketSchedules,
        benefits: this.marketBenefits,
        photo: newPhoto,
        ...(this.description != null && {
          description: this.marketDescription,
        }),
        ...(this.phone != null && { phone: this.marketPhone }),
        ...(this.site != null && { url: this.marketSite }),
      };

      console.log(data);

      updateLocalMarket(data, this.marketId)
        .then((response) => {
          console.log(response);
          console.log(data);
          this.$buefy.toast.open({
            message: "Negócio editado com sucesso!",
            position: "is-top",
            type: "is-primary",
          });
          this.$router.push("/");
        })
        .catch((error) => {
          this.isLoading = false;
          this.$buefy.toast.open({
            message: error.response.data.message.pt,
            position: "is-top",
            type: "is-danger",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-div {
  padding-left: 10%;
  padding-right: 10%;
}
.header {
  margin-top: 20px;
}

.tabs.is-toggle.is-toggle-rounded li:first-child a {
  border-bottom-right-radius: 290486px;
  border-top-right-radius: 290486px;
}

.tabs.is-toggle.is-toggle-rounded li:last-child a {
  border-bottom-left-radius: 290486px;
  border-top-left-radius: 290486px;
}

.tabs-shadow {
  /* border-radius */
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  /* box-shadow */
  -webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  -moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
}

.tabs.is-toggle a {
  border: none;
  color: #1d4696;
}

.tabs {
  padding: 10px;
}

.tabs.is-toggle li.is-active a {
  color: #1d4696;
}

i::before {
  color: #1d4696;
  transform: translate(-16px, 2px);
}
</style>
