<template>
	<section>
		<div class="auth-div">
			<!--  <p class="title has-text-primary"><b>Perfil</b></p> -->
			<div class="field email-field">
				<b-field class="file is-primary center-inside">
					<b-upload v-model="editFile" class="file-label" accept="image/*">
						<div class="file-cta center-inside">
							<img class="profile-photo" v-if="userImg && !editFile" :src="userImg" />
							<img class="profile-photo" v-else-if="editFile" :src="getFileURL" />
							<i v-else class="file-icon mdi mdi-camera-outline mdi-48px"></i>
						</div>
					</b-upload>
				</b-field>
			</div>
			<div class="field email-field">
				<label class="label">Nome</label>
				<div class="control has-icons-right">
					<input class="input is-success" :class="{ 'error-text': errors.has('name') }" type="text" v-validate="'required'" name="name" v-model="edit_name" />
					<span class="icon is-small is-right">
						<i v-show="!errors.has('name') && edit_name != null" class="required-icon mdi mdi-check mdi-24px"></i>
					</span>
				</div>
				<p v-show="errors.has('name')" class="help is-danger">
					Insira o seu nome
				</p>
			</div>

			<div class="field email-field">
				<label class="label">Email</label>
				<div class="control has-icons-right">
					<input class="input is-success" disabled type="text" v-model="user_email" />
				</div>
			</div>

			<div class="field email-field">
				<label class="label">Género</label>
				<div class="control">
					<label class="radio">
						<input v-validate="'required'" type="radio" value="male" v-model="edit_gender" name="answer" />
						Masculino
					</label>
					<label class="radio">
						<input type="radio" value="female" v-model="edit_gender" name="answer" />
						Feminino
					</label>
					<br />
					<br />
					<label class="radio">
						<input type="radio" value="other" v-model="edit_gender" name="answer" />
						Outro
					</label>
				</div>
				<hr />
				<p v-show="errors.has('answer')" class="help is-danger">
					Selecione um género
				</p>
			</div>
			<div class="field email-field">
				<label class="label">Telemóvel</label>
				<div class="control has-icons-right">
					<input class="input is-success" :class="{ 'error-text': errors.has('phone') }" type="text" name="phone" v-model="edit_phone" v-validate="'numeric'" />
				</div>
				<p v-show="errors.has('phone')" class="help is-danger">
					Número de telefone inválido
				</p>
			</div>
			<div class="field email-field">
				<div @click="showDate = !showDate">
					<label class="label">Data de Nascimento</label>
					<input class="input is-success" name="date" disabled type="text" v-model="formatDate" />
				</div>
				<br />
				<b-datepicker v-show="showDate" v-model="edit_birth_date" name="birthday" expanded :max-date="new Date()" :mobile-native="false" inline placeholder="Select a date">
				</b-datepicker>
			</div>
			<p v-if="!hasAge" class="help is-danger">
				Para utilizar o emigra tem que ter mais de 16 anos
			</p>

			<div class="email-field" v-if="userData.info.addresses_updates < 1">
				<label class="label">Local de Acolhimento</label>
				<div class="field has-addons">
					<div class="control">
						<div class="select">
							<select v-model="host_country">
								<option :value="zip" v-for="zip in postalCodeData" v-if="zip.Regex !== '' || zip.Regex"
									>{{ zip.ISO }}
									<pre>&nbsp;&nbsp;</pre>
									-
									{{ zip.Country }}
								</option>
							</select>
						</div>
					</div>
					<div style="width: 100%" class="control has-icons-right">
						<input
							ref="host"
							class="input is-success"
							type="text"
							v-validate="{ required: true, regex: host_country.Regex }"
							name="host"
							:class="{ 'error-text': errors.has('host') }"
							v-model="zip_host"
						/>
						<span class="icon is-small is-right">
							<i v-show="!errors.has('host') && zip_host != null" class="required-icon mdi mdi-check mdi-24px"></i>
						</span>
					</div>
				</div>
				<p v-show="errors.has('host')" class="help is-danger">
					Formato de código postal inválido
				</p>
			</div>

			<p class="error-change" v-else>
				Apenas pode efetuar uma alteração de código postal!
			</p>
			<br v-if="userData.info.addresses_updates >= 1 && userCar.length <= 0" />
			<div v-if="userCar.length <= 0" class="add-car" @click="openModal">
				<i class="mdi mdi-plus-circle-outline mdi-24px"></i>
				<p><b>Adicionar Automóvel</b></p>
			</div>

			<div v-else class="field email-field" @click="openModal">
				<label class="label">Automóvel</label>
				<div class="control has-icons-right">
					<input class="input is-success" type="text" v-model="userCar[0].plate" />
					<span class="icon is-small is-right">
						<i class="required-icon mdi mdi-pencil-outline mdi-24px"></i>
					</span>
				</div>
			</div>
			<br />

			<div class="next-div has-text-centered">
				<button class="button is-rounded is-primary is-fullwidth" :class="{ 'is-loading': isLoading }" @click="update">
					Guardar
				</button>
			</div>
			<div v-if="userType === 'emigrant'" style="margin-top:2rem">
				<button class="button delete-button is-rounded is-primary button-size " @click="logout">
					Terminar Sessão
				</button>
			</div>

			<div v-if="userType === 'emigrant'">
				<button class="button delete-button is-rounded is-danger button-size " @click="isDeactivateModal = true">
					Desativar Conta
				</button>
			</div>

			<div v-if="userType === 'emigrant'">
				<button class="button  delete-button is-rounded is-danger button-size " @click="isDeleteModal = true">
					Eliminar Conta
				</button>
			</div>
		</div>
		<CreateCarModal v-if="isModalOpen" :isModalOpen="isModalOpen" :userCar="userCar" @clicked="closeModal"></CreateCarModal>
		<b-modal :can-cancel="false" v-model="isDeleteModal" scroll="keep">
			<div class="modal is-active">
				<div class="modal-card">
					<section class="modal-card-body">
						<p class="has-text-primary"><b>Tem a certeza que pretende eliminar a sua conta?</b></p>
						<p class="has-text-danger mb-3">Esta opção elimina permanentemente a conta do utilizador.</p>
						<button @click="confirmDelete" class="button card-button is-rounded is-primary is-fullwidth">
							Confirmar
						</button>
						<br />
						<button @click="isDeleteModal = false" class="button is-rounded is-danger is-fullwidth">
							Agora não
						</button>
					</section>
				</div>
			</div>
		</b-modal>

		<b-modal :can-cancel="false" v-model="isDeactivateModal" scroll="keep">
			<div class="modal is-active">
				<div class="modal-card">
					<section class="modal-card-body">
						<p class="has-text-primary"><b>Tem a certeza que pretende desativar a sua conta?</b></p>
						<p class="has-text-danger">Esta opção desativa a conta do utilizador, podendo ser ativada novamente enviando um pedido ao suporte.</p>
						<button @click="deactivate" class="button card-button is-rounded is-primary is-fullwidth">
							Confirmar
						</button>
						<br />
						<button @click="isDeactivateModal = false" class="button is-rounded is-danger is-fullwidth">
							Agora não
						</button>
					</section>
				</div>
			</div>
		</b-modal>
	</section>
</template>
<script>
	//API
	import { updateEmigrantDestination, updateProfile } from '@/api/apiProfile';
	import { listUserVehicles } from '@/api/apiCar';
	import { deactivateAccount, deleteAccount } from '@/api/apiAuth';
	//PostalCode Data JSON
	const postalCodeData = require('@/components/Auth/RegisterSteps/postalCodes/postal-codes.json');
	//Components
	import CreateCarModal from '@/components/Profile/CreateCarModal';

	import { logout } from '@/mixins/auth/logout';

	export default {
		mixins: [logout],
		name: 'EditProfile',
		components: {
			CreateCarModal,
		},
		props: {
			userData: {
				type: Object,
				required: true,
			},
		},
		data() {
			return {
				userImg: null,
				editFile: null,
				edit_name: null,
				user_email: null,
				edit_gender: null,
				postalCodeData,
				edit_birth_date: new Date(),
				edit_phone: null,
				host_country: null,
				zip_host: null,
				hasAge: true,
				isLoading: false,
				showDate: false,
				isModalOpen: false,
				userCar: [],
				isDeleteModal: false,
				isDeactivateModal: false,
				userType: null,
			};
		},
		created() {
			this.userImg = this.userData.info.photo;
			this.edit_name = this.userData.info.name;
			this.user_email = this.userData.email;
			this.edit_gender = this.userData.info.gender;
			for (let i = 0; i < this.postalCodeData.length; i++) {
				if (this.userData.info.destination_country === this.postalCodeData[i].ISO) {
					this.host_country = this.postalCodeData[i];
				}
			}
			this.zip_host = this.userData.info.destination_zip_code;
			if (this.userData.info.birth_date) {
				let from = this.userData.info.birth_date.split('-');
				this.edit_birth_date = new Date(from[0], from[1] - 1, from[2]);
			}
			this.edit_phone = this.userData.info.phone;
			this.getUserVehicles();
		},
		mounted() {
			this.userId = this.$store.getters['auth/getUserData'].id;
			this.userType = this.$store.getters['auth/getUserData'].user_type.type;
		},

		computed: {
			formatDate() {
				let d = new Date(this.edit_birth_date),
					month = '' + (d.getMonth() + 1),
					day = '' + d.getDate(),
					year = d.getFullYear();

				if (month.length < 2) month = '0' + month;
				if (day.length < 2) day = '0' + day;
				return [year, month, day].join('-');
			},
			getFileURL() {
				if (this.editFile) {
					return window.URL.createObjectURL(this.editFile);
				}
			},
		},

		methods: {
			openModal() {
				this.isModalOpen = true;
			},
			closeModal(value) {
				this.isModalOpen = value;
				this.getUserVehicles();
			},
			calculate_age(dob) {
				let diff_ms = Date.now() - dob.getTime();
				let age_dt = new Date(diff_ms);
				return Math.abs(age_dt.getUTCFullYear() - 1970);
			},
			getUserVehicles() {
				listUserVehicles()
					.then(response => {
						console.log(response);
						this.userCar = response.data;
					})
					.catch(error => {});
			},

			update() {
				this.isLoading = true;
				this.$validator.validateAll().then(result => {
					if (result) {
						if (this.calculate_age(this.edit_birth_date) >= 16) {
							console.log(this.calculate_age(this.edit_birth_date));
							let data = {
								origin_zip_code: this.userData.info.origin_zip_code,
								origin_county: this.userData.info.origin_county,
								origin_parish: this.userData.info.origin_parish,
								origin_street: this.userData.info.origin_street,
								name: this.edit_name,
								gender: this.edit_gender,
								birth_date: this.formatDate,
								...(this.edit_phone != null && { phone: this.edit_phone }),
								...(this.editFile != null && { photo: this.editFile }),
							};

							let data1 = {
								destination_country: this.host_country.ISO,
								destination_zip_code: this.zip_host,
							};
							if (this.zip_host !== this.userData.info.destination_zip_code) {
								updateEmigrantDestination(data1)
									.then(response => {})
									.catch(error => {
										this.isLoading = false;

										this.$buefy.toast.open({
											message: error.response.data.message.pt,
											position: 'is-top',
											type: 'is-danger',
										});
									});
							}

							updateProfile(data)
								.then(response => {
									console.log(response);
									this.isLoading = false;
									location.reload();
								})
								.catch(error => {
									this.isLoading = false;

									this.$buefy.toast.open({
										message: error.response.data.message.pt,
										position: 'is-top',
										type: 'is-danger',
									});
								});
						} else {
							this.isLoading = false;
							this.hasAge = false;
						}
					} else {
						this.isLoading = false;
					}
				});
			},
			deactivate() {
				deactivateAccount(this.userId)
					.then(async response => {
						console.log(response.data);
						await this.logout();
					})
					.catch(error => {});
			},

			confirmDelete() {
				deleteAccount(this.userId)
					.then(async response => {
						console.log(response.data);
						await this.logout();
					})
					.catch(error => {});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/auth';

	.auth-div {
		top: 115px;
	}

	.email-field {
		margin-top: 30px;
		margin-bottom: 20px;
	}

	.button {
		margin-bottom: 50px;
	}

	.register-steps > .step-2 {
		background: #1d4696;
	}

	.file-icon {
		margin-right: 0;
		color: white;
	}

	.file-cta {
		width: 120px;
		height: 120px;
		padding: 0;
		border-radius: 50%;
		background-color: #bac3dc !important;
	}

	.profile-photo {
		border-radius: 50%;
		width: 120px;
		height: 120px;
		object-fit: cover;
	}

	hr {
		margin-bottom: 0;
	}

	.error-change {
		background-color: rgba(230, 67, 16, 0.3);
		font-size: 13px;
		color: #e64310;
		border-radius: 4px;
		padding: 10px;
	}

	.mdi-plus-circle-outline {
		color: #f6a328;
		margin-right: 5px;
	}

	.add-car {
		display: flex;
		align-items: center;
		color: #f6a328;
	}

	.title {
		font-size: 1.5rem;
		margin-top: 4px;
	}

	.label,
	input,
	select,
	p {
		font-size: 0.9rem;
	}

	.small-text,
	.link-text,
	.radio {
		font-size: 0.75rem;
	}

	.modal-card {
		border-radius: 20px;
	}

	.card-button {
		margin-bottom: 0 !important;
		margin-top: 10px;
	}

	.delete-button {
		font-size: 0.75rem;
		margin-bottom: 10px;
		height: auto !important;
	}
</style>
