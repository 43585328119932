<template>
	<section>
		<div class="hitchhike-fields">
			<div class="columns is-gapless is-multiline">
				<div class="column is-4-desktop is-6-tablet" v-for="(hitchhike, index) in ownHitchhikes" :key="index" v-if="!hitchhike.trash && hitchhike.finished === false">
					<div class="card">
						<div class="card-content">
							<div class="media">
								<div class="media-left">
									<div class="user center-inside">
										<img class="profile-photo" v-if="userData.info.photo" :src="userData.info.photo" />
										<i v-else class="file-icon mdi mdi-camera-outline mdi-36px"></i>
									</div>
								</div>
								<div class="media-content">
									<p class="has-text-primary">
										<b>{{ userData.info.name }}</b>
									</p>
									<p class="has-text-dark">{{ hitchhike.vehicle.plate }}</p>
								</div>
								<div class="media-right">
									<div class="media-content">
										<p class="has-text-secondary">
											<b>{{ hitchhike.distance_text }}</b>
										</p>
										<p class="has-text-primary">
											<b>{{ hitchhike.available_places }} Lug.</b>
										</p>
									</div>
								</div>
							</div>

							<!--              <p v-if="hitchhike.hitchhikes.length>0" @click="openDetails(index)" class="has-text-primary is-click"><b>Tem
                              {{ hitchhike.hitchhikes.length }} pedidos de boleia</b></p>-->

							<span v-if="hitchhike.hitchhikes.length === 1" @click="openDetails(index)" class="tag is-secondary  is-rounded is-normal is-click"
								><b>{{ hitchhike.hitchhikes.length }} pedido de boleia</b></span
							>
							<span v-else-if="hitchhike.hitchhikes.length > 1" @click="openDetails(index)" class="tag is-secondary  is-rounded is-normal is-click"
								><b>{{ hitchhike.hitchhikes.length }} pedidos de boleia</b></span
							>
							<br />
							<br />
							<div class="media">
								<div class="media-left">
									<div class="media-content">
										<p class="has-text-primary">
											<b>{{ hitchhike.departure_date }}</b>
										</p>
										<p class="has-text-primary">
											<b>{{ $moment(hitchhike.complete_hour).format('HH:mm') }}</b>
										</p>
									</div>
								</div>
								<div class="media-content has-text-centered">
									<div class="media-content">
										<button
											class="button is-rounded is-primary"
											:class="{ 'is-loading': isButtonLoading }"
											@click="editHitchhike(hitchhike.id, hitchhike.available_places, hitchhike.vehicle.places, index)"
										>
											<b>Editar</b>
										</button>
									</div>
								</div>
								<div class="media-right">
									<div class="media-content">
										<button class="button is-rounded is-danger " @click="deleteHitchhike(hitchhike.id, index)"><b>Apagar</b></button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="hitchhikeDetails === index" style="padding-left: 15px;padding-right: 15px">
						<div v-if="!request.trash" class="card" v-for="(request, requestIndex) in hitchhike.hitchhikes">
							<div class="card-content">
								<div class="media" style="margin-bottom: 0">
									<div class="media-left" @click="goToPage('/emigrant/' + request.emigrant.id)">
										<div class="user center-inside">
											<img class="profile-photo" v-if="request.emigrant.photo" :src="request.emigrant.photo" />
											<i v-else class="file-icon mdi mdi-camera-outline mdi-36px"></i>
										</div>
									</div>
									<div class="media-content" @click="goToPage('/emigrant/' + request.emigrant.id)">
										<p class="has-text-primary">
											<b>{{ request.emigrant.name }}</b>
										</p>
									</div>
									<div class="media-right">
										<div class="media-content">
											<p class="has-text-primary">
												<b>{{ request.hitchhike_places }} Lug.</b>
											</p>
										</div>
									</div>
								</div>

								<div class="media">
									<div class="media-left">
										<div class="media-content">
											<!--                      <p class="has-text-primary"><b>{{ hitchhike.departure_hour }}</b></p>-->
										</div>
									</div>
									<div class="media-content has-text-centered">
										<div class="media-content">
											<button
												v-if="!request.is_accepted"
												class="button is-dark decline-button"
												@click="answerHitchhike(request.id, 'decline', index, requestIndex)"
											>
												<b><i class="mdi mdi-close mdi-24px "></i></b>
											</button>
										</div>
									</div>
									<div class="media-right">
										<div class="media-content">
											<button
												v-if="!request.is_accepted && requestIndex === 0"
												class="button is-rounded is-primary "
												@click="answerHitchhike(request.id, 'accept', index, requestIndex)"
											>
												<b>Aceitar</b>
											</button>
											<button v-else-if="!request.is_accepted && requestIndex !== 0" class="button is-rounded is-primary" disabled><b>Aceitar</b></button>
											<button v-else class="button is-rounded is-primary" disabled><b>Aceite</b></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	//Mixins
	import { userInfo } from '@/mixins/shared/userInfo';
	import { goToPage } from '@/mixins/shared/goToPage';
	import { loading } from '@/mixins/shared/loading';
	//API
	import { answerHitchhikeRequest, deleteCarTrip, updateCarTrip } from '@/api/apiRides';

	export default {
		mixins: [loading, userInfo, goToPage],
		props: {
			ownHitchhikes: {
				type: Array,
				required: true,
			},
		},
		name: 'ListCreatedHitchhikes',
		data() {
			return {
				isButtonLoading: false,
				hitchhikeDetails: null,
			};
		},
		created() {
			console.log(this.ownHitchhikes);
		},
		methods: {
			deleteHitchhike(id, index) {
				this.hitchhike_id = id;
				this.hitchhike_index = index;
				let self = this;
				this.$buefy.dialog.confirm({
					title: 'Apagar Viagem',
					canCancel: ['button'],
					message: '<p style="color: #1d4696">Tem a certeza que pretende eliminar esta viagem?</p>',
					cancelText: 'Confirmar',
					confirmText: 'Não',
					type: 'is-primary',
					onCancel() {
						deleteCarTrip(self.hitchhike_id)
							.then(response => {
								self.ownHitchhikes.splice(self.hitchhike_index, 1);
							})
							.catch(error => {
								self.$buefy.toast.open({
									message: error.response.data.message.pt,
									position: 'is-top',
									type: 'is-danger',
								});
							});
					},
				});
			},
			editHitchhike(id, availablePlaces, maxPlaces, index) {
				this.hitchhike_id = id;
				this.hitchhike_index = index;
				let self = this;
				this.$buefy.dialog.prompt({
					message: '<p style="color: #1d4696">Alterar o número de lugares disponíveis?</p>',
					cancelText: 'Cancelar',
					confirmText: 'Atualizar',
					inputAttrs: {
						type: 'number',
						value: availablePlaces,
						maxlength: 2,
						max: maxPlaces - 1,
					},
					trapFocus: true,
					onConfirm(value) {
						updateCarTrip(self.hitchhike_id, value)
							.then(response => {
								self.ownHitchhikes[self.hitchhike_index].available_places = value;
							})
							.catch(error => {
								self.$buefy.toast.open({
									message: error.response.data.message.pt,
									position: 'is-top',
									type: 'is-danger',
								});
							});
					},
				});
			},
			answerHitchhike(hitchhike_id, answer, index, requestIndex) {
				console.log(hitchhike_id, answer, index, requestIndex);

				answerHitchhikeRequest(hitchhike_id, answer)
					.then(response => {
						this.ownHitchhikes[index].hitchhikes.splice(requestIndex, 1);
					})
					.catch(error => {
						this.$buefy.toast.open({
							message: error.response.data.message.pt,
							position: 'is-top',
							type: 'is-danger',
						});
					});
			},
			openDetails(index) {
				if (this.hitchhikeDetails === null) {
					this.hitchhikeDetails = index;
				} else if (this.hitchhikeDetails !== null && this.hitchhikeDetails === index) {
					this.hitchhikeDetails = null;
				} else {
					this.hitchhikeDetails = index;
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	@import '../../../assets/scss/hitchhike';

	.hitchhike-fields {
		width: 100%;
		height: calc(100vh - 165px);
		position: absolute;
		padding-left: 0;
		padding-right: 0;
		overflow-y: scroll;
	}

	.card {
		width: 93%;
		border-radius: 25px;
		margin: 10px auto;
		-webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
		-moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
		box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
	}

	.media {
		border: none;
		margin-top: 0;
	}

	.decline-button {
		float: right;
		color: white;
		border-radius: 50%;
		width: 40px;
		height: 40px;
	}
	p {
		font-size: 0.9rem;
	}
</style>
