import {
    get,
    post, put
} from '@/utils/http';


export function createVehicle(car) {
    return post('/vehicles', car)
}

export function listUserVehicles() {
    return get('/vehicles')
}

export function listAllCarBrands() {
    return get('/carbrands')
}

export function listCarBrandByID(car_brand_id) {
    return get('/carbrands/' + car_brand_id)
}

export function listAllCarModels() {
    return get('/carmodels')
}

export function listCarModelsByID(car_model_id) {
    return get('/carmodels/' + car_model_id)
}

export function listCarModelsByCarBrand(id) {
    return get('/carmodels/carbrands/' + id)
}