// initial state
const state = {
    activeHitchhikeStep: 0,
    hitchhikeData: null,

}

// getters
const getters = {

    getActiveHitchhikeStep(state, getters) {
        return state.activeHitchhikeStep
    },
    getHitchhikeData(state, getters) {
        return state.hitchhikeData
    }
}

// mutations
const mutations = {
    setActiveHitchhikeStep(state, activeHitchhikeStep) {
        state.activeHitchhikeStep = activeHitchhikeStep
    },
    setHitchhikeData(state, hitchhikeData) {
        state.hitchhikeData = hitchhikeData
    }
}


export default {
    namespaced: true,
    state,
    getters,
    mutations
}
