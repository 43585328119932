<template>
  <section>
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <section class="modal-card-body">
          <button
            @click="closeModal()"
            class="delete"
            aria-label="close"
          ></button>
          <p class=" modal-card-title title has-text-primary"><b>Filtros</b></p>
          <br />

          <div class="field email-field">
            <label class="label">Tipo de Vantagem</label>
            <div class="select control">
              <select v-model="discountType">
                <option value="fixed_price_purchase"
                  >Desconto fixo na compra</option
                >
                <option value="fixed_price_product"
                  >Desconto fixo em produto</option
                >
                <option value="percentage_purchase"
                  >Desconto em percentagem na compra</option
                >
                <option value="percentage_product"
                  >Desconto em percentagem em produto</option
                >
              </select>
            </div>
          </div>

          <div class="field email-field">
            <label class="label">Setor</label>
            <div class="select control">
              <select v-model="selectedMarketArea">
                <option v-for="area in marketArea" :value="area.id">{{
                  area.name_pt
                }}</option>
              </select>
            </div>
          </div>

          <button
            class="button is-rounded is-secondary is-fullwidth"
            @click="saveCloseModal()"
          >
            Filtrar
          </button>
        </section>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    isModalOpen: {
      type: Boolean,
      required: true,
    },
    markets: {
      type: Array,
      required: true,
    },
    marketArea: {
      type: Array,
      required: true,
    },
  },
  name: "FilterModal",
  data() {
    return {
      modalState: this.isModalOpen,
      name: "",
      discountType: "",
      selectedMarketArea: "",
    };
  },
  created() {
    for (let i = 0; i < this.markets.length; i++) {
      this.markets[i].benefits_type = [];
      for (let j = 0; j < this.markets[i].benefits.length; j++) {
        this.markets[i].benefits_type.push(this.markets[i].benefits[j].type);
      }
    }
  },
  methods: {
    closeModal(event) {
      this.$emit("clicked", false);
    },
    saveCloseModal(event) {
      this.$emit("clicked", false);
      this.$emit("data", this.discountType, this.selectedMarketArea);
    },
  },
  computed: {
    filteredMarkets() {
      return this.markets.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.name.toLowerCase()) >= 0 &&
          option.benefits_type
            .toString()
            .toLowerCase()
            .indexOf(this.discountType.toLowerCase()) >= 0 &&
          option.local_market_area_id
            .toString()
            .toLowerCase()
            .indexOf(this.selectedMarketArea.toLowerCase()) >= 0
        );
      });
    },

    /*const filter = 'nature';
       const filteredResult = initialState.filter((item) => {
         return (item.tags.toString().indexOf(filter) >= 0);
       });*/
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/auth";

.auth-div {
  bottom: 5%;
}

.field {
  margin-bottom: 0;
}

.email-field {
  position: relative;
  margin-bottom: 40px;
}

.button {
  margin-top: 25px;
  margin-bottom: 5px;
}

.select-origin {
  color: #bac3dc !important;
}

.select,
select {
  width: 100% !important;
}

.modal-card {
  border-radius: 20px;
}

.delete {
  float: right;
}
</style>
