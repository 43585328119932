<template>
  <Map v-if="userData.info&&$route.name==='Map'" :userData="userData" ></Map>
  <List v-else-if="userData.info&&$route.name==='List'&&data_fecthed" :userData="userData"
        :nearbyUsers="nearbyUsers"></List>
</template>

<script>
//Components
import Map from "@/components/Community/Map/Map";
import List from "@/components/Community/List";
//Mixin
import {userInfo} from "@/mixins/shared/userInfo";
//API
import {getNearbyEmigrants} from "@/api/apiCommunity";

export default {
  mixins: [userInfo],
  name: "Community",
  components: {
    Map,
    List
  },
  data() {
    return {
      nearbyUsers: null,
      data_fecthed: false
    }
  },
  created() {
    let data = {
      radius: 200
    }
    getNearbyEmigrants(data)
        .then(response => {
          console.log(response)
          this.nearbyUsers = response.data
          console.log(this.nearbyUsers)
          this.data_fecthed = true
        })
        .catch(error => {
        });
  }
}
</script>

<style scoped>

</style>