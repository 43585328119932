export const steps = {
    computed: {
        getStep() {
            return this.$store.getters["steps/getActiveStep"]
        },
        getStepData() {
            return this.$store.getters["steps/getActiveStepData"]
        }
    },
    methods: {
        setActiveStep(step, data) {
            this.$store.commit('steps/setActiveStep', step)
            this.$store.commit('steps/setActiveStepData', data)
        }
    }
}
