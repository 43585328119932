// initial state
const state = {
    activeGoodsStep: 0,
    goodsData: null,
    goodType:null

}

// getters
const getters = {

    getActiveGoodsStep(state, getters) {
        return state.activeGoodsStep
    },
    getGoodsData(state, getters) {
        return state.goodsData
    },

    getGoodType(state, getters){
        return state.goodType
    }
}

// mutations
const mutations = {
    setActiveGoodsStep(state, activeGoodsStep) {
        state.activeGoodsStep = activeGoodsStep
    },
    setGoodsData(state, goodsData) {
        state.goodsData = goodsData
    },
    setGoodType(state, goodType){
        state.goodType = goodType
    }
}


export default {
    namespaced: true,
    state,
    getters,
    mutations
}
