export const flightSteps = {
    computed: {
        getFlightStep() {
            return this.$store.getters["flightSteps/getActiveFlightStep"]
        },
        getFlightData(){
            return this.$store.getters["flightSteps/getFlightData"]
        },
        getPassengers(){
            return this.$store.getters["flightSteps/getPassengersData"]
        },
     
    
    },
    methods: {
        setFlightStep(step, data) {
            this.$store.commit('flightSteps/setActiveFlightStep', step)
           /*  this.$store.commit('flightSteps/setFlightData', data) */
        }
    }
}
