<template>
  <div class="auth-div">
    <div class="header">
      <i class="mdi mdi-chevron-left mdi-36px" @click="$router.back()"></i>
      <span class="title">Negócio Local</span>
    </div>
    <br />
    <div class="tabs is-toggle center-inside is-toggle-rounded">
      <div class="tabs-shadow">
        <ul>
          <li @click="activeTab = 0" :class="{ 'is-active': activeTab === 0 }">
            <a>
              <span><b>Negócio</b></span>
            </a>
          </li>
          <li @click="activeTab = 1" :class="{ 'is-active': activeTab === 1 }">
            <a>
              <span><b>Benefícios</b></span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <new-local-market
      v-if="activeTab === 0"
      @emitLocalMarket="emitLocalMarket"
      :name="name"
      :photo="photo"
      :area="area"
      :email="email"
      :phone="phone"
      :description="description"
      :country="country"
      :zipCode="zipCode"
      :site="site"
      :days ="days"
    ></new-local-market>
    <benefits
      @emitBenefits="emitBenefits"
      :discounts="discounts"
      :discountsValue="discountsValue"
      :description="benefitDescription"
      :benefits="benefits"
      :color="color"
      :type="benefitType"
      :new="newBenefits"
      :multiple="multiple"
      :date="benefitDate"
      :favourite="favourite"
      v-if="activeTab === 1"
    ></benefits>
    <button class="button is-rounded is-primary is-fullwidth" style="color:white; height:55px" :class="{ 'is-loading': isButtonLoading }" @click="createLocalMarket">
      Guardar
    </button>
    <br />
  </div>
</template>

<script>
import { createLocalMarket } from "@/api/apiBackOffice";
import NewLocalMarket from "@/components/Backoffice/NewLocalMarket.vue";
import Benefits from "@/components/Backoffice/Benefits.vue";

export default {
  components: {
    NewLocalMarket,
    Benefits
  },
  data() {
    return {
      activeTab: 0,
      isButtonLoading: false,
      photo: null,
      name: null,
      area: null,
      email: null,
      phone: null,
      description: null,
      country: null,
      zipCode: null,
      site: null,
      days: {
        sunday: [
          {
            open: "",
            close: "",
            id: "1",
            isOpen: false
          }
        ],
        monday: [
          {
            open: "",
            close: "",
            id: "2",
            isOpen: false
          }
        ],
        tuesday: [
          {
            open: "",
            close: "",
            id: "3",
            isOpen: false
          }
        ],
        wednesday: [
          {
            open: "",
            close: "",
            id: "4",
            isOpen: false
          }
        ],
        thursday: [
          {
            open: "",
            close: "",
            id: "5",
            isOpen: false
          }
        ],
        friday: [
          {
            open: "",
            close: "",
            id: "6",
            isOpen: false
          }
        ],
        saturday: [
          {
            open: "",
            close: "",
            id: "8",
            isOpen: false
          }
        ]
      },
      discounts: [],
      discountsValue: [],
      benefitDescription: [],
      benefits: {},
      color: [],
      benefitType: [],
      newBenefits: [],
      multiple: [],
      benefitDate: [],
      favourite: []
    };
  },
  methods: {
    createLocalMarket() {
      let count;
      count = this.favourite.filter(item => item == "true").length;
      if (count > 1) {
        this.$buefy.toast.open({
          message: "Apenas pode ter um benefício como favorito!",
          position: "is-top",
          type: "is-danger"
        });
      } else {
        let allBenefits = [];
        for (let i = 0; i < this.newBenefits.length; i++) {
          if (this.favourite[i] == "true") {
            allBenefits.push({
              type: this.benefitType[i],
              value: this.discountsValue[i],
              description: this.benefitDescription[i],
              color: this.color[i],
              featured: true,
              multiple: false
            });
            if (this.multiple[i] || this.multiple === "true") {
              allBenefits[i].multiple = true;
            } else {
              allBenefits[i].multiple = false;
            }

            if (this.benefitDate[i]) {
              allBenefits[i].validity = this.benefitDate[i];
            }
          } else {
            allBenefits.push({
              type: this.benefitType[i],
              value: this.discountsValue[i],
              description: this.benefitDescription[i],
              color: this.color[i],
              featured: false,
              multiple: true
            });
            if (this.multiple[i] || this.multiple === "true") {
              allBenefits[i].multiple = true;
            } else {
              allBenefits[i].multiple = false;
            }

            if (this.benefitDate[i]) {
              allBenefits[i].validity = this.benefitDate[i];
            }
          }
        }
        console.log(allBenefits);

        this.benefits = allBenefits;
        console.log(this.benefits);
        if (this.name === null || this.email === null || this.zipCode === null || this.area === null || this.days === null || this.benefits.length === 0) {
          this.$buefy.toast.open({
            message: "Deve preencher todos os campos e adicionar pelo menos 1 benefício!",
            position: "is-top",
            type: "is-danger"
          });
        } else {
          let data = {
            name: this.name,
            email: this.email,
            zip_code: this.zipCode,
            country: this.country,
            local_market_area_id: this.area,
            days: this.days,
            benefits: this.benefits,
            ...(this.description != null && { description: this.description }),
            ...(this.phone != null && { phone: this.phone }),
            ...(this.photo != null && { photo: this.photo }),
            ...(this.site != null && { url: this.site })
          };
          console.log(data);

          createLocalMarket(data)
            .then(response => {
              console.log(response);
              this.$buefy.toast.open({
                message: "Negócio criado com sucesso!",
                position: "is-top",
                type: "is-primary"
              });
              this.$router.push("/");
            })
            .catch(error => {
              console.log(error.response.data.message.pt);
              this.isLoading = false;
              this.$buefy.toast.open({
                message: error.response.data.message.pt,
                position: "is-top",
                type: "is-danger"
              });
            });
        }
      }
    },
    emitLocalMarket(value) {
      this.name = value.name;
      this.photo = value.photo;
      this.area = value.area;
      this.email = value.email;
      this.phone = value.phone;
      this.description = value.description;
      this.country = value.country;
      this.zipCode = value.zip;
      this.site = value.site;
      this.days = value.days;
    },

    emitBenefits(value) {
      this.discounts = value.discounts;
      this.discountsValue = value.discountsValue;
      this.benefits = value.benefits;
      this.color = value.color;
      this.benefitType = value.type;
      this.newBenefits = value.newBenefits;
      this.multiple = value.multiple;
      this.benefitDate = value.date;
      this.favourite = value.favourite;
    }
  }
};
</script>

<style lang="scss" scoped>
.auth-div {
  padding-left: 10%;
  padding-right: 10%;
}
.header {
  margin-top: 20px;
}

.tabs.is-toggle.is-toggle-rounded li:first-child a {
  border-bottom-right-radius: 290486px;
  border-top-right-radius: 290486px;
}

.tabs.is-toggle.is-toggle-rounded li:last-child a {
  border-bottom-left-radius: 290486px;
  border-top-left-radius: 290486px;
}

.tabs-shadow {
  /* border-radius */
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  /* box-shadow */
  -webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  -moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
}

.tabs.is-toggle a {
  border: none;
  color: #1d4696;
}

.tabs {
  padding: 10px;
}

.tabs.is-toggle li.is-active a {
  color: #1d4696;
}

i::before {
  color: #1d4696;
  transform: translate(-16px, 2px);
}
</style>
