<template>
  <section>
    <b-field class="file is-primary center-inside">
      <b-upload v-model="marketPhoto" class="file-label" accept="image/*">
        <div class="file-cta center-inside">
          <img class="profile-photo" v-if="marketPhoto" :src="getFileURL" />
          <i v-else class="file-icon mdi mdi-camera-outline mdi-48px"></i>
        </div>
      </b-upload>
    </b-field>
    <br />
    <div class="field email-field">
      <label class="label">Nome</label>
      <div class="control has-icons-right">
        <input class="input is-success" :class="{ 'error-text': errors.has('name') }" type="text" v-validate="'required'" name="name" v-model="marketName" @keyup="emitToParent" />
        <span class="icon is-small is-right">
          <i v-show="!errors.has('name')" class="required-icon mdi mdi-check mdi-24px"></i>
        </span>
      </div>
      <p v-show="errors.has('name')" class="help is-danger">
        Insira o nome do negócio!
      </p>
    </div>

    <div class="field email-field">
      <label class="label" style="display:inline">Setor</label>
      <label class="label" style="display:inline; float:right; color:#f6a328;  cursor:pointer" @click="isAddCategory = true">Nova categoria</label>
      <i class="icon mdi mdi-plus mdi-18px" style="display:inline; float:right; color:#f6a328; cursor:pointer" @click="isAddCategory = true"></i>
      <div class="select control">
        <select v-validate="'required'" v-model="marketArea" @change="emitToParent" name="status">
          <option v-for="(item, index) in localMarketAreas" :key="index" :value="item.id">
            {{ item.name_pt }}
          </option>
        </select>
        <p v-show="errors.has('status')" class="help is-danger">
          Selecione uma área de negócio!
        </p>
      </div>
    </div>

    <div class="field email-field">
      <label class="label">Email</label>
      <div class="control has-icons-right">
        <input
          class="input is-success"
          :class="{ 'error-text': errors.has('email') }"
          type="email"
          v-validate="'required|email'"
          name="email"
          v-model="marketEmail"
          @keyup="emitToParent"
        />
        <span class="icon is-small is-right">
          <i v-show="!errors.has('email') && email != ''" class="required-icon mdi mdi-check mdi-24px"></i>
        </span>
      </div>
      <p v-show="errors.has('email')" class="help is-danger">
        Insira um endereço de email!
      </p>
    </div>

    <div class="field email-field">
      <label class="label">Número de telefone</label>
      <div class="control has-icons-right">
        <input
          class="input is-success"
          :class="{ 'error-text': errors.has('phone') }"
          type="text"
          v-validate="'min:9|max:15'"
          name="phone"
          v-model="marketPhone"
          @keyup="emitToParent"
        />
        <span class="icon is-small is-right">
          <i v-show="!errors.has('phone')" class="required-icon mdi mdi-check mdi-24px"></i>
        </span>
      </div>
      <p v-show="errors.has('phone')" class="help is-danger">
        Insira um número de telefone válido!
      </p>
    </div>

    <div class="field email-field">
      <label class="label">Descrição</label>
      <div class="control has-icons-right">
        <textarea class="input is-success" type="text" v-model="marketDescription" @keyup="emitToParent" />
      </div>
    </div>

    <div class="field email-field">
      <label class="label">País</label>
      <div class="select control">
        <select v-validate="'required'" v-model="marketCountry" @change="emitToParent" name="country">
          <option v-for="(item, index) in postalCodeData" :key="index" :value="item.ISO">
            {{ item.ISO }} -
            {{ item.Country }}
          </option>
        </select>
        <p v-show="errors.has('country')" class="help is-danger">
          Insira um país!
        </p>
      </div>
    </div>

    <div class="field email-field">
      <label class="label">Código Postal</label>
      <div style="width: 100%" class="control has-icons-right">
        <input
          ref="host"
          class="input is-success"
          type="text"
          v-validate="{ required: true }"
          name="zipCode"
          :class="{ 'error-text': errors.has('zipCode') }"
          v-model="marketZipCode"
          @keyup="emitToParent"
        />
        <span class="icon is-small is-right">
          <i v-show="!errors.has('zipCode') && zipCode != null" class="required-icon mdi mdi-check mdi-24px"></i>
        </span>
      </div>
      <p v-show="errors.has('zipCode')" class="help is-danger">
        Formato de código postal inválido
      </p>
    </div>

    <div class="field email-field">
      <label class="label">Website</label>
      <div class="control has-icons-right">
        <input class="input is-success" :class="{ 'error-text': errors.has('site') }" type="text" v-validate="'url'" name="site" v-model="marketSite" @keyup="emitToParent" />
        <span class="icon is-small is-right">
          <i v-show="!errors.has('site')" class="required-icon mdi mdi-check mdi-24px"></i>
        </span>
      </div>
      <p v-show="errors.has('site')" class="help is-danger">
        Insira um endereço válido!
      </p>
    </div>

    <div>
      <label class="label">Horário do Negócio</label>
      <business-hours @updated-hours="updatedHours" :days="days" :timeIncrement="15" :hourFormat24="true" :localization="local"></business-hours>
    </div>
    <br />

    <b-modal v-model="isAddCategory">
      <div class="modal-card">
        <section class="modal-card-body">
          <button @click="isAddCategory = false" class="delete" aria-label="close"></button>
          <p class=" modal-card-title title has-text-primary">
            <b>Adicionar nova categoria</b>
          </p>

          <div class="field email-field">
            <label class="label">Nome em Português</label>
            <div class="control has-icons-right">
              <input class="input is-success" :class="{ 'error-text': errors.has('name_pt') }" type="text" v-model="newCategoryPt" />
              <span class="icon is-small is-right">
                <i v-show="!newCategoryPtError && newCategoryPt != null" class="required-icon mdi mdi-check mdi-24px"></i>
              </span>
            </div>
            <p v-show="newCategoryPtError" class="help is-danger">
              Insira um nome em Português!
            </p>
          </div>

          <div class="field email-field">
            <label class="label">Nome em Inglês</label>
            <div class="control has-icons-right">
              <input class="input is-success" :class="{ 'error-text': errors.has('name_en') }" type="text" v-model="newCategoryEn" />
              <span class="icon is-small is-right">
                <i v-show="!newCategoryEnError && newCategoryEn != null" class="required-icon mdi mdi-check mdi-24px"></i>
              </span>
            </div>
            <p v-show="newCategoryEnError" class="help is-danger">
              Insira um nome em Inglês!
            </p>
          </div>

          <button class="button is-rounded is-primary is-fullwidth" :class="{ 'is-loading': isButtonLoading }" @click="newCategory">
            Adicionar
          </button>
        </section>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { getLocalMarketsAreas, createCategory } from "@/api/apiBackOffice";
const postalCodeData = require("@/components/Auth/RegisterSteps/postalCodes/postal-codes.json");
export default {
  props: ["name", "photo", "area", "email", "phone", "description", "country", "zipCode", "site", "days"],
  data() {
    return {
      isButtonLoading: false,
      marketPhoto: this.photo,
      marketName: this.name,
      marketArea: this.area,
      marketEmail: this.email,
      marketPhone: this.phone,
      marketDescription: this.description,
      marketCountry: this.country,
      marketZipCode: this.zipCode,
      marketSite: this.site,
      postalCodeData,
      localMarketAreas: [],
      isAddCategory: false,
      newCategoryPt: null,
      newCategoryEn: null,
      newCategoryPtError: false,
      newCategoryEnError: false,

      
      local: {
        switchOpen: "Aberto",
        switchClosed: "Fechado",
        placeholderOpens: "Abre às",
        placeholderCloses: "Fechas às",
        addHours: "Adicionar Intervalo",
        open: {
          invalidInput: "Por favor introduza um horário de abertura em formato de 24h (exemplo 08:00).",
          greaterThanNext: "Por favor introduza um horário de abertura anterior ao horário de fecho.",
          lessThanPrevious: "Por favor introduza um horário de abertura posterior ao horário de fecho anterior.",
          midnightNotLast: "A meia-noite só pode ser selecionada para a última hora de fecho do dia."
        },
        close: {
          invalidInput: "Por favor introduza um horário de abertura em formato de 24h (exemplo 08:00).",
          greaterThanNext: "Por favor introduza um horário de fecho posterior ao horário de abertura.",
          lessThanPrevious: "Por favor introduza um horário de fecho posterior ao horário de abertura.",
          midnightNotLast: "A meia-noite só pode ser selecionada para a última hora de fecho do dia."
        },
        t24hours: "24 horas",
        midnight: "Midnight",
        days: {
          monday: "Segunda",
          tuesday: "Terça",
          wednesday: "Quarta",
          thursday: "Quinta",
          friday: "Sexta",
          saturday: "Sábado",
          sunday: "Domingo",
          newYearsEve: 'New Year\'s Eve', // prettier-ignore
          newYearsDay: 'New Year\'s Day', // prettier-ignore
          martinLutherKingJrDay: "Martin Luther King, Jr. Day",
          presidentsDay: 'Presidents\' Day', // prettier-ignore
          easter: "Easter",
          memorialDay: "Memorial Day",
          independenceDay: "Independence Day",
          fourthOfJuly: "4th of July",
          laborDay: "Labor Day",
          columbusDay: "Columbus Day",
          veteransDay: "Veterans Day",
          thanksgivingDay: "Thanksgiving Day",
          christmasEve: "Christmas Eve",
          christmas: "Christmas"
        }
      }
    };
  },

  computed: {
    getFileURL() {
      if (this.marketPhoto) {
        this.emitToParent();
        return window.URL.createObjectURL(this.marketPhoto);
      }
    }
  },

  mounted() {
    getLocalMarketsAreas()
      .then(response => {
        console.log(response.data);
        this.localMarketAreas = response.data;
      })
      .catch(error => {});
  },

  methods: {
    updatedHours: function(val) {
      this.emitToParent();
      console.log(this.days);
    },
    emitToParent(event) {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit("emitLocalMarket", {
            name: this.marketName,
            photo: this.marketPhoto,
            area: this.marketArea,
            email: this.marketEmail,
            phone: this.marketPhone,
            description: this.marketDescription,
            country: this.marketCountry,
            zip: this.marketZipCode,
            site: this.marketSite,
            days: this.days
          });
        }
      });
    },

    newCategory() {
      if (this.newCategoryPt === "" || this.newCategoryPt === null) {
        this.newCategoryPtError = true;
      } else this.newCategoryPtError = false;

      if (this.newCategoryEn === "" || this.newCategoryEn === null) {
        this.newCategoryEnError = true;
      } else this.newCategoryEnError = false;

      if (this.newCategoryPt && this.newCategoryEn) {
        let data = {
          name_pt: this.newCategoryPt,
          name_en: this.newCategoryEn
        };

        createCategory(data)
          .then(response => {
            console.log(response);
            this.$buefy.toast.open({
              message: "Categoria criada com sucesso",
              position: "is-top",
              type: "is-primary"
            });
            getLocalMarketsAreas()
              .then(response => {
                console.log(response.data);
                this.localMarketAreas = response.data;
              })
              .catch(error => {});
            this.isAddCategory = false;
          })
          .catch(error => {
            console.log(error);
            this.isLoading = false;
            this.$buefy.toast.open({
              message: error,
              position: "is-top",
              type: "is-danger"
            });
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/auth";
@import "../../assets/scss/backofficeLocalMarketModal";

.modal-card {
  width: auto !important;
}
</style>
