export const servicesSteps = {
    computed: {
        getServicesStep() {
            return this.$store.getters["servicesSteps/getActiveServicesStep"]
        },
        getServiceData() {
            return this.$store.getters["servicesSteps/getServicesData"]
        }
    },
    methods: {
        setServices(step, data) {
            this.$store.commit('servicesSteps/setActiveServicesStep', step)
            this.$store.commit('servicesSteps/setServicesData', data)
        }
    }
}
