import {
    formDataPut, put,get
} from '@/utils/http';

export function updateProfile(data) {
    let form = new FormData()
    form.append('origin_zip_code', data.origin_zip_code)
    form.append('origin_county', data.origin_county)
    form.append('origin_parish', data.origin_parish)
    form.append('origin_street', data.origin_street)
    form.append('name', data.name)
    form.append('gender', data.gender)
    form.append('birth_date', data.birth_date)
    if (data.phone) {
        form.append('phone', data.phone)
    }
    if (data.photo) {
        form.append('photo', data.photo)
    }
    return formDataPut('/emigrants', form);
}

export function updateEmigrantDestination(data) {
    return put('/emigrants/destination', {
        'destination_country': data.destination_country,
        'destination_zip_code': data.destination_zip_code
    })
}

export function getUserInfo(emigrant_id){
    return get('/emigrants/'+emigrant_id)
}

export function getPosts(){
    return get('/posts')
}

