<template>
  <section>
    <div class="goods-services-fields">
      <br>
      <p style="font-size: 24px" class="has-text-primary has-text-centered"><b>{{
          getGoodsData[0].article_type.name_pt
        }}</b></p>
      <br>
      <div class="columns is-gapless is-multiline">
        <div class="column is-4-desktop is-6-tablet" v-for="(good,index) in getGoodsData">
          <div class="card">
            <div class="card-content">
              <div class="media">
                <div class="media-left">
                  <div class="user center-inside">
                    <img v-if="good.photo"
                         :src="good.photo">
                    <i v-else class="file-icon mdi mdi-camera-outline mdi-36px"></i>
                  </div>
                </div>
                <div class="media-content">
                  <p class="has-text-primary"><b>{{ good.title }}</b></p>
                  <p class="has-text-dark" style="overflow:hidden">{{ good.article_type.name_pt }}</p>
                </div>
                <div class="media-right">
                  <div class="media-content">
                    <p class="has-text-secondary"><b>{{ good.distance }} km</b></p>
                  </div>
                </div>
                <div class="media-right">
                  <div class="media-content is-click" @click="removeFavourite(good.wish_articles[0].id, index)"
                       v-if="good.wish_articles.length>0">
                    <i class="mdi mdi-heart mdi-24px not-favourite"></i>
                  </div>
                  <div class="media-content is-click" v-else
                       @click="addToFavourites(good.id, index)">
                    <i class="mdi mdi-heart-outline mdi-24px not-favourite"></i>
                  </div>
                </div>
              </div>
              <div class="media">
                <div class="media-left">
                  <div class="media-content">
                    <p v-if="getGoodType !== 'exchange' && getGoodType !== 'donate'" class="has-text-primary">
                      <b>{{ good.price }} €</b></p>
                  </div>
                </div>
                <div class="media-content has-text-centered">

                </div>
                <div class="media-right">
                  <div class="media-content">
                    <button class="button is-rounded is-primary is-fullwidth"
                            @click="goToPage('/goods/'+good.id)"><b>Detalhes</b>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

//Mixins
import {goodsSteps} from "@/mixins/goods/goodsSteps";
import {goToPage} from "@/mixins/shared/goToPage";
//API
import {createWishGood, deleteWishGood} from "@/api/apiGoodsAndServices";

export default {
  mixins: [goodsSteps, goToPage],
  name: "GoodsResults",
  data() {
    return {}
  },
  created() {
    console.log(this.getGoodsData)
    console.log(this.getGoodType);
  },
  methods: {
    addToFavourites(id, index) {
      createWishGood(id)
          .then(response => {
            console.log(response)
            let data = this.getGoodsData
            data[index].wish_articles.push(response.data.body)
            this.setGoods(1, data)

          })
          .catch(error => {

          });
    },
    removeFavourite(id, index) {
      deleteWishGood(id)
          .then(response => {
            console.log(response)
            let data = this.getGoodsData
            data[index].wish_articles.pop()
            this.setGoods(1, data)
          })
          .catch(error => {

          });
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/goodsAndServices";

.goods-services-fields {
  width: 100%;
  height: calc(100vh - 77px);
  position: absolute;
  padding-left: 0;
  padding-right: 0;
  overflow-y: scroll;
}

.card {
  width: 93%;
  border-radius: 25px;
  margin: 10px auto;
  -webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  -moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;

}

.media {
  border: none;
  margin-top: 0;
}
</style>