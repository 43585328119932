<template>
  <Change v-if="$route.query.code"></Change>
  <Forgot v-else></Forgot>

</template>

<script>
//COMPONENTS
import Forgot from "@/components/Auth/ForgotPassword/Forgot";
import Change from "@/components/Auth/ForgotPassword/Change";

export default {
  name: "RecoverPassword",
  components: {
    Forgot,
    Change
  },
  data() {
    return {}
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>


</style>