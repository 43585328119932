export const userInfo = {
    computed: {
        isUserLogged: function () {
            return !!this.$store.getters['auth/getToken']
        },
        userType: function () {
            const userType = this.$store.getters['auth/getUserType']
            switch (userType) {
                case 0:
                    return 'emigrant'
                case 1:
                    return 'local_market'
                case 2:
                    return 'admin'
                default:
                    return 'user'
            }
        },
        isFirstAuth() {
            return this.$store.getters["auth/getFirstAuth"];
        },
        userData() {
            return this.$store.getters['auth/getUserData']
        },
        showTutorial() {
            return this.$store.getters['auth/getShowTutorial']
        },
        confirmAddress() {
            return this.$store.getters['auth/getConfirmAddress']
        },

    }
}
