<template>
  <HomePage v-if="isUserLogged"></HomePage>
  <Auth v-else-if="!isUserLogged"></Auth>
</template>

<script>
//Mixins
import {userInfo} from "@/mixins/shared/userInfo";

//Components
import Auth from "@/components/Auth/Auth";
import HomePage from "@/components/HomePage/HomePage";


export default {
  name: "Home",
  mixins: [userInfo],
  components: {
    Auth,
    HomePage
  }

}
</script>

<style scoped>

</style>