import store from '../store/index'
import router from '../router/index'

export default function () {
    router.beforeEach((to, from, next) => {
    

        const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
        if (requiresAuth) {
            let token = store.getters['auth/getToken']
            let isFirstAuth = store.getters['auth/getFirstAuth']
            if (token && !isFirstAuth) {
                next()
            } else {
                router.push('/')
            }
        } else {
            next();
        }
        const onlyNoAuth = to.matched.some(record => record.meta.onlyNoAuth)
        if (onlyNoAuth) {
            let token = store.getters['auth/getToken']
            if (!token) {
                next()
            } else {
                router.push('/')
            }
        } else {
            next();
        }

    });

    /*router.beforeEach((to, from, next) => {
        if (to.matched.some(record => record.meta.userType)) {
            if (to.meta.userType == store.getters['auth/getUserType']) {
                next()
            } else {
                router.push('/')
            }
        } else {
            next();
        }
    })*/
}
