<template>
  <b-loading :is-full-page="true" v-model="isPageLoading" :can-cancel="false">
    <div class="center-inside">
      <img width="140" class="loading-animation" src="../../assets/logos/logo.png">
    </div>
  </b-loading>
</template>

<script>
export default {
  props: {
    isPageLoading: {
      type: Boolean,
      default: false
    }
  },
  name: "Loading"
}
</script>

<style scoped>

</style>