<template>
  <div>
    <navbar></navbar>
    <sidebar @clicked="onClickChild"></sidebar>

    <div class="content">
      <Business v-if="showBusiness"></Business>
      <Users v-if="showUsers"></Users>
      <Tickets v-if="showTickets"></Tickets>
    </div>
  </div>
</template>

<script>
import Sidebar from "../components/Backoffice/Sidebar";
import Navbar from "../components/Backoffice/Navbar";
import Business from "../components/Backoffice/Business";
import Users from "../components/Backoffice/Users";
import Tickets from "../components/Backoffice/Tickets";

export default {
  components: {
    Sidebar,
    Navbar,
    Business,
    Users,
    Tickets,
  },
  data() {
    return {
      showBusiness: true,
      showUsers: false,
      showTickets: false,
    };
  },
  methods: {
    onClickChild(value) {
      if (value === "business") {
        this.showBusiness = true;
        this.showUsers = false;
        this.showTickets = false;
      }
      if (value === "users") {
        this.showBusiness = false;
        this.showUsers = true;
        this.showTickets = false;
      }
      if (value === "tickets") {
        this.showBusiness = false;
        this.showUsers = false;
        this.showTickets = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding-top: 68px;
  padding-left: 150px;
}

@media screen and (max-width: 768px) {
  .content {
    padding-left: 0;
  }
}
</style>
