<template>
  <section class="emigrant-profile" v-if="user !== null">
    <i class="mdi mdi-chevron-left mdi-36px" @click="$router.back()"></i>
    <div class="emigrant-data">
      <div class="center-inside">
        <div class="user center-inside">
          <img class="profile-photo" v-if="user.photo" :src="user.photo" />
          <i v-else class="file-icon mdi mdi-camera-outline mdi-48px"></i>
        </div>
        <br />
      </div>
      <br />
      <p class="has-text-centered title ">{{ user.name }}</p>
      <hr />
      <p class="has-text-primary">Morada: {{ user.destination_formatted_address }}</p>
      <p class="has-text-primary">Idade: {{ getAge(user.birth_date) }}</p>
      <br />
      <table class="center-inside">
        <tr>
          <th style="padding-right: 10px">
            <button v-if="user.follow === 'none'" class="button is-rounded is-primary" @click="followUser(user.id)">
              <b>Seguir</b>
            </button>
            <button @click="unfollowUser(user.follow_id)" v-else-if="user.follow === 'pending'" class="button is-rounded pending-button">
              <b>Pendente</b>
            </button>
            <button @click="goToPage('/notifications')" v-else-if="user.follow === 'answering'" class="button is-rounded pending-button">
              <b>solicitado</b>
            </button>
            <button @click="unfollowUser(user.follow_id)" v-else-if="user.follow === 'following'" class="button is-rounded following-button">
              <b>A seguir</b>
            </button>
          </th>
          <th style="padding-right: 10px" @click="goToChat()">
            <div class="chat-button center-inside">
              <i class="file-icon mdi mdi-chat-outline mdi-36px"></i>
            </div>
          </th>
        </tr>
      </table>
    </div>
  </section>
</template>

<script>
//API
import { getUserInfo } from "@/api/apiProfile";
import { sendMessageRequest } from "@/api/apiChat";
//Mixin
import { followUser } from "@/mixins/follow/followUser";
import { loading } from "@/mixins/shared/loading";
import { goToPage } from "@/mixins/shared/goToPage";
import { userInfo } from "@/mixins/shared/userInfo";

export default {
  mixins: [followUser, loading, goToPage, userInfo],
  name: "PublicProfile",
  data() {
    return {
      user: null
    };
  },
  created() {
    this.setPageLoading(true);
    getUserInfo(this.$route.params.id)
      .then(response => {
        console.log(response);
        this.user = response.data;
        console.log(this.user);
        this.setPageLoading(false);
      })
      .catch(error => {
        this.setPageLoading(false);
      });
  },
  methods: {
    goToChat() {
      if (this.user.room !== null) {
        this.goToPage("/chat/" + this.user.room._id + "/" + this.userData.id);
      } else {
        sendMessageRequest(this.user.id)
          .then(response => {
            console.log(response);
            this.goToPage("/chat/" + response.data.room._id + "/" + this.userData.id);
          })
          .catch(error => {});
      }
    },
    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/emigrantProfile";

.mdi-chevron-left::before {
  padding: 14px;
  color: $primary;
}
</style>
