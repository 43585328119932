export const tokenVerification = {
    computed: {
        hasVerifiedToken() {
            return this.$store.getters['auth/getHasVerifiedToken']
        }
    },
    methods: {
        setHasVerifiedToken(hasVerifiedToken) {
            this.$store.commit('auth/setHasVerifiedToken', hasVerifiedToken)
        }
    }
}
