<template>
	<section class="section-card">
		<div class="card">
			<div class="content">
				<div class="header">
					<img v-if="this.details.user.emigrant.photo" class="profile-photo inline" :src="this.details.user.emigrant.photo" />
					<div v-else class="diff-file-cta center-inside inline">
						<i class="diff-file-icon mdi mdi-camera-outline mdi-26px"></i>
					</div>
					<div class="inline">
						<span
							class="new-post-text"
							:style="details.user.emigrant.photo ? 'transform: translateY(-20px)' : 'transform: translateY(0px)'"
							v-if="this.details.user.emigrant.name.length < 17"
							>Publicação de {{ this.details.user.emigrant.name }}</span
						>
						<span v-else class="new-post-text" :style="details.user.emigrant.photo ? 'transform: translateY(-20px)' : 'transform: translateY(0px)'">
							Publicação de
							{{ this.details.user.emigrant.name.substring(0, 14) }}...
						</span>
					</div>
				</div>
				<img v-if="this.details.media_url && this.details.media_type === 'image'" class="feed-image" :src="details.media_url" />
				<Media
					v-else-if="this.details.media_url && this.details.media_type === 'video'"
					class="feed-video"
					:kind="'video'"
					:controls="true"
					:loop="true"
					:src="details.media_url"
				>
				</Media>
				<div v-else class="file-post center-inside inline ">
					<i class="file-icon-post  mdi mdi-camera-outline mdi-48px"></i>
				</div>

				<textarea
					class="input is-success"
					:class="{ 'error-text': errors.has('requestDescription') }"
					type="text"
					v-model="details.description"
					name="description"
					disabled
				/>

				<p class="likes has-text-primary">{{details.likes.length}} gostos</p>
			</div>
		</div>
		<div class="next-div has-text-centered" style="display: flex;justify-content: center;">
			<button class="button is-rounded is-primary is-fullwidth publish" @click="goBack">
				Voltar
			</button>
		</div>
	</section>
</template>

<script>
	import { getPostDetails } from '@/api/apiFeed';
	import { feedSteps } from '@/mixins/feed/feedSteps';
	export default {
		mixins: [feedSteps],
		name: 'ViewPublication',
		data() {
			return {
				postId: null,
				details: [],
			};
		},
		methods: {
			postDetails() {
				getPostDetails(this.postId)
					.then(response => {
						this.details = response.data;
						console.log(this.details);
					})
					.catch(error => {});
			},

			goBack() {
				this.setFeedInfo(0, null);
			},
		},

		created() {
			this.postId = this.$store.getters['feedSteps/getFeedData'];
			this.postDetails();
			
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/createPublication';
</style>
