<template>
  <section>
    <div style="margin-bottom: 100px" v-for="(discount, index) in newBenefits" :key="index">
      <div class="media">
        <div class="media-left">
          <div class="button is-rounded  is-half" style="color:white" v-if="benefitColor[index]" :style="{ backgroundColor: color[index] }">Benefício {{ index + 1 }}</div>
          <div class="button is-rounded  is-half" v-else>Benefício {{ index + 1 }}</div>
        </div>
        <div class="media-content"></div>
        <div class="media-right">
          <i class="mdi mdi-close-circle-outline mdi-36px" @click="removeBenefit(index)"> </i>
        </div>
      </div>

      <div class="field email-field">
        <label class="label description-label">Tipo de desconto</label>
        <div class="select control">
          <select v-validate="'required'" name="type" v-model="benefitType[index]">
            <option value="fixed_price_purchase">Desconto fixo na compra</option>
            <option value="fixed_price_product">Desconto fixo em produto</option>
            <option value="percentage_purchase">Desconto em % na compra</option>
            <option value="percentage_product">Desconto em % em produto</option>
          </select>

          <p v-show="messageError" class="help is-danger">
            Selecione um desconto!
          </p>
        </div>
      </div>

      <div class="field email-field">
        <label class="label description-label">Valor do desconto</label>
        <div class="control has-icons-right">
          <input class="input is-success" :class="{ 'error-text': errors.has('discount') }" type="number" v-model="benefitDiscountsValue[index]" />
          <span class="icon is-small is-right">
            <i v-show="!messageError" class="required-icon mdi mdi-check mdi-24px"></i>
          </span>
        </div>
        <p v-show="messageError" class="help is-danger">
          Insira um valor de desconto!
        </p>
      </div>

      <label class="label description-label">Descrição</label>
      <textarea class="input is-success" type="text" v-model="benefitsDescripiton[index]" />

      <label class="label description-label">Cor</label>
      <div class="field email-field">
        <v-swatches :swatches="swatches" v-model="benefitColor[index]"></v-swatches>
        <br />
        <p v-show="messageError" class="help is-danger">
          Escolha uma cor!
        </p>
      </div>

      <label class="label description-label">Validade do desconto</label>
      <div class="field email-field">
        <vc-date-picker :min-date="tomorrow" :model-config="modelConfig" v-model="benefitDate[index]" />
      </div>

      <div class="field email-field">
        <label class="label">Marcar como favorito</label>
        <div class="select control">
          <select v-validate="'required'" name="type" v-model="favouriteBenefit[index]">
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>

          <p v-show="messageError" class="help is-danger">
            Selecione um desconto!
          </p>
        </div>
      </div>

      <label class="label multiple">Acumulável com outros benefícios</label>
      <div class="select control">
        <select v-validate="'required'" name="type" v-model="multipleBenefit[index]">
          <option value="true">Sim</option>
          <option value="false">Não</option>
        </select>

        <p v-show="messageError" class="help is-danger">
          Selecione um desconto!
        </p>
      </div>
    </div>

    <button class="button is-rounded is-white is-fullwidth" style="margin-bottom:20px" @click="addBenefit">
      Adicionar Benefício
    </button>
  </section>
</template>

<script>
import VSwatches from "vue-swatches";
export default {
  props: ["discounts", "discountsValue", "description", "benefits", "color", "type", "new", "multiple", "date", "favourite"],
  components: {
    VSwatches
  },
  data() {
    return {
      benefitDiscounts: this.discounts,
      benefitDiscountsValue: this.discountsValue,
      benefitsDescripiton: this.description,
      benefitColor: this.color,
      benefitType: this.type,
      newBenefits: this.new,
      multipleBenefit: this.multiple,
      benefitDate: this.date,
      favouriteBenefit: this.favourite,
      messageError: false,
      tomorrow: new Date (),

      modelConfig: {
        type: "string"
      },
      swatches: ["#1d4696", "#f6a328", "#bac3dc", "#e64310"]
    };
  },
  methods: {
    addBenefit: function() {
      if (
        this.newBenefits.length === this.favouriteBenefit.length &&
        this.newBenefits.length === this.multipleBenefit.length &&
        this.newBenefits.length === this.benefitType.length &&
        this.newBenefits.length === this.color.length &&
        this.newBenefits.length === this.discountsValue.length
      ) {
        this.newBenefits.push(this.newBenefits.length + 1);
        this.messageError = false;
      } else {
        this.messageError = true;
      }
    },
    removeBenefit: function(index) {
      this.newBenefits.splice(index, 1);
      this.color.splice(index, 1);
      this.benefitType.splice(index, 1);
      this.multipleBenefit.splice(index, 1);
      this.favouriteBenefit.splice(index, 1);
      this.benefitDate.splice(index, 1);
      this.benefitsDescripiton.splice(index, 1);
      this.benefitDiscountsValue.splice(index, 1);
      this.emitToParent();
    },
    emitToParent(event) {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit("emitBenefits", {
            discounts: this.discounts,
            discountsValue: this.discountsValue,
            color: this.color,
            type: this.benefitType,
            newBenefits: this.newBenefits,
            multiple: this.multiple,
            date: this.benefitDate,
            favourite: this.favourite
          });
        }
      });
    }
  },
  mounted() {
    console.log(this.new);
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
    console.log(this.tomorrow);
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/auth";
@import "../../assets/scss/backofficeLocalMarketModal";

.button {
  border: 1px solid $dark !important;
  color: $primary;
}

.button:focus,
.button:active,
.button.is-active {
  border: 1px solid $dark !important;
}
.button:focus {
  border: 1px solid $dark !important;
}

.mdi-close-circle-outline::before {
  margin-top: 25px;
  margin-bottom: 5px;
}
</style>
