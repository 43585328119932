<template>
  <section>
    <div class="goods-services-fields">
      <div class="field email-field">
        <label class="label">Serviço</label>
        <div class="control has-icons-right">
          <input
              class="input is-success"
              type="text"
              name="title"
              v-model="searchText"
          />

        </div>
      </div>
      <br>
      <div class="field email-field">
        <label class="label">Categoria</label>
        <br>
        <div class="field email-field">
          <div class="columns is-mobile is-multiline">
            <div v-if="service.icon!==null" class="column is-4 center-inside" v-for="service in servicesList">
              <div @click="setSelection(service.id)" class="icon-button is-click center-inside has-text-centered"
                   :class="{'icon-button-selected':selectedService===service.id}">
                <p class="selected-text" v-if="selectedService===service.id">
                  <b>{{ service.name_pt }}</b></p>
                <i v-else class="file-icon mdi  mdi-36px" :class="service.icon"></i>
              </div>
            </div>
          </div>
        </div>

        <br/>
        <br/>
        <div class="next-div has-text-centered">
          <button
              class="button is-rounded is-primary is-fullwidth"
              @click="searchService"
              :class="{ 'is-loading': isLoading }"
          >
            Pesquisar
          </button>
        </div>
        <br/>
        <br/>
      </div>
    </div>
  </section>
</template>

<script>
//API
import {searchService} from "@/api/apiGoodsAndServices";

//Mixins
import {servicesSteps} from "@/mixins/services/servicesSteps";

export default {
  mixins: [servicesSteps],
  props: {
    servicesList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedService: null,
      searchText: "",
      isLoading: false,

    };
  },
  created() {

  },
  methods: {
    setSelection(service_id) {
      if (this.selectedService === service_id) {
        this.selectedService = null
      } else {
        this.selectedService = service_id
      }
    },
    searchService() {
      this.isLoading = true;
      if (this.searchText !== "" || this.selectedService !== null) {
        let text = ''
        let category = ''
        let data = ''
        if (this.searchText !== "") {
          text = '&text=' + this.searchText
        }
        if (this.selectedService !== null) {
          category = '&service_type=' + this.selectedService
        }

        if (this.searchText !== "" && this.selectedService === null) {
          data = 'text=' + this.searchText
        } else if (this.selectedService !== null && this.searchText === "") {
          data = 'service_type=' + this.selectedService
        } else {
          data = 'text=' + this.searchText + '&service_type=' + this.selectedService
        }

        searchService(data)
            .then((response) => {
              console.log(response)
              if (response.data.length <= 0) {
                this.$buefy.toast.open({
                  message:
                      "Não existem serviços com as carateristicas selecionadas",
                  position: "is-top",
                  type: "is-danger",
                });
              } else {
                this.setServices(1, response.data);
              }
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
              this.$buefy.toast.open({
                message: error.response.data.message.pt,
                position: "is-top",
                type: "is-danger",
              });
            });
      } else {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: 'Pesquise por texto ou selecione uma categoria',
          position: "is-top",
          type: "is-danger",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/auth";
@import "../../assets/scss/goodsAndServices";

.select,
select {
  width: 100% !important;
}
</style>
