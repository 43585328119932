import {steps} from "@/mixins/shared/steps";

export const goToPage = {
    mixins: [steps],
    methods: {
        goToPage(location) {
            this.setActiveStep(0,null)
            if (this.$route.path !== location) {
                this.$router.push(location)
            }

        }
    }
}
