// initial state
const state = {
    activeFeedStep: 0,
    feedData: null,

}

// getters
const getters = {

    getActiveFeedStep(state, getters) {
        return state.activeFeedStep
    },
    getFeedData(state, getters) {
        return state.feedData
    }
}

// mutations
const mutations = {
    setActiveFeedStep(state, activeFeedStep) {
        state.activeFeedStep = activeFeedStep
    },
    setFeedData(state, feedData) {
        state.feedData = feedData
    }
}


export default {
    namespaced: true,
    state,
    getters,
    mutations
}
