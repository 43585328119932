<template>
  <section v-if="userCar != null">
    <div class="center-inside">
      <div class="card">
        <div class="card-content">
          <div class="media">
            <div class="media-left">
              <div class="user center-inside">
                <img class="profile-photo" v-if="userData.info.photo" :src="userData.info.photo" />
                <i v-else class="file-icon mdi mdi-camera-outline mdi-36px"></i>
              </div>
            </div>
            <div class="media-content">
              <p class="has-text-primary">
                <b>{{ userData.info.name }}</b>
              </p>
              <p class="has-text-dark">{{ userCar[0].car_model.car_brand.name }} | {{ userCar[0].year }}</p>
            </div>
            <div class="media-right">
              <div class="media-content">
                <p class="has-text-secondary">
                  <b>{{ getHitchhikeData.routes.routes[0].legs[0].distance.text }}</b>
                </p>
                <p class="has-text-primary">
                  <b>{{ getHitchhikeData.available_places }} Lug.</b>
                </p>
              </div>
            </div>
          </div>
          <div class="media">
            <div class="media-left">
              <div class="media-content">
                <p class="has-text-primary">
                  <b>{{ getHitchhikeData.date }}</b>
                </p>
              </div>
            </div>
            <div class="media-content has-text-centered">
              <p class="has-text-primary">
                <b>{{ getHitchhikeData.time }}</b>
              </p>
            </div>
            <div class="media-right">
              <div class="media-content">
                <button class="button is-rounded is-primary is-fullwidth" :class="{ 'is-loading': isButtonLoading }" @click="createTrip"><b>Anunciar</b></button>
                <button class="button is-rounded is-danger is-fullwidth mt-2" :class="{ 'is-loading': isButtonLoading }" @click="back"><b>Cancelar</b></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <gmap-map ref="map" class="map-size" :zoom="5" :center="{ lat: 40.9417347, lng: -2.8104512 }" :options="options">
      <DirectionsRenderer />
    </gmap-map>
  </section>
</template>

<script>
//MAP Style
const mapStyle = require("@/components/Community/Map/mapStyle.json");
//Components
import DirectionsRenderer from "@/components/Hitchhike/OfferHitchhike/Map/DirectionsRenderer.js";
//API
import { createCarTrip } from "@/api/apiRides";
//Mixins
import { hitchhikeSteps } from "@/mixins/hitchhikes/hitchhikeSteps";
import { listUserVehicles } from "@/api/apiCar";
import { userInfo } from "@/mixins/shared/userInfo";
export default {
  name: "Map",
  mixins: [hitchhikeSteps, userInfo],
  components: {
    DirectionsRenderer
  },

  data() {
    return {
      options: {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        styles: mapStyle
      },
      isButtonLoading: false,
      userCar: null
    };
  },
  created() {
    console.log(this.getHitchhikeData);
    listUserVehicles()
      .then(response => {
        this.userCar = response.data;
        console.log(this.userCar[0].car_model.car_brand.name);
      })
      .catch(error => {});
  },
  computed: {},
  methods: {
    createTrip() {
      this.isButtonLoading = true;
      let data = {
        available_places: this.getHitchhikeData.available_places,
        departure_zip_code: this.getHitchhikeData.departure_zip_code,
        departure_country: this.getHitchhikeData.departure_country,
        arrival_zip_code: this.getHitchhikeData.arrival_zip_code,
        arrival_country: this.getHitchhikeData.arrival_country,
        departure_date: this.getHitchhikeData.departure_date,
        summary: this.getHitchhikeData.routes.routes[0].summary
      };
      createCarTrip(data)
        .then(response => {
          console.log(response);
          this.isButtonLoading = false;
          this.$buefy.toast.open({
            message: "Viagem criada com sucesso",
            position: "is-top",
            type: "is-primary"
          });
          this.setHitchhike(0, null);
        })
        .catch(error => {
          this.isButtonLoading = false;
          this.$buefy.toast.open({
            message: error.response.data.message.pt,
            position: "is-top",
            type: "is-danger"
          });
          this.setHitchhike(0, null);
        });
    },
    back() {
      this.setHitchhike(1, this.getHitchhikeData);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../../assets/scss/hitchhike";

.map-size {
  width: 100vw;
  height: 100vh;
}

.card {
  position: absolute;
  bottom: 2%;
  z-index: 1;
  width: 93%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px;
}

.media {
  border: none;
  margin-top: 0;
}
</style>
