// initial state
const state = {
    activeLocalMarketStep: 0,
    localMarketData: null,
    benefits: null

}

// getters
const getters = {

    getActiveLocalMarketStep(state, getters) {
        return state.activeLocalMarketStep
    },
    getLocalMarketData(state, getters) {
        return state.localMarketData
    },
    getBenefits(state, getters) {
        return state.benefits
    }
}

// mutations
const mutations = {
    setActiveLocalMarketStep(state, activeLocalMarketStep) {
        state.activeLocalMarketStep = activeLocalMarketStep
    },
    setLocalMarketData(state, localMarketData,) {
        state.localMarketData = localMarketData

    },
    setBenefits(state, benefits) {
        state.benefits = benefits
    }
}


export default {
    namespaced: true,
    state,
    getters,
    mutations
}
