<template>
	<section>
		<div class="auth-div">
			<div class="header">
				<i class="mdi mdi-chevron-left mdi-24px" style="margin-right:10px" @click="$router.back()"></i>
				<span class="title">
					{{ origin.split('-', 1).shift() }} -
					{{ destination.split('-', 1).shift() }}
				</span>
				<!-- Siglas da Rota -->
			</div>
			<div class="container">
				<chart-card>
					<!-- Gráfico de linhas-->
					<highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
				</chart-card>
			</div>

			<div class="container">
				<chart-card>
					<!-- Gráfico mostra o melhor periodo de N dias fixos ao longo do mês -->
					<highcharts class="hc" :options="secondChart" ref="chart"></highcharts>
				</chart-card>
			</div>

			<div class="container">
				<chart-card>
					<!-- Gráfico dá-nos o melhor período de combinação ida e volta naquele mês-->
					<highcharts class="hc" :options="thirdChart" ref="chart"></highcharts>
				</chart-card>
			</div>

			<div class="container">
				<chart-card>
					<!-- Gráfico mostra a melhor combinação da periodicidade fixa do utilizador ao longo do ano-->
					<highcharts class="hc" :options="fourthChart" ref="chart"></highcharts>
				</chart-card>
			</div>

			<!-- Não vai ser utilizado provavelmente -->
			<!--   <div class="container">
        <chart-card>
          
          <highcharts
            class="hc"
            :options="singleColumnChart"
            ref="chart"
          ></highcharts>
        </chart-card>
      </div> -->

			<div class="next-div has-text-centered " style="margin-top: 90px;">
				<button class="button is-rounded is-primary is-fullwidth" @click="unfollow">
					Deixar de seguir
				</button>
			</div>
			<br />
		</div>
	</section>
</template>

<script>
	import moment from 'moment';
	import Highcharts from 'highcharts';
	import exportingInit from 'highcharts/modules/exporting';
	import { unfollowFlight, getGraphInfo } from '@/api/apiFlights';

	exportingInit(Highcharts);

	export default {
		data() {
			return {
				flightId: null,
				outbound: null,
				inbound: null,
				graphData: null,
				origin: null,
				destination: null,
				secondChartOut: [],
				secondChartIn: [],

				//Line chart options
				chartOptions: {
					exporting: {
						enabled: false,
					},

					title: {
						text: null /* Intervalo de datas escolhidas pelo utilizador */,
					},

					subtitle: {
						text: 'Fixo',
					},

					yAxis: {
						title: {
							text: 'Preço',
						},
					},

					xAxis: {
						type: 'datetime',
						dateTimeLabelFormats: {
							day: '%e %b %y',
						},
						min: null /* Intervalo das datas */,
						max: null,
					},

					legend: {
						layout: 'vertical',
						align: 'right',
						verticalAlign: 'middle',
					},

					plotOptions: {
						series: {
							label: {
								connectorAllowed: false,
							},
							pointStart: 6,
						},
					},

					series: [
						{
							name: 'Voos',
							data: [],
							color: '#1d4696',
						},
					],
					responsive: {
						rules: [
							{
								condition: {
									maxWidth: 500,
								},
								chartOptions: {
									legend: {
										layout: 'horizontal',
										align: 'center',
										verticalAlign: 'bottom',
									},
								},
							},
						],
					},
					credits: {
						enabled: false,
					},
				},

				//2º Gráfico
				secondChart: {
					exporting: {
						enabled: false,
					},
					chart: {
						type: 'column',
					},
					title: {
						text: '' /* Mês a ser analisado */,
					},
					subtitle: {
						text: '' /* Subtítulo */,
					},
					xAxis: {
						categories: [] /* Datas dos 5 intervalos */,
						crosshair: true,
					},
					yAxis: {
						min: 0,
						title: {
							text: 'Preço',
						},
					},
					tooltip: {
						headerFormat: '<span style="font-size:10px">{point.key}</span><table>',

						pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} €</b></td></tr>',
						footerFormat: '</table>',
						shared: true,
						useHTML: true,
					},
					plotOptions: {
						column: {
							pointPadding: 0.2,
							borderWidth: 0,
						},
					},
					series: [
						{
							name: 'Ida',
							data: null /* Preços de ida de cada intervalo */,
							color: '#1d4696',
						},
						{
							name: 'Volta',
							data: null /* Preço de volta de cada intervalo */,
							color: '#f6a328',
						},
					],
					credits: {
						enabled: false,
					},
				},

				//3º Gráfico
				thirdChart: {
					exporting: {
						enabled: false,
					},
					chart: {
						type: 'column',
					},
					title: {
						text: '' /* Mês a ser analisado */,
					},
					subtitle: {
						text: '' /* Subtítulo */,
					},
					xAxis: {
						categories: null /* Datas dos 5 intervalos */,
						crosshair: true,
					},
					yAxis: {
						min: 0,
						title: {
							text: 'Preço',
						},
					},
					tooltip: {
						headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
						pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} €</b></td></tr>',
						footerFormat: '</table>',
						shared: true,
						useHTML: true,
					},
					plotOptions: {
						column: {
							pointPadding: 0.2,
							borderWidth: 0,
						},
					},
					series: [
						{
							name: 'Ida + Volta',
							data: null /* Preços de ida de cada intervalo */,
							color: '#1d4696',
						},
					],
					credits: {
						enabled: false,
					},
				},

				//4º Gráfico
				fourthChart: {
					exporting: {
						enabled: false,
					},
					chart: {
						type: 'column',
					},
					title: {
						text: '5 melhores datas' /* Ano a ser analisado */,
					},
					subtitle: {
						text: '' /* Subtítulo */,
					},
					xAxis: {
						categories: null /* Datas dos 5 intervalos */,
						crosshair: true,
					},
					yAxis: {
						min: 0,
						title: {
							text: 'Preço',
						},
					},
					tooltip: {
						headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
						pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} €</b></td></tr>',
						footerFormat: '</table>',
						shared: true,
						useHTML: true,
					},
					plotOptions: {
						column: {
							pointPadding: 0.2,
							borderWidth: 0,
						},
					},
					series: [
						{
							name: 'Ida + Volta',
							data: null /* Preços de ida de cada intervalo */,
							color: '#1d4696',
						},
					],
					credits: {
						enabled: false,
					},
				},

				//Não vai ser utilizado provavelmente
				//Column Charts Comparison
				/* singleColumnChart: {
	        exporting: {
	          enabled: false,
	        },
	        chart: {
	          type: "column",
	        },
	        title: {
	          text: "16-20 Jul",
	        },
	        subtitle: {
	          text: "Fixo",
	        },
	        xAxis: {
	          categories: [
	            "Jan",
	            " ",
	            "Mar",
	            "",
	            "Mai",
	            "",
	            "Jul",
	            "",
	            "Set",
	            "Nov",
	            "",
	          ],
	          crosshair: true,
	        },
	        yAxis: {
	          min: 0,
	          title: {
	            text: "Preço",
	          },
	        },
	        tooltip: {
	          headerFormat:
	            '<span style="font-size:10px">{point.key}</span><table>',
	          pointFormat:
	            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
	            '<td style="padding:0"><b>{point.y:.1f} €</b></td></tr>',
	          footerFormat: "</table>",
	          shared: true,
	          useHTML: true,
	        },
	        plotOptions: {
	          column: {
	            pointPadding: 0.2,
	            borderWidth: 0,
	          },
	        },
	        series: [
	          {
	            name: "Ida",
	            data: [250, 300, 470, 380, 200, 276, 300, 280, 350, 400, 387],
	            color: "#f6a328",
	          },
	        ],
	        credits: {
	          enabled: false,
	        },
	      }, */
			};
		},
		methods: {
			unfollow() {
				unfollowFlight(this.flightId)
					.then(response => {
						console.log(response);
						this.$buefy.toast.open({
							message: 'Tracking removido com sucesso.',
							position: 'is-top',
							type: 'is-primary',
						});
						this.$router.push('/myflights');
					})
					.catch(error => {
						this.isLoading = false;
						this.$buefy.toast.open({
							message: error.response.data.message.pt,
							position: 'is-top',
							type: 'is-danger',
						});
					});
			},

			fillSecondChartLabel() {
				for (let i = 0; i < this.secondChartOut.length; i++) {
					this.secondChart.xAxis.categories[i] = this.secondChartOut[i] + ' | ' + this.secondChartIn[i];
				}
			},
		},
		created() {
			console.log(this.$route.params);
			this.flightId = this.$route.params.id;
			this.outbound = this.$route.params.outbound;
			this.inbound = this.$route.params.inbound;
			this.origin = this.$route.params.origin;
			this.destination = this.$route.params.destination;

			getGraphInfo(this.flightId, this.formattedOutboundDate, this.formattedInboundDate)
				.then(response => {
					console.log(response);
					this.graphData = response;

					let length = this.graphData.data.first.length;
					if (length > 0) {
						for (let index = 0; index < this.graphData.data.first.length; index++) {
							this.graphData.data.first[index][0] = moment.utc(this.graphData.data.first[index][0]).valueOf();
						}

						this.chartOptions.series[0].data = this.graphData.data.first;

						this.chartOptions.xAxis.min = this.graphData.data.first[0][0];
						this.chartOptions.xAxis.max = this.graphData.data.first[length - 1][0];
						this.chartOptions.title.text = moment(this.outbound).format('DD MMM') + '  ' + moment(this.inbound).format('DD MMM');
					}


					this.secondChart.title.text = this.graphData.data.second.month;
					this.secondChart.subtitle.text = this.graphData.data.second.difference;
					this.secondChart.series[0].data = this.graphData.data.second.array_out_q;
					this.secondChart.series[1].data = this.graphData.data.second.array_in_q;
					this.secondChartOut = this.graphData.data.second.out_label;
					this.secondChartIn = this.graphData.data.second.in_label;

					this.fillSecondChartLabel();

					this.thirdChart.title.text = this.graphData.data.third_graph.month;
					this.thirdChart.xAxis.categories = this.graphData.data.third_graph.array_labels;
					this.thirdChart.series[0].data = this.graphData.data.third_graph.array_quotes;

					this.fourthChart.xAxis.categories = this.graphData.data.fourth_graph.array_labels;
					this.fourthChart.series[0].data = this.graphData.data.fourth_graph.array_quotes;
				})
				.catch(error => {
					this.isLoading = false;
					/* this.$buefy.toast.open({
	          message: error,
	          position: "is-top",
	          type: "is-danger",
	        }); */
				});
		},
		computed: {
			formattedOutboundDate() {
				return moment(this.outbound).format('YYYY-MM-DD');
			},
			formattedInboundDate() {
				return moment(this.inbound).format('YYYY-MM-DD');
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/auth';

	.auth-div {
		padding-left: 6%;
		padding-right: 6%;
	}

	.hc {
		width: 100%;
	}

	.container {
		margin-bottom: 20px;
	}

	.title {
		font-size: 1.5rem;
	}

	.header {
		margin-top: 20px;
	}
</style>
